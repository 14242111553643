import { Pagination } from 'baseui/pagination';

interface IProps {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  numPages: number;
  pageSize: number;
}

const Pagin = ({ currentPage, setCurrentPage, numPages, pageSize }: IProps) => {
  return (
    <Pagination
      numPages={numPages}
      currentPage={currentPage}
      onPageChange={({ nextPage }) => {
        setCurrentPage(Math.min(Math.max(nextPage, 1), numPages));
      }}
    />
  );
};

export default Pagin;
