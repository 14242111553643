import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';

interface SelectedCompaniesContextType {
  selectedCompanies: string[];
  setSelectedCompanies: (companies: string[]) => void;
  companiesOptions: any;
  storesOptions: any;
  storesOptionsByCompanies: any;
  setCompaniesOptions: any;
  setStoresOptions: any;
  setStoresOptionsByCompanies: any;
  selectedCurrency: string;
  setSelectedCurrency: (currency: string) => void;
}

const SelectedCompaniesContext = createContext<
  SelectedCompaniesContextType | undefined
>(undefined);

export const SelectedCompaniesProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState<any>([]);
  const [storesOptions, setStoresOptions] = useState<any>([]);
  const [storesOptionsByCompanies, setStoresOptionsByCompanies] = useState<any>(
    []
  );
  const [selectedCurrency, setSelectedCurrency] = useState<string>(''); // Default value

  // Load selected companies from localStorage on component mount
  useEffect(() => {
    const savedSelectedCompanies = JSON.parse(
      localStorage.getItem('selectedCompanies') || '[]'
    );
    if (savedSelectedCompanies.length > 0) {
      setSelectedCompanies(savedSelectedCompanies);
    }
  }, []);

  return (
    <SelectedCompaniesContext.Provider
      value={{
        selectedCompanies,
        setSelectedCompanies,
        storesOptions,
        storesOptionsByCompanies,
        setStoresOptionsByCompanies,
        setStoresOptions,
        companiesOptions,
        setCompaniesOptions,
        selectedCurrency,
        setSelectedCurrency,
      }}
    >
      {children}
    </SelectedCompaniesContext.Provider>
  );
};

export const useSelectedCompanies = () => {
  const context = useContext(SelectedCompaniesContext);
  if (context === undefined) {
    throw new Error(
      'useSelectedCompanies must be used within a SelectedCompaniesProvider'
    );
  }
  return context;
};
