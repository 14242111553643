import { useStyletron } from 'styletron-react';

import colors from 'constants/colors';

interface IProps {
  icon?: string;
  name: string;
  isDisable?: boolean;
  onClick: () => void;
  noFilter?: boolean;
  isLoading?: boolean;
}

const CustomIconButton = ({
  icon,
  name,
  onClick,
  isDisable = false,
  noFilter = false,
  isLoading = false,
}: IProps) => {
  const [css] = useStyletron();

  const wrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    border: '0.1rem solid rgba(13, 38, 107, 0.1)',
    borderRadius: '10px',
    backgroundColor: colors.white,
    cursor: 'pointer',
    height: 'fit-content',
    '@media (max-width: 600px)': {
      padding: '4px 10px',
    },
    ':hover': {
      backgroundColor: 'var(--lightGray)',
      color: 'var(--black)',
    },
    marginBottom: '2px',
  });

  const DisableWrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(13, 38, 107, 0.1)',
    borderRadius: '10px',
    backgroundColor: '#f5f3f3',
    cursor: 'not-allowed',
    height: 'fit-content',
    '@media (max-width: 600px)': {
      padding: '4px 10px',
    },
    ':hover': {
      backgroundColor: 'var(--lightGray)',
      color: 'var(--black)',
    },
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    marginRight: '8px',
    marginLeft: '8px',
    filter: noFilter
      ? ''
      : 'invert(31%) sepia(61%) saturate(337%) hue-rotate(182deg) brightness(87%) contrast(88%)',
    '@media (max-width: 600px)': {
      padding: '8px',
      width: '17px',
      height: '17px',
      marginRight: '6px',
      marginLeft: '6px',
    },
  });
  const buttonStyles = css({
    border: 'none',
    outline: 'none',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'poppins-regular',
    color: colors.darkBlue,
    borderRadius: '10px',
    padding: '6px 12px',
    backgroundColor: colors.white,
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '0.8rem',
    },
    ':hover': {
      border: 'none',
      backgroundColor: 'transparent',
    },
  });
  const disableButtonStyles = css({
    border: 'none',
    outline: 'none',
    fontSize: '1rem',
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'poppins-regular',
    color: colors.darkBlue,
    backgroundColor: '#f5f3f3',
    padding: '6px 12px',
    cursor: 'not-allowed',
    '@media (max-width: 600px)': {
      fontSize: '0.8rem',
    },
    ':hover': {
      border: 'none',
      backgroundColor: 'transparent',
    },
  });

  return (
    <div>
      {!isDisable && (
        <div className={wrapperStyles} onClick={onClick}>
          {icon && <img className={iconStyles} src={icon} alt={name} />}
          <button className={buttonStyles} type="button" disabled={isLoading}>
            {name}
          </button>
        </div>
      )}
      {isDisable && (
        <div className={DisableWrapperStyles} onClick={onClick}>
          <img className={iconStyles} src={icon} alt={name} />
          <button className={disableButtonStyles} type="button" disabled>
            {name}
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomIconButton;
