/* eslint-disable prettier/prettier */
import { useStyletron } from 'styletron-react';
import { SIZE, Select, TYPE, Value } from 'baseui/select';
import { useEffect, useRef, useState } from 'react';
import FiltersModalCloseIcon from '../../../../assets/icons/filter-modal-close.svg';
import AllFilters from './allFilter';
import { Axios } from 'core/services/http';
import {
  grossSaleFormula,
  selectMonth,
  selectPeriod,
} from 'modules/shared/constant';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { toaster } from 'baseui/toast';
import { useSelectedCompanies } from 'context/companies';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';

interface SelectType {
  label: string;
  id: string;
}

interface PresetDataType {
  datePeriod: string;
  year: string | number;
  month: string | number;
  weekId: string | number;
  grossSaleFormula: string;
  isCategoriesChecked?: boolean;
  isAccountsChecked?: boolean;
  isPosDivisionsChecked?: boolean;
  isStoresChecked?: boolean;
  accountCategoriesIds: string[];
  accountNamesIds: string[];
  posDivisionsIds: string[];
  storesIds: string[];
}

const AnalyticsFilters = ({
  setTableData,
  setTotal,
  setIsLoading,
  setNoOfPattern,
  isCogsPattern = false,
  isCogsAccount = false,
  isCogsCategory = false,
  isLoading,
}: any) => {
  const [css] = useStyletron();
  const { selectedCompanies, selectedCurrency, storesOptions } =
    useSelectedCompanies();
  const axios = new Axios().getInstance();
  const axiosInstance = new Axios();
  const [selectedPeriod, setSelectedPeriod] = useState<SelectType | null>(null);
  const [selectedGrossSaleFormula, setSelectedGrossSaleFormula] =
    useState<Value>([
      {
        label: 'Gross Minus Actual VAT',
        id: 'gross_bf_discount_minus_vat',
      },
    ]);
  const [selectedYear, setSelectedYear] = useState<any>({});
  const [selectedMonth, setSelectedMonth] = useState<any>({});
  const [selectedWeeks, setSelectedWeeks] = useState<any>({});
  const [categoriesOptions, setCategoriesOptions] = useState<any>([]);
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] =
    useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isCategoriesChecked, setIsCategoriesChecked] =
    useState<boolean>(false);
  const [isAccountsChecked, setIsAccountsChecked] = useState<boolean>(false);
  const [isTypesChecked, setIsTypesChecked] = useState<boolean>(false);
  const [weeks, setWeeks] = useState<any>([]);
  const [weeksOptions, setWeeksOptions] = useState<any>([]);
  const [weeksOptionsForAPI, setWeeksOptionsForAPI] = useState<any>([]);
  const [divisionsOptions, setDivisionsOptions] = useState<any>([]);
  const [selectedTypes, setSelectedTypes] = useState<any>([]);
  const [selectedStores, setSelectedStores] = useState<any>([]);
  const [accountNameOptions, setAccountNameOptions] = useState<any>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>([]);
  const [selectedPattern, setSelectedPattern] = useState<any>([
    { id: 3, label: '3 month/week' },
  ]);
  const [presetOptions, setPresetOptions] = useState<any>([]);
  const [selectedPreset, setSelectedPreset] = useState<any>([]);
  const [isDataReady, setIsDataReady] = useState(false);
  const [selectedPresetData, setSelectedPresetData] =
    useState<PresetDataType | null>(null);

  const [abortController, setAbortController] =
    useState<AbortController | null>(null); // For request cancelation

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [salesLastFetchedAt, setSalesLastFetchedAt] = useState<any>('');
  const [cogsLastFetchedAt, setCogsLastFetchedAt] = useState<any>('');
  const [isApplyingPreset, setIsApplyingPreset] = useState(true);
  const [hasAppliedPreset, setHasAppliedPreset] = useState(false);

  // Toggle dropdown visibility
  const handleToggleClick = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  // AbortController setup
  const createNewAbortController = () => {
    const controller = new AbortController();
    setAbortController(controller);
    return controller;
  };
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsFilterSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  const pattern = [
    { id: 1, label: '1 month/week' },
    { id: 2, label: '2 month/week' },
    { id: 3, label: '3 month/week' },
    { id: 6, label: '6 month/week' },
    { id: 9, label: '9 month/week' },
    { id: 12, label: '12 month/week' },
  ];

  useEffect(() => {
    const body = document.body;
    const className = 'overflow-x-hidden';

    if (isFilterSidebarOpen) {
      body.classList.add(className);
    } else {
      body.classList.remove(className);
    }

    return () => {
      body.classList.remove(className);
    };
  }, [isFilterSidebarOpen]);

  const fetchPresets = async () => {
    try {
      const response = await axios.get('/analytics-presets');
      const presets = response.data.data.map((preset: any) => ({
        id: preset.id,
        label: preset.name,
        ...preset,
      }));
      setPresetOptions(presets);

      const systemPreset = presets.find((preset: any) => preset.isDefault);
      if (systemPreset) {
        setSelectedPresetData(systemPreset);
      }
    } catch (error) {
      toaster.negative('Failed to fetch presets', {
        autoHideDuration: 4000,
      });
    }
  };

  useEffect(() => {
    fetchPresets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchAccounts();
      await fetchAccountCategories();
      await fetchDivisions();
      setIsDataReady(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isDataReady && selectedPresetData && storesOptions) {
      applyPreset(selectedPresetData);
    }
    // eslint-disable-next-line
  }, [isDataReady, selectedPresetData, storesOptions]);

  const applyPreset = (preset: any) => {
    setIsApplyingPreset(true);
    setSelectedPreset([{ id: preset._id, label: preset.name }]);

    // Set date period
    const periodOption = selectPeriod.find(
      (opt) => opt.id === preset.datePeriod
    );
    setSelectedPeriod(
      periodOption ? { id: periodOption.id, label: periodOption.label } : null
    );

    // Set year
    const currentYear = new Date().getFullYear();
    const selectedYear =
      preset.year === 'current_year'
        ? { id: currentYear, label: currentYear.toString() }
        : { id: currentYear - 1, label: (currentYear - 1).toString() };
    setSelectedYear(selectedYear);

    // Set month based on current or last month
    const currentMonth = new Date().getMonth() + 1;
    const selectedMonth =
      preset.month === 'current_month'
        ? selectMonth.find((opt) => opt.id === currentMonth.toString())
        : selectMonth.find((opt) => opt.id === (currentMonth - 1).toString());
    setSelectedMonth(selectedMonth || {});

    // Fetch weeks for the year before setting the week
    fetchWeeksForYear(currentYear).then((weekOptions: any) => {
      const currentWeekId = getCurrentWeekId(weekOptions);
      const lastWeekId = getLastWeekId(weekOptions, currentWeekId); // Get the previous week to the current one
      // Set the selected week based on the preset
      const selectedWeekId =
        preset.weekId === 'current_week' ? currentWeekId : lastWeekId;

      const matchedWeek = weekOptions.find(
        (week: any) => week.id === selectedWeekId
      );
      setSelectedWeeks(
        matchedWeek
          ? {
              id: matchedWeek.id,
              label: (
                <span style={{ fontSize: '13px' }}>{matchedWeek.label}</span>
              ),
            }
          : {}
      );
    });

    // Set categories, account names, types, and stores based on options and isChecked flags
    setSelectedCategories(
      preset.isCategoriesChecked ?? true
        ? categoriesOptions // Select all if isChecked is true
        : categoriesOptions.filter((opt: any) =>
            preset.accountCategoriesIds.includes(opt.id)
          )
    );
    setIsCategoriesChecked(preset.isCategoriesChecked ?? true);
    setIsAccountsChecked(preset.isAccountsChecked ?? true);
    setIsTypesChecked(preset.isPosDivisionsChecked ?? true);
    setIsChecked(preset.isStoresChecked ?? true);

    setSelectedAccount(
      preset.isAccountsChecked ?? true
        ? accountNameOptions // Select all if isChecked is true
        : accountNameOptions.filter((opt: any) =>
            preset.accountNamesIds.includes(opt.id)
          )
    );

    setSelectedTypes(
      preset.isPosDivisionsChecked ?? true
        ? divisionsOptions // Select all if isChecked is true
        : divisionsOptions.filter((opt: any) =>
            preset.posDivisionsIds.includes(opt.id)
          )
    );

    setSelectedStores(
      preset.isStoresChecked ?? true
        ? storesOptions // Select all if isChecked is true
        : storesOptions.filter((opt: any) => preset.storesIds.includes(opt.id))
    );

    // setSelectedPattern(preset.noOfPattern || '');
    setSelectedGrossSaleFormula([
      { id: preset.grossSaleFormula, label: preset.grossSaleFormula },
    ]);
    // Indicate that the initial preset application is completed
    setIsApplyingPreset(false);
  };

  const checkForDeviations = (presets: any[]) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const matchingPreset = presets.find((preset) => {
      const periodOption = selectPeriod.find(
        (opt) => opt.id === preset.datePeriod
      );
      const yearOption =
        preset.year === 'current_year'
          ? { id: currentYear, label: currentYear.toString() }
          : { id: currentYear - 1, label: (currentYear - 1).toString() };
      const monthOption =
        preset.month === 'current_month'
          ? selectMonth.find((opt) => opt.id === currentMonth.toString())
          : selectMonth.find((opt) => opt.id === (currentMonth - 1).toString());

      const categoriesMatch =
        (preset.isCategoriesChecked ??
          (true && selectedCategories.length === categoriesOptions.length)) ||
        (!preset.isCategoriesChecked &&
          preset.accountCategoriesIds.every((id: any) =>
            selectedCategories.some((cat: any) => cat.id === id)
          ));

      const accountsMatch =
        (preset.isAccountsChecked ??
          (true && selectedAccount.length === accountNameOptions.length)) ||
        (!preset.isAccountsChecked &&
          preset.accountNamesIds.every((id: any) =>
            selectedAccount.some((acc: any) => acc.id === id)
          ));

      const typesMatch =
        (preset.isPosDivisionsChecked ??
          (true && selectedTypes.length === divisionsOptions.length)) ||
        (!preset.isPosDivisionsChecked &&
          preset.posDivisionsIds.every((id: any) =>
            selectedTypes.some((type: any) => type.id === id)
          ));

      const storesMatch =
        (preset.isStoresChecked ??
          (true && selectedStores.length === storesOptions.length)) ||
        (!preset.isStoresChecked &&
          preset.storesIds.every((id: any) =>
            selectedStores.some((store: any) => store.id === id)
          ));

      return (
        periodOption?.id === selectedPeriod?.id &&
        yearOption.id === selectedYear?.id &&
        monthOption?.id === selectedMonth?.id &&
        categoriesMatch &&
        accountsMatch &&
        typesMatch &&
        storesMatch
      );
    });

    if (matchingPreset) {
      setSelectedPreset([
        { id: matchingPreset._id, label: matchingPreset.name },
      ]);
    } else {
      setSelectedPreset([{ id: 'custom', label: 'Custom Preset' }]);
    }
  };

  useEffect(() => {
    if (!isApplyingPreset) {
      checkForDeviations(presetOptions);
    }
    // eslint-disable-next-line
  }, [
    selectedPeriod,
    selectedYear,
    selectedMonth,
    selectedWeeks,
    selectedCategories,
    selectedAccount,
    selectedTypes,
    selectedStores,
  ]);

  // Effect to apply filters after default preset application
  useEffect(() => {
    if (
      !isApplyingPreset &&
      !hasAppliedPreset &&
      selectedCompanies &&
      selectedCompanies.length > 0
    ) {
      handleApplyFilters(); // Call handleApplyFilters after preset is fully applied
      setHasAppliedPreset(true);
    }
    // eslint-disable-next-line
  }, [isApplyingPreset, selectedCompanies]);

  // Fetch weeks based on the selected year
  const fetchWeeksForYear = async (year: any) => {
    const response = await axios.get('analytics/week-calender');
    if (response.data.weeks) {
      const filteredWeeks = response.data.weeks.filter(
        (week: any) => String(week.year) === String(year)
      );
      setWeeks(filteredWeeks);
      setWeeksOptionsForAPI(filteredWeeks);

      // Set the options for the weeks dropdown
      const weekOptions = filteredWeeks.map((week: any) => ({
        label: (
          <span style={{ fontSize: '13px' }}>
            <b>Week {week.id}</b> {week.fromDate} to {week.toDate}
          </span>
        ),
        id: week.id,
      }));
      setWeeksOptions(weekOptions);
      return filteredWeeks;
    }
  };

  // Helper function to get the current week ID based on the current date
  // If not found, pick the closest lower week
  const getCurrentWeekId = (weeks: any) => {
    const today = new Date();
    // Find the first week that contains today’s date
    let currentWeek = weeks.find(
      (week: any) =>
        new Date(week.fromDate) <= today && today <= new Date(week.toDate)
    );

    // If the current week is not found, find the closest previous week
    if (!currentWeek) {
      for (let i = weeks.length - 1; i >= 0; i--) {
        if (new Date(weeks[i].toDate) < today) {
          currentWeek = weeks[i];
          break;
        }
      }
    }

    return currentWeek ? currentWeek.id : null;
  };

  // Helper function to get the previous (last) week ID relative to the current week ID
  const getLastWeekId = (weeks: any, currentWeekId: any) => {
    const currentIndex = weeks.findIndex(
      (week: any) => week.id === currentWeekId
    );
    return currentIndex > 0 ? weeks[currentIndex - 1].id : null; // Previous week or null if at the start
  };

  const handlePresetChange = (selected: Value) => {
    const preset = selected[0];
    setSelectedPreset([{ label: preset.label, id: preset._id }]);
    if (preset) {
      // Set period based on preset
      const periodOption = selectPeriod.find(
        (opt) => opt.id === preset.datePeriod
      );
      setSelectedPeriod(
        periodOption ? { id: periodOption.id, label: periodOption.label } : null
      );

      // Set year based on "current_year" or "last_year"
      const currentYear = new Date().getFullYear();
      const selectedYear =
        preset.year === 'current_year'
          ? { id: currentYear, label: currentYear.toString() }
          : { id: currentYear - 1, label: (currentYear - 1).toString() };
      setSelectedYear(selectedYear);

      // Set month based on "current_month" or "last_month"
      const currentMonth = new Date().getMonth() + 1;
      const selectedMonth =
        preset.month === 'current_month'
          ? selectMonth.find((opt) => opt.id === currentMonth.toString())
          : selectMonth.find((opt) => opt.id === (currentMonth - 1).toString());
      setSelectedMonth(selectedMonth || {});

      // Fetch weeks based on the selected year and set the week
      fetchWeeksForYear(selectedYear.id).then((weekOptions: any) => {
        const currentWeekId = getCurrentWeekId(weekOptions);
        const lastWeekId = getLastWeekId(weekOptions, currentWeekId);
        // Determine the selected week based on "current_week" or "last_week"
        const selectedWeekId =
          preset.weekId === 'current_week' ? currentWeekId : lastWeekId;

        const matchedWeek = weekOptions.find(
          (week: any) => week.id === selectedWeekId
        );
        setSelectedWeeks(
          matchedWeek
            ? {
                id: matchedWeek.id,
                label: (
                  <span style={{ fontSize: '13px' }}>{matchedWeek.label}</span>
                ),
              }
            : {}
        );
      });

      setIsCategoriesChecked(preset.isCategoriesChecked ?? true);
      setIsAccountsChecked(preset.isAccountsChecked ?? true);
      setIsTypesChecked(preset.isPosDivisionsChecked ?? true);
      setIsChecked(preset.isStoresChecked ?? true);

      // Set categories, accounts, types, and stores based on isChecked flags
      setSelectedCategories(
        preset.isCategoriesChecked ?? true
          ? categoriesOptions
          : categoriesOptions.filter((opt: any) =>
              preset.accountCategoriesIds.includes(opt.id)
            )
      );

      setSelectedAccount(
        preset.isAccountsChecked ?? true
          ? accountNameOptions
          : accountNameOptions.filter((opt: any) =>
              preset.accountNamesIds.includes(opt.id)
            )
      );

      setSelectedTypes(
        preset.isPosDivisionsChecked ?? true
          ? divisionsOptions
          : divisionsOptions.filter((opt: any) =>
              preset.posDivisionsIds.includes(opt.id)
            )
      );

      setSelectedStores(
        preset.isStoresChecked ?? true
          ? storesOptions
          : storesOptions.filter((opt: any) =>
              preset.storesIds.includes(opt.id)
            )
      );

      // setSelectedPattern(preset.noOfPattern || '');
      setSelectedGrossSaleFormula([
        { id: preset.grossSaleFormula, label: preset.grossSaleFormula },
      ]);
    }
  };

  useEffect(() => {
    const fetchWeeks = async () => {
      const response = await axios.get('analytics/week-calender');
      if (response.data.weeks) {
        setWeeks(response.data.weeks);
      }
    };
    fetchWeeks();
    // eslint-disable-next-line
  }, []);

  const fetchDivisions = async () => {
    try {
      const response = await axios.get('/pos-divisions');
      const divisions = response.data.data.map((division: any) => ({
        id: division.id,
        label: division.olapName,
      }));
      setDivisionsOptions([...divisions]);
    } catch (error) {
      console.error('Error fetching divisions:', error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account-names', {
        params: { status: 'active' },
      });
      const accounts = response.data.data.map((account: any) => ({
        id: account.id,
        label: account.olapName,
      }));
      setAccountNameOptions([...accounts]);
      // setSelectedAccount(accounts);
    } catch (error) {
      console.error('Error fetching account names:', error);
    }
  };
  const fetchAccountCategories = async () => {
    try {
      const response = await axios.get('/account-categories', {
        params: { status: 'active' },
      });
      const accountCats = response.data.data.map((cat: any) => ({
        id: cat.id,
        label: cat.name,
      }));
      setCategoriesOptions([...accountCats]);
    } catch (error) {
      console.error('Error fetching account categories:', error);
    }
  };

  const handleApplyFilters = async (from_api: boolean = false) => {
    if (selectedCompanies.length === 0) {
      toaster.negative('Please select a company at the top.', {
        autoHideDuration: 2000,
      });
      return;
    }
    if (!selectedPeriod) {
      toaster.negative('Please select a period.', {
        autoHideDuration: 2000,
      });
      return;
    }
    if (
      selectedPeriod.id === 'year' &&
      Object.keys(selectedYear).length === 0
    ) {
      toaster.negative('Please select a year.', {
        autoHideDuration: 2000,
      });
      return;
    }
    if (
      selectedPeriod.id === 'month' &&
      (selectedYear.length === 0 || Object.keys(selectedMonth).length === 0)
    ) {
      toaster.negative('Please select a year and a month.', {
        autoHideDuration: 2000,
      });
      return;
    }
    if (
      selectedPeriod.id === 'week' &&
      (selectedYear.length === 0 || Object.keys(selectedWeeks).length === 0)
    ) {
      toaster.negative('Please select a year, a month, and a week.', {
        autoHideDuration: 2000,
      });
      return;
    }
    setIsFilterSidebarOpen(false);

    // Create an abort controller for the current request
    const controller = createNewAbortController();

    const filters: any = {
      periodType: selectedPeriod.id,
      grossSaleFormula: selectedGrossSaleFormula[0].id,
      year: selectedYear.id,
      month: selectedPeriod.id === 'month' ? selectedMonth.id : null,
      week: selectedPeriod.id === 'week' ? selectedWeeks.id : null,
      from_api,
      storesOptions: storesOptions.map((store: any) => store.id),
      // Include only if not checked
      ...(isChecked
        ? {}
        : { stores: selectedStores.map((store: any) => store.id) }),
      ...(isCategoriesChecked
        ? {}
        : {
            productCategories: selectedCategories.map(
              (category: any) => category.id
            ),
          }),
      ...(isTypesChecked
        ? {}
        : { productTypes: selectedTypes.map((type: any) => type.id) }),
      ...(isAccountsChecked
        ? {}
        : { productNames: selectedAccount.map((name: any) => name.id) }),
      mode: 'summary',
      csIntegrationIds: selectedCompanies,
      selectedCurrency,
    };
    if (selectedPeriod.id === 'week' && selectedWeeks) {
      const selectedWeek = weeks.find(
        (week: any) => week.id === selectedWeeks.id
      );
      if (selectedWeek) {
        filters.fromDate = selectedWeek.fromDate;
        filters.toDate = selectedWeek.toDate;
      }
    }

    if (isCogsPattern) {
      filters.noOfPattern = selectedPattern?.[0]?.id ?? 3;
      filters.mode = 'pattern';
      filters.weekOptions = weeksOptionsForAPI;
    }
    if (isCogsAccount) {
      filters.mode = 'cogs-account';
    }
    if (isCogsCategory) {
      filters.mode = 'cogs-category';
    }

    try {
      setIsLoading(true);
      setNoOfPattern && setNoOfPattern(selectedPattern[0].id);
      setTableData([]);
      if (!isCogsPattern && !isCogsAccount && !isCogsCategory) setTotal({});
      // Use the new `requestWithAbort` method, passing the `signal`
      const response = await axiosInstance.requestWithAbort(
        {
          method: 'post',
          url: '/analytics',
          data: filters,
        },
        controller.signal
      );
      setIsLoading(false);
      if (response.data.errorMessage && response.data.errorMessage !== '') {
        toaster.negative(response.data.errorMessage);
        return;
      }
      if (!isCogsPattern && !isCogsAccount && !isCogsCategory) {
        setTableData(response.data.data.data);
        setTotal(response.data.data.total);
      } else if (isCogsAccount || isCogsCategory) {
        setTableData(response.data.data.result);
        if (response.data.data.error && response.data.data.error !== '') {
          toaster.negative(response.data.data.error);
        }
      } else {
        setTableData(response.data.data);
      }
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
      if (error.name === 'AbortError') {
        toaster.warning('Request canceled by user.', {
          autoHideDuration: 3000,
        });
      } else {
        toaster.negative('Internal Server Error', { autoHideDuration: 4000 });
        console.error('Error fetching analytics data', error);
      }
    }
  };

  // Function to cancel the request
  const handleCancelRequest = () => {
    if (abortController) {
      abortController.abort(); // Cancel the current API request
      setAbortController(null); // Reset the abort controller
    }
  };

  const handleYearChange = (selectedYear: any, formattedDate = null) => {
    if (formattedDate) {
      // Filter weeks based on the selected year
      const filteredWeeks = weeks.filter(
        (week: any) => String(week.year) === String(selectedYear)
      );

      // Set the weeks for API call
      setWeeksOptionsForAPI(filteredWeeks);

      // Map weeks to options for the dropdown
      const weekOptions = filteredWeeks.map((week: any) => ({
        label: (
          <span style={{ fontSize: '13px' }}>
            <b>Week {week.id}</b> {week.fromDate} to {week.toDate}
          </span>
        ),
        id: week.id,
      }));

      setWeeksOptions(weekOptions);
      const matchedWeek = filteredWeeks?.find(
        (week: any) =>
          week.fromDate <= formattedDate && week.toDate >= formattedDate
      );

      if (matchedWeek && setSelectedWeeks) {
        setSelectedWeeks({
          id: matchedWeek.id,
          label: <span style={{ fontSize: '13px' }}>{matchedWeek.label}</span>,
        });
      }
      setSelectedYear({ id: selectedYear, label: selectedYear.toString() });
    } else {
      setSelectedYear(selectedYear);
      // Only run if the period is 'week' and a year is selected
      if (selectedPeriod?.id === 'week' && selectedYear) {
        // Clear the weeks on year change
        setSelectedWeeks({});

        // Filter weeks based on the selected year
        const filteredWeeks = weeks.filter(
          (week: any) => week.year === selectedYear.id
        );

        // Set the weeks for API call
        setWeeksOptionsForAPI(filteredWeeks);

        // Map weeks to options for the dropdown
        const weekOptions = filteredWeeks.map((week: any) => ({
          label: (
            <span style={{ fontSize: '13px' }}>
              <b>Week {week.id}</b> {week.fromDate} to {week.toDate}
            </span>
          ),
          id: week.id,
        }));

        setWeeksOptions(weekOptions);
      } else {
        // Clear weeks if the period is not 'week' or no year is selected
        setWeeksOptions([]);
      }
    }
  };

  const filtersWrapper = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  });

  const filterGrid = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)', // Adjust as needed
    gap: '8px',
    alignItems: 'center',
  });
  const filterPopupButton = css({
    textAlign: 'center',
    fontSize: '12px',
    borderRadius: '8px', // Consistent border radius
    border: '2px solid #A9A9A9',
    padding: '0px 8px', // Same padding as buttonStyleRefresh
    backgroundColor: 'var(--primary)',
    color: 'black',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'all 0.3s ease',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    height: '41px', // Consistent height
    display: 'flex', // To match buttonStyleRefresh
    alignItems: 'center', // Center align vertically
    gap: '4px',
    ':hover': {
      backgroundColor: 'var(--darkPrimary)',
      borderColor: 'var(--darkPrimary)',
      color: 'black',
      transform: 'scale(1.05)',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.4)',
    },
    ':focus': {
      outline: 'none',
      transform: 'scale(1.05)',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.4)',
    },
  });
  const filterModal = css({
    position: 'fixed',
    top: '0',
    right: '0',
    width: '410px',
    height: '100%',
    boxShadow: `"1px·1px·10px·#000"`,
    backgroundColor: 'rgba(31, 32, 58, 0.6)',
    zIndex: '3',
    display: isFilterSidebarOpen ? 'block' : 'none',
  });
  const filterModalSidebar = css({
    width: '100%',
    maxWidth: '410px',
    backgroundColor: '#fff',
    padding: '24px 24px 110px',
    margin: '0 0 0 auto',
    overflowY: 'auto',
    height: '100vh',
    boxShadow: '0 0 10px #000',
  });
  const filterModalSidebarTitle = css({
    fontSize: '20px',
    lineHeight: '30px',
    fontFamily: 'poppins-medium',
    color: 'var(--black)',
    margin: '26.4px 0',
  });
  const filterModalSidebarAction = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  });
  const filterModalSidebarClose = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '34px',
    height: '34px',
    backgroundColor: 'rgba(48, 51, 147, 0.1)',
    borderRadius: '4px',
    outline: 'none',
    border: '1px solid rgba(48, 51, 147, .10)',
    cursor: 'pointer',
  });
  const filterModalSidebarGrid = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gap: '32px',
    alignItems: 'center',
  });
  const filterLabel = css({
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: '400',
    color: 'var(--black)',
    margin: '0 0 8px',
  });

  const selectOverrides = {
    ControlContainer: {
      style: () => ({
        // border: '1px solid var(--lightGray)',
        borderRadius: '4px',
        backgroundColor: '#fff',
        color: 'var(--black)',
        padding: '4px',
        width: '100%',
        maxHeight: '100px',
        overflowY: 'auto',
        fontFamily: 'poppins-medium',
      }),
    },
    ValueContainer: {
      style: () => ({
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        fontFamily: 'poppins-medium',
      }),
    },
    Tag: {
      style: () => ({
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '4px 8px',
        fontSize: '16px',
        fontFamily: 'poppins-medium',
      }),
    },
    Placeholder: {
      style: () => ({
        color: 'rgba(31, 32, 58, 0.50)',
        fontSize: '16px',
        lineHeight: '21px',
        fontWeight: '400',
        fontFamily: 'poppins-medium',
      }),
    },
    SingleValue: {
      style: () => ({
        color: 'var(--black)',
        fontSize: '16px',
        lineHeight: '21px',
        fontWeight: '400',
        fontFamily: 'poppins-medium',
      }),
    },
    Dropdown: {
      style: () => ({
        backgroundColor: '#fff',
        border: '1px solid rgba(48, 51, 147, 0.10)',
        borderRadius: '4px',
        boxShadow: '0px 10px 20px 0px rgba(48, 51, 147, 0.10)',
        padding: '0px',
      }),
    },
    DropdownListItem: {
      style: () => ({
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'poppins-medium',
        lineHeight: '21px',
        color: 'var(--black)',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 35px', // Adjust padding for proper spacing
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ':not(:last-child)': {
          borderBottom: '1px solid rgba(48, 51, 147, .10)',
        },
      }),
    },
    OptionContent: {
      style: () => ({
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'poppins-medium',
        marginLeft: '8px',
      }),
    },
  };

  const storeWrapper = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
  });

  const buttonStyle = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '12px',
    borderRadius: '8px',
    border: '2px solid #A9A9A9',
    padding: '8px 12px',
    backgroundColor: 'var(--primary)',
    color: 'black',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'all 0.3s ease',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    height: '41px',
    ':hover': {
      backgroundColor: 'var(--darkPrimary)',
      borderColor: 'var(--darkPrimary)',
      color: 'black',
      transform: 'scale(1.05)',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.4)',
    },
    ':focus': {
      outline: 'none',
      transform: 'scale(1.05)',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.4)',
    },
  });

  const getToggleStyles = (isChecked: boolean) => ({
    Toggle: {
      style: () => ({
        backgroundColor: '#fff',
      }),
    },
    ToggleTrack: {
      style: () => ({
        backgroundColor: isChecked ? 'var(--blue)' : 'rgba(48, 51, 147, .20)',
        width: '34px',
      }),
    },
    Label: {
      style: () => ({
        outline: `none`,
        color: 'var(--black)',
        fontSize: '16px',
        lineHeight: '21px',
        fontWeight: '400',
        fontFamily: "'Poppins', sans-serif",
      }),
    },
  });

  const toggle = getToggleStyles(isChecked);
  const toggleCategories = getToggleStyles(isCategoriesChecked);
  const toggleAccount = getToggleStyles(isAccountsChecked);
  const toggleType = getToggleStyles(isTypesChecked);

  const abortButtonStyle = css({
    textAlign: 'center',
    fontSize: '12px',
    borderRadius: '10px',
    border: '2px solid var(--lightGray)',
    padding: '12px 12px',
    backgroundColor: '#E57373',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    ':hover': {
      backgroundColor: '#D32F2F',
      borderColor: 'var(--black)',
      color: 'var(--black)',
    },
    width: '30%',
  });
  // Styles
  const buttonContainerStyle = css({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  });

  const buttonStyleRefresh = css({
    textAlign: 'center',
    fontSize: '12px',
    borderRadius: '8px',
    border: '2px solid #A9A9A9',
    padding: '0px 8px',
    backgroundColor: 'var(--primary)',
    color: 'black',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'all 0.3s ease',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    height: '41px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    ':hover': {
      backgroundColor: 'var(--darkPrimary)',
      borderColor: 'var(--darkPrimary)',
      color: 'black',
      transform: 'scale(1.05)',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.4)',
    },
  });

  const dropdownStyle = css({
    position: 'absolute',
    top: '100%',
    left: '0',
    backgroundColor: '#fff',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    zIndex: 999,
    padding: '8px 0',
    minWidth: '150px',
    display: 'flex',
    flexDirection: 'column',
  });

  const dropdownItemStyle = css({
    padding: '8px 12px',
    fontSize: '12px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: 'var(--lightGray)',
    },
  });

  const toggleStyle = css({
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '0 4px',
    fontSize: '18px',
    lineHeight: '1',
    color: 'var(--black)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      color: 'var(--darkPrimary)',
    },
  });

  const buttonGroupStyle = css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  });

  const stickySection = css({
    position: 'sticky',
    top: '-27px',
    zIndex: '99999',
    backgroundColor: 'white',
    padding: '8px',
  });
  const handleRemoveCategory = (id: string) => {
    setSelectedCategories((prevCategories: any) =>
      prevCategories.filter((category: any) => category.id !== id)
    );
  };

  const handleRemoveAccount = (id: string) => {
    setSelectedAccount((prevAccounts: any) =>
      prevAccounts.filter((account: any) => account.id !== id)
    );
  };

  const handleRemoveType = (id: string) => {
    setSelectedTypes((prevAccounts: any) =>
      prevAccounts.filter((account: any) => account.id !== id)
    );
  };

  const handleRemoveStore = (id: string) => {
    setSelectedStores((prevAccounts: any) =>
      prevAccounts.filter((account: any) => account.id !== id)
    );
  };

  return (
    <>
      <div className={filtersWrapper}>
        <div>
          <div className={filterGrid}>
            <AllFilters
              setSelectedPeriod={setSelectedPeriod}
              selectedPeriod={selectedPeriod}
              setSelectedYear={handleYearChange}
              selectedYear={selectedYear}
              setSelectedMonth={setSelectedMonth}
              selectedMonth={selectedMonth}
              setSelectedWeeks={setSelectedWeeks}
              selectedWeeks={selectedWeeks}
              applyFilter={(from_api: boolean) => handleApplyFilters(from_api)}
              weeksOptions={weeksOptions}
              weeksOptionsForApi={weeksOptionsForAPI}
              handleYearChange={handleYearChange}
              shouldShowIcon={true}
              salesLastFetchedAt={salesLastFetchedAt}
              cogsLastFetchedAt={cogsLastFetchedAt}
            />
            {/* {isLoading && (
              <div className={abortButtonStyle} onClick={handleCancelRequest}>
                Abort
              </div>
            )} */}
          </div>
        </div>

        {/* Filter Popup */}
        <div
          className={filterPopupButton}
          onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
        >
          Filters
        </div>
      </div>

      <div className={filterModal} ref={modalRef}>
        <div className={filterModalSidebar}>
          <div className={stickySection}>
            <div className={buttonGroupStyle}>
              <button
                className={`${buttonStyle} button`}
                onClick={() => handleApplyFilters(false)}
              >
                <p>Apply Filters</p>
              </button>
              <div className={buttonContainerStyle} ref={dropdownRef}>
                {/* Main Refresh button that triggers handleApplyFilters */}
                <div
                  className={buttonStyleRefresh}
                  onClick={() => handleApplyFilters(false)} // Triggers applyFilter when clicking anywhere except the caret
                >
                  <p style={{ margin: 0 }}>Refresh</p>
                  {/* Toggle button for dropdown */}
                  <button
                    className={toggleStyle}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents handleApplyFilters from being triggered
                      handleToggleClick();
                    }}
                  >
                    <span className="caret-icon">▼</span>
                  </button>
                </div>

                {/* Dropdown for the secondary button */}
                {isDropdownVisible && (
                  <div className={dropdownStyle}>
                    <div
                      className={dropdownItemStyle}
                      onClick={() => {
                        handleApplyFilters(true);
                        setIsDropdownVisible(false); // Close dropdown after selection
                      }}
                    >
                      Refresh From ERP
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={filterModalSidebarAction}>
              <div>
                <div className={filterModalSidebarTitle}>Filters</div>
              </div>

              <div>
                <button
                  type="button"
                  className={`${filterModalSidebarClose} button`}
                  onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                >
                  <img src={FiltersModalCloseIcon} alt="" />
                </button>
              </div>
            </div>
          </div>
          <div>
            <label className={filterLabel}>Preset</label>
            <Select
              size={SIZE.compact}
              options={presetOptions}
              value={selectedPreset}
              placeholder="Select Preset"
              onChange={({ value }) => handlePresetChange(value)}
              overrides={selectOverrides}
            />

            <br />
            {isCogsPattern && (
              <>
                <div>
                  <label className={filterLabel}>Pattern Length</label>
                  <Select
                    size={SIZE.compact}
                    options={pattern?.map(({ id, label }: any) => ({
                      label,
                      id,
                    }))}
                    value={selectedPattern}
                    placeholder="Select Previous Month/Week For Pattern"
                    disabled={false}
                    onChange={({ value }) => {
                      setSelectedPattern(value);
                    }}
                    clearable={false}
                    searchable={false}
                  />
                </div>
                <br />
              </>
            )}
            <div className={storeWrapper}>
              <div className={filterLabel}>Categories</div>
              <div>
                <Checkbox
                  checked={isCategoriesChecked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setIsCategoriesChecked(e.currentTarget.checked)
                  }
                  checkmarkType={STYLE_TYPE.toggle_round}
                  overrides={toggleCategories}
                >
                  All Categories
                </Checkbox>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                paddingBottom: '10px',
                maxWidth: '100%',
                marginTop: '5px',
              }}
            >
              {selectedCategories.map((item: any) => (
                <div
                  key={item.id}
                  style={{
                    backgroundColor: '#e0e0e0',
                    borderRadius: '20px',
                    padding: '5px 10px',
                    margin: '0 5px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.label}
                  <span
                    style={{
                      marginLeft: '8px',
                      cursor: 'pointer',
                      fontSize: '12px',
                      color: '#555',
                    }}
                    onClick={() => handleRemoveCategory(item.id)}
                  >
                    &#x2715; {/* Cross icon */}
                  </span>
                </div>
              ))}
            </div>
            <div className="multi-selectbox">
              <SelectWithCheckboxes
                options={categoriesOptions}
                disabled={isCategoriesChecked}
                store={selectedCategories}
                name="Categorie"
                handleChange={(params: any) => setSelectedCategories(params)}
                isFullWidth={true}
              />
            </div>
            <br />
            <br />
            <div className={storeWrapper}>
              <div className={filterLabel}>Accounts</div>
              <div>
                <Checkbox
                  checked={isAccountsChecked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setIsAccountsChecked(e.currentTarget.checked)
                  }
                  checkmarkType={STYLE_TYPE.toggle_round}
                  overrides={toggleAccount}
                >
                  All Accounts
                </Checkbox>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                paddingBottom: '10px',
                maxWidth: '100%',
                marginTop: '5px',
              }}
            >
              {selectedAccount.map((item: any) => (
                <div
                  key={item.id}
                  style={{
                    backgroundColor: '#e0e0e0',
                    borderRadius: '20px',
                    padding: '5px 10px',
                    margin: '0 5px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.label}
                  <span
                    style={{
                      marginLeft: '8px',
                      cursor: 'pointer',
                      fontSize: '12px',
                      color: '#555',
                    }}
                    onClick={() => handleRemoveAccount(item.id)}
                  >
                    &#x2715; {/* Cross icon */}
                  </span>
                </div>
              ))}
            </div>
            <div className="multi-selectbox">
              <SelectWithCheckboxes
                options={accountNameOptions}
                disabled={isAccountsChecked}
                store={selectedAccount}
                name="Account"
                handleChange={(params: any) => setSelectedAccount(params)}
                isFullWidth={true}
              />
            </div>
            <br />
            <br />
            <div className={storeWrapper}>
              <div className={filterLabel}>POS Divisons</div>
              <div>
                <Checkbox
                  checked={isTypesChecked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setIsTypesChecked(e.currentTarget.checked)
                  }
                  checkmarkType={STYLE_TYPE.toggle_round}
                  overrides={toggleType}
                >
                  All POS Divisons
                </Checkbox>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                paddingBottom: '10px',
                maxWidth: '100%',
                marginTop: '5px',
              }}
            >
              {selectedTypes.map((item: any) => (
                <div
                  key={item.id}
                  style={{
                    backgroundColor: '#e0e0e0',
                    borderRadius: '20px',
                    padding: '5px 10px',
                    margin: '0 5px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.label}
                  <span
                    style={{
                      marginLeft: '8px',
                      cursor: 'pointer',
                      fontSize: '12px',
                      color: '#555',
                    }}
                    onClick={() => handleRemoveType(item.id)}
                  >
                    &#x2715; {/* Cross icon */}
                  </span>
                </div>
              ))}
            </div>
            <div className="multi-selectbox">
              <SelectWithCheckboxes
                options={divisionsOptions}
                disabled={isTypesChecked}
                store={selectedTypes}
                name="POS Division"
                handleChange={(params: any) => setSelectedTypes(params)}
                isFullWidth={true}
              />
            </div>
            <br />
            <div className="multi-selectbox">
              <label className={filterLabel}>Gross Sale Formula</label>
              <Select
                size={SIZE.compact}
                options={grossSaleFormula.map(({ id, label }: any) => ({
                  label,
                  id,
                }))}
                placeholder="Gross Sale Formula"
                closeOnSelect={true}
                value={selectedGrossSaleFormula}
                onChange={({ value }) => setSelectedGrossSaleFormula(value)}
                overrides={selectOverrides}
              />
            </div>
            <br />
            <div>
              <div
                style={{ marginBottom: '20px' }}
                className={filterModalSidebarGrid}
              >
                <AllFilters
                  setSelectedPeriod={setSelectedPeriod}
                  selectedPeriod={selectedPeriod}
                  setSelectedYear={handleYearChange}
                  selectedYear={selectedYear}
                  setSelectedMonth={setSelectedMonth}
                  selectedMonth={selectedMonth}
                  setSelectedWeeks={setSelectedWeeks}
                  selectedWeeks={selectedWeeks}
                  fitlerLabel={true}
                  applyFilter={(from_api: boolean) =>
                    handleApplyFilters(from_api)
                  }
                  weeksOptions={weeksOptions}
                  weeksOptionsForApi={weeksOptionsForAPI}
                  handleYearChange={handleYearChange}
                  shouldShowButtons={false}
                />
                <div className={storeWrapper}>
                  <div className={filterLabel}>Stores</div>
                  <div>
                    <Checkbox
                      checked={isChecked}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setIsChecked(e.currentTarget.checked)
                      }
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={toggle}
                    >
                      All Stores
                    </Checkbox>
                  </div>
                </div>
                {selectedStores && selectedStores.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      overflowX: 'auto',
                      paddingBottom: '10px',
                      maxWidth: '100%',
                    }}
                  >
                    {selectedStores.map((item: any) => (
                      <div
                        key={item.id}
                        style={{
                          backgroundColor: '#e0e0e0',
                          borderRadius: '20px',
                          padding: '5px 10px',
                          margin: '0 5px',
                          whiteSpace: 'nowrap',
                          fontSize: '10px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {item.label}
                        <span
                          style={{
                            marginLeft: '8px',
                            cursor: 'pointer',
                            fontSize: '12px',
                            color: '#555',
                          }}
                          onClick={() => handleRemoveStore(item.id)}
                        >
                          &#x2715; {/* Cross icon */}
                        </span>
                      </div>
                    ))}
                  </div>
                )}

                <div className="multi-selectbox">
                  <SelectWithCheckboxes
                    options={storesOptions?.map(({ id, label }: any) => ({
                      label,
                      id,
                    }))}
                    disabled={isChecked}
                    store={selectedStores}
                    name="Store"
                    handleChange={(params: any) => setSelectedStores(params)}
                    isFullWidth={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsFilters;
