import { AppContext } from 'context';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children }: any) => {
  const {
    state: { token },
  } = useContext(AppContext);
  const { pathname, search } = useLocation();
  return token && token !== '' ? (
    children
  ) : (
    <Navigate to="/" replace state={{ path: `${pathname}${search}` }} />
  );
  // return children;
};

export default RequireAuth;
