import React, { useState } from 'react';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { useStyletron } from 'styletron-react';
import colors from 'constants/colors';
import {
  NOTIFICATION_POPUP,
  NOTIFICATION_SOUND,
  NOTIFICATION_SOUND_ID,
} from 'constants/notification';
import { AppContext, DispatcherAction } from 'context';
import soundOptions from 'constants/SoundOptions';
const NotificationToggleMenu = () => {
  const {
    state: { notificationPopupEnabled, soundEnabled, selectedSound },
    dispatcher,
  } = React.useContext(AppContext);
  const [selectedValue, setSelectedValue] = useState(selectedSound);

  const [css] = useStyletron();
  const main = css({
    position: 'absolute',
    width: '200px',
    background: '#F4F7FA',
    zIndex: 2222,
    height: '150px',
    right: '0',
    top: '55px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '10px 20px',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 15px',
  });
  const label = css({
    fontSize: '16px',
    color: colors.darkBlue,
  });
  const stylePopup = css({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const toggleClicked = (value: boolean) => {
    localStorage.setItem(NOTIFICATION_POPUP, value ? '1' : '0');
    dispatcher(DispatcherAction.NotificationPopup, value);
  };
  const handleSoundEnabledChange = (value: boolean) => {
    localStorage.setItem(NOTIFICATION_SOUND, value ? '1' : '0');
    dispatcher(DispatcherAction.SoundEnabled, value);
  };

  const handleSelectedSoundChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedValue(e.target.value);
    localStorage.setItem(NOTIFICATION_SOUND_ID, e.target.value);
    dispatcher(DispatcherAction.SelectedSound, e.target.value);
  };

  return (
    <div className={main}>
      <div className={stylePopup}>
        <p className={label}>Notification popup</p>
        <Checkbox
          checked={notificationPopupEnabled}
          onChange={(e) => toggleClicked(e.currentTarget.checked)}
          checkmarkType={STYLE_TYPE.toggle_round}
        ></Checkbox>
      </div>
      <div className={stylePopup}>
        <p className={label}> Sound</p>
        <select
          style={{
            width: '70%',
            height: '40px',
            border: '1px solid #DFE3E8',
            borderRadius: '4px',
            padding: '0 10px',
            fontSize: '0.9rem',
            textAlign: 'center',
            fontWeight: '600',
            color: colors.darkGray,
            outline: 'none',
            backgroundColor: '#fff',
            cursor: 'pointer',
            appearance: 'none',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            boxShadow: 'none',
            WebkitBoxShadow: 'none',
            MozBoxShadow: 'none',
          }}
          value={selectedValue}
          onChange={handleSelectedSoundChange}
        >
          {soundOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className={stylePopup}>
        <p className={label}>Enable Sound</p>
        <Checkbox
          checked={soundEnabled}
          onChange={(e) => handleSoundEnabledChange(e.currentTarget.checked)}
          checkmarkType={STYLE_TYPE.toggle_round}
        ></Checkbox>
      </div>
    </div>
  );
};

export default NotificationToggleMenu;
