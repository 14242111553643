import { useRef, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import {
  KIND as ButtonKind,
  SHAPE as ButtonShape,
  Button,
} from 'baseui/button';
import { useStyletron } from 'styletron-react';
import * as xlsx from 'xlsx';
import { v4 } from 'uuid';

type Props = {
  isOpen: boolean;
  products: Array<any>;
  stores: Array<any>;
  setIsOpen: (isOpen: boolean) => void;
  onImport: (data: Array<any>) => void;
};

const ImportPriceContractsItems = ({
  isOpen,
  products,
  stores,
  setIsOpen,
  onImport,
}: Props) => {
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const [css] = useStyletron();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const browseFileClickedHandler = () => {
    fileInputRef.current?.click();
  };

  const isDataValid = (data: Array<any>) => {
    const errors: Array<any> = [];
    data.forEach((element: any, index: any) => {
      const rowsErrors: Array<any> = [];
      const rowDetails = `Row # ${index + 1}`;
      if (!element.vendorProductId) rowsErrors.push(`Product not found in row`);
      if (!element.priceWoVAT || element.priceWoVAT <= 0)
        rowsErrors.push(`Price should be greater than 0 in row`);
      if (!element.productPackagingUnit)
        rowsErrors.push(`Product Packaging Unit is required in row`);
      if (element.foundStores.length) {
        const storeList = element.foundStores;
        const foundStores = element.excludedStoreList;
        const foundStoreNames = foundStores.map(({ label }: any) =>
          label.trim()
        );
        const notFoundStores = storeList.filter(
          (label: any) => !foundStoreNames.includes(label.trim())
        );
        if (notFoundStores.length)
          rowsErrors.push(`Store(s) not found: ${notFoundStores.join(', ')}`);
      }
      if (!element.specialEmailAnyStore) {
        const storeList = element.foundSpecialEmailStores;
        const foundStores = element.specialEmailSpecificStoreList;
        const foundStoreNames = foundStores.map(({ label }: any) =>
          label.trim()
        );
        const notFoundStores = storeList.filter(
          (label: any) => !foundStoreNames.includes(label.trim())
        );
        if (notFoundStores.length)
          rowsErrors.push(
            `Special Email Store(s) not found: ${notFoundStores.join(', ')}`
          );
      }
      if (rowsErrors.length) {
        errors.push({
          rowDetails,
          rowsErrors,
        });
      }
    });
    return errors;
  };

  const processExtractedExcelData = (data: Array<any>, keys: any) => {
    const newKeys: string[] = [];
    keys.forEach((element: string) => {
      if (element === 'Product Code') newKeys.push('supplierProductCode');
      if (element === 'Product Name') newKeys.push('supplierProductName');
      if (element === 'Product Packaging Unit')
        newKeys.push('productPackagingUnit');
      if (element === 'Price without VAT') newKeys.push('priceWoVAT');
      if (element === 'Active') newKeys.push('isActive');
      if (element === 'Special Email All Stores')
        newKeys.push('specialEmailAnyStore');
      if (element === 'Special Email Stores')
        newKeys.push('specialEmailSpecificStoreList');
      if (element === 'Hide in Requisition') newKeys.push('hideInRequisition');
      if (element === 'Excluded Stores') newKeys.push('excludedStoreList');
    });
    const items = data.reduce((accumulator, current) => {
      let count = 0;
      const price: any = {};
      Object.entries(current).forEach(([_, value]: any) => {
        price[`${newKeys[count]}`] = value;
        count++;
      });
      accumulator.push(price);
      return accumulator;
    }, []);
    const transformed = items.reduce((acc: Array<any>, current: any) => {
      const {
        supplierProductCode,
        priceWoVAT,
        productPackagingUnit,
        isActive,
        specialEmailAnyStore,
        specialEmailSpecificStoreList,
        hideInRequisition,
        excludedStoreList,
      } = current;
      const product = products.find(
        (product) =>
          product.supplierProductCode === supplierProductCode &&
          product.productPackagingUnit === productPackagingUnit
      );
      let excludedStoreListArray = excludedStoreList
        ? excludedStoreList.split(',')
        : [];

      if (
        excludedStoreListArray.length === 1 &&
        excludedStoreListArray[0]?.trim() === '-'
      )
        excludedStoreListArray = [];

      const foundStores = excludedStoreListArray.reduce(
        (accumulator: Array<any>, store: any) => {
          const foundStore = stores.find(
            ({ label }) => label?.trim() === store?.trim()
          );
          if (foundStore) accumulator.push(foundStore);
          return accumulator;
        },
        []
      );
      const specialEmailSpecificStoreListArray = specialEmailSpecificStoreList
        ? specialEmailSpecificStoreList.split(',')
        : [];
      const foundSpecialEmailStores = specialEmailSpecificStoreListArray.reduce(
        (accumulator: Array<any>, store: any) => {
          const foundStore = stores.find(
            ({ label }) => label?.trim() === store?.trim()
          );
          if (foundStore) accumulator.push(foundStore);
          return accumulator;
        },
        []
      );
      acc.push({
        _id: v4(),
        vendorProductId: product || null,
        productPackagingUnit: productPackagingUnit || null,
        priceWoVAT: priceWoVAT || 0,
        isActive: isActive === 'TRUE',
        specialEmailAnyStore: specialEmailAnyStore === 'TRUE',
        foundSpecialEmailStores: specialEmailSpecificStoreListArray || [],
        specialEmailSpecificStoreList: foundSpecialEmailStores || [],
        hideInRequisition: hideInRequisition === 'TRUE',
        excludedStoreList: foundStores || [],
        foundStores: excludedStoreListArray || [],
      });
      return acc;
    }, []);
    console.log(transformed);
    const errors = isDataValid(transformed);
    if (errors.length) {
      setErrors(errors);
      setErrorModalOpen(true);
      return;
    }
    onImport(transformed);
  };

  const readFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      if (e?.target?.result) {
        const data = e.target.result;
        const workbook = xlsx.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let extractedData: any = xlsx.utils.sheet_to_json(worksheet);
        if (extractedData?.length) {
          processExtractedExcelData(
            extractedData,
            Object.keys(extractedData[0])
          );
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const fileInputChangeHandler = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      readFile(file);
    }
  };

  const downloadSampleClickedHandler = () => {
    const sampleUnits = [
      {
        'Product Code': 'NF00433',
        'Product Name': 'SUMA D4 DIS',
        'Product Packaging Unit': '12X1.5LTR',
        'Price without VAT': 45,
        Active: 'TRUE',
        'Special Email All Stores': 'TRUE',
        'Special Email Stores': '-',
        'Hide in Requisition': 'FALSE',
        'Excluded Stores': 'CPKMOE, MPKMOE, SPDMOE',
      },
      {
        'Product Code': 'FD00382',
        'Product Name': 'almont water',
        'Product Packaging Unit': '10X1KG',
        'Price without VAT': 56,
        Active: 'FALSE',
        'Special Email All Stores': 'FALSE',
        'Special Email Stores': 'CPKMOE, SPDMOE',
        'Hide in Requisition': 'TRUE',
        'Excluded Stores': '-',
      },
    ];
    const worksheet = xlsx.utils.json_to_sheet(sampleUnits);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Price Contract Items');
    xlsx.writeFile(workbook, 'PriceContract_Items_Import_Sample.xlsx');
  };

  return (
    <>
      <Modal
        onClose={() => setIsOpen(false)}
        closeable={false}
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.auto}
        role={ROLE.dialog}
      >
        <ModalHeader>
          Import Price Contract Items
          <input
            className={css({
              marginLeft: '16px',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              background: '#ececec',
              border: 'none',
              borderRadius: '16px',
              padding: '8px 16px',
              outline: 'none',
              fontSize: '1rem',
              color: '#00aeef',
              ':hover': {
                background: isLoading ? '#ececec' : '#00aeef',
                color: isLoading ? '#00aeef' : '#ffffff',
                fontWeight: isLoading ? '400' : '600',
              },
            })}
            type="button"
            value="Download sample"
            onClick={downloadSampleClickedHandler}
            disabled={isLoading}
          />
        </ModalHeader>
        <ModalBody>
          <div className={css({ minWidth: '400px' })}>
            <div
              className={css({
                margin: '10px 0',
                padding: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                border: '2px dashed #1abc9c',
                borderRadius: '8px',
              })}
            >
              <p className={css({ fontSize: '12px', color: '#9f9f9f' })}>
                Files Supported: XLSX
              </p>
              <input
                type="file"
                hidden
                accept=".xlsx"
                ref={fileInputRef}
                onChange={fileInputChangeHandler}
              />
              <Button
                kind={ButtonKind.secondary}
                shape={ButtonShape.pill}
                onClick={browseFileClickedHandler}
                isLoading={isLoading}
              >
                Browse
              </Button>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            type="button"
            shape={ButtonShape.pill}
            kind={ButtonKind.secondary}
            onClick={() => setIsOpen(false)}
            disabled={isLoading}
          >
            Cancel
          </ModalButton>
        </ModalFooter>
      </Modal>
      <Modal
        onClose={() => {
          setErrorModalOpen(false);
          setIsOpen(false);
        }}
        isOpen={errorModalOpen && isOpen}
        animate
        overrides={{
          Dialog: {
            style: {
              maxWidth: '80vw',
              minWidth: '50vw',
              maxHeight: '70vh',
              overflowY: 'auto',
            },
          },
        }}
      >
        <ModalHeader>Import Errors</ModalHeader>
        <ModalBody>
          {errors.map(({ rowDetails, rowsErrors }: any) => {
            return (
              <div key={rowDetails}>
                <h4
                  className={css({
                    fontWeight: 'bold',
                    fontSize: '18px',
                    margin: '10px 0',
                  })}
                >
                  {rowDetails}:
                </h4>
                <div>
                  {rowsErrors.map((error: any) => (
                    <p
                      className={css({
                        fontSize: '16px',
                        margin: '6px 0',
                      })}
                      key={error}
                    >
                      {error}
                    </p>
                  ))}
                </div>
              </div>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            type="button"
            shape={ButtonShape.pill}
            kind={ButtonKind.secondary}
            onClick={() => {
              setErrorModalOpen(false);
              setIsOpen(false);
            }}
            disabled={isLoading}
          >
            Close
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ImportPriceContractsItems;
