import { useEffect } from 'react';
type Props = {
  pdfBlobUrl: string;
  isLoading: boolean;
  setPdfBlobUrl: (a: any) => void;
};
const useExportPdf = ({ pdfBlobUrl, isLoading, setPdfBlobUrl }: Props) => {
  useEffect(() => {
    if (pdfBlobUrl && !isLoading) {
      const hidenPdFViewerIframe: HTMLIFrameElement =
        document.createElement('iframe');
      hidenPdFViewerIframe.style.display = 'none';
      document.body.appendChild(hidenPdFViewerIframe);
      hidenPdFViewerIframe.src = pdfBlobUrl;
      hidenPdFViewerIframe.onload = function () {
        // @ts-ignore
        hidenPdFViewerIframe?.contentWindow.print();
      };
      return () => {
        document.body.removeChild(hidenPdFViewerIframe);
        setPdfBlobUrl('');
      };
    }
  }, [pdfBlobUrl, isLoading]);
  return {};
};

export default useExportPdf;
