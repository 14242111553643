import React from 'react';
import { MultiSelect, Select } from 'baseui/select';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { DatePicker } from 'baseui/datepicker';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { useStyletron } from 'styletron-react';
import colors from 'constants/colors';
import './index.scss';
import { timeCheck } from 'modules/shared/time-check';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';
import { Input } from 'baseui/input';
import { OrderStatusEnum } from 'modules/shared/enums/order-status.enum';
import { useSelectedCompanies } from 'context/companies';

interface IProps {
  dates: Array<any>;
  dateFilters: Array<any>;
  stores: Array<any>;
  store: any;
  setStore: any;
  startDate: Array<any>;
  secondStartDate: Array<any>;
  endDate: Array<any>;
  secondEndDate: Array<any>;
  dateOption: any;
  secondDateOption: any;
  dateFilter: any;
  secondDateFilter: any;
  setStartDate: any;
  setSecondStartDate: any;
  setEndDate: any;
  setSecondEndDate: any;
  setDateOption: any;
  setSecondDateOption: any;
  setDateFilter: any;
  setSecondDateFilter: any;
  onSubmit: any;
  orderNumberSearch: any;
  setOrderNumberSearch: any;
  requisitionNumberSearch: any;
  setRequisitionNumberSearch: any;
  orderStatusFilterValue: any;
  handleOrderValueDropDownChange: any;
  isChangedAfterReleaseValue: any;
  handleIsChangedAfterReleaseDropDownChange: any;
  processingErrorCountValue: any;
  setProcessingErrorCountValue: any;
  users: any;
  createdByValue: any;
  setCreatedByValue: any;
  updatedByValue: any;
  setUpdatedByValue: any;
  releasedByValue: any;
  setReleasedByValue: any;
  vendors: any;
  handleVendorChange: any;
  vendorsValue: any;
  orderMinValue: any;
  setOrderMinValue: any;
  orderMaxValue: any;
  setOrderMaxValue: any;
  products: any;
  productsValue: any;
  handleProductChange: any;
  handleClear: any;
  isDateDisable: any;
  setIsDateDisable: any;
  validForAllStores: any;
  setValidForAllStores: any;
  activeAllFilters?: any;
}

const FilterList = ({
  stores,
  store,
  setStore,
  dates,
  dateFilters,
  startDate,
  secondStartDate,
  endDate,
  secondEndDate,
  dateOption,
  secondDateOption,
  setStartDate,
  setSecondStartDate,
  dateFilter,
  secondDateFilter,
  setEndDate,
  setSecondEndDate,
  setDateOption,
  setSecondDateOption,
  setDateFilter,
  setSecondDateFilter,
  onSubmit,
  orderNumberSearch,
  setOrderNumberSearch,
  requisitionNumberSearch,
  setRequisitionNumberSearch,
  orderStatusFilterValue,
  handleOrderValueDropDownChange,
  isChangedAfterReleaseValue,
  handleIsChangedAfterReleaseDropDownChange,
  processingErrorCountValue,
  setProcessingErrorCountValue,
  users,
  createdByValue,
  setCreatedByValue,
  updatedByValue,
  setUpdatedByValue,
  releasedByValue,
  setReleasedByValue,
  vendors,
  handleVendorChange,
  vendorsValue,
  orderMinValue,
  setOrderMinValue,
  orderMaxValue,
  setOrderMaxValue,
  products,
  productsValue,
  handleProductChange,
  handleClear,
  isDateDisable,
  setIsDateDisable,
  validForAllStores,
  setValidForAllStores,
  activeAllFilters,
}: IProps) => {
  const [css] = useStyletron();
  const { storesOptionsByCompanies } = useSelectedCompanies();
  const [isSecondDateDisable, setIsSecondDateDisable] =
    React.useState<any>(true);
  const orderStatusOptions = Object.values(OrderStatusEnum).map((value) => ({
    label: value,
    id: value,
  }));
  const isChnagedAfterReleaseOptions = [
    { id: 'true', label: 'True' },
    { id: 'false', label: 'False' },
  ];
  const storeOptions = storesOptionsByCompanies.map(({ id, label }: any) => ({
    label,
    id,
  }));
  const datesOptions = dates.map(({ id, label }) => ({ label, id }));
  const datesFilterOptions = dateFilters.map(({ id, label }) => ({
    label,
    id,
  }));

  const buttonStyleClear = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: '#FF0000',
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });
  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });

  const setDateInInput = (value: any) => {
    const date = timeCheck(value[0]?.id);
    setStartDate([date.startTime]);
    setEndDate([date.endTime]);
  };
  const setSecondDateInInput = (value: any) => {
    const date = timeCheck(value[0]?.id);
    setSecondStartDate([date.startTime]);
    setSecondEndDate([date.endTime]);
  };
  return (
    <div className="create-order-list">
      <div className={`${activeAllFilters ? 'visible' : 'hidden'}`}>
        <FlexGrid
          flexGridColumnCount={[1, 2, 7]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Date Period</div>
              <Select
                options={datesOptions}
                value={dateOption}
                placeholder="This month"
                disabled={false}
                onChange={(params: any) => {
                  setDateOption(params.value);
                  setDateInInput(params.value);
                  if (params.value[0]?.id === 'custom') {
                    setIsDateDisable(false);
                  } else setIsDateDisable(true);
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Start Date</div>
              <DatePicker
                disabled={isDateDisable}
                required={true}
                value={startDate}
                onChange={({ date }) => {
                  setStartDate(Array.isArray(date) ? date : [date]);
                }}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">End Date</div>
              <DatePicker
                disabled={isDateDisable}
                required={true}
                value={endDate}
                onChange={({ date }) =>
                  setEndDate(Array.isArray(date) ? date : [date])
                }
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Filter By</div>
              <Select
                options={datesFilterOptions}
                value={dateFilter}
                placeholder="Release Date"
                disabled={false}
                onChange={(params: any) => setDateFilter(params.value)}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Order Code</div>
              <Input
                type="text"
                value={orderNumberSearch}
                onChange={(e) => setOrderNumberSearch(e.target.value)}
                placeholder="Order code#"
                clearOnEscape
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Stores</div>
              <SelectWithCheckboxes
                options={storeOptions}
                disabled={validForAllStores}
                store={store}
                handleChange={(params: any) => setStore(params)}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label" style={{ marginBottom: '12px' }}>
                All stores
              </div>
              <Checkbox
                checked={validForAllStores}
                checkmarkType={STYLE_TYPE.toggle_round}
                overrides={{
                  Toggle: {
                    style: ({ $theme, $checked }) => ({
                      backgroundColor: $checked ? '#09B08C' : '#000',
                    }),
                  },
                }}
                onChange={(e) => {
                  setStore(stores);
                  setValidForAllStores(e.target.checked);
                }}
              />
            </div>
          </FlexGridItem>

          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Date Period</div>
              <Select
                options={datesOptions}
                value={secondDateOption}
                placeholder="Date Period"
                disabled={false}
                onChange={(params: any) => {
                  setSecondDateOption(params.value);
                  setSecondDateInInput(params.value);
                  if (params.value[0]?.id === 'custom') {
                    setIsSecondDateDisable(false);
                  } else setIsSecondDateDisable(true);
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Start Date</div>
              <DatePicker
                disabled={isSecondDateDisable}
                required={true}
                value={secondStartDate}
                onChange={({ date }) => {
                  setSecondStartDate(Array.isArray(date) ? date : [date]);
                }}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">End Date</div>
              <DatePicker
                disabled={isSecondDateDisable}
                required={true}
                value={secondEndDate}
                onChange={({ date }) =>
                  setSecondEndDate(Array.isArray(date) ? date : [date])
                }
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Filter By</div>
              <Select
                options={datesFilterOptions}
                value={secondDateFilter}
                placeholder="Supply Date"
                disabled={false}
                onChange={(params: any) => setSecondDateFilter(params.value)}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Requisition Code</div>
              <Input
                type="text"
                value={requisitionNumberSearch}
                onChange={(e) => setRequisitionNumberSearch(e.target.value)}
                placeholder="Requisition Code#"
                clearOnEscape
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Order status</div>
              <MultiSelect
                options={orderStatusOptions}
                labelKey="label"
                valueKey="id"
                value={orderStatusFilterValue}
                onChange={handleOrderValueDropDownChange}
                placeholder="Select options..."
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Is Changed After released</div>
              <Select
                options={isChnagedAfterReleaseOptions}
                labelKey="label"
                valueKey="id"
                value={isChangedAfterReleaseValue}
                onChange={handleIsChangedAfterReleaseDropDownChange}
                placeholder="Select options..."
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Processing Error Count</div>
              <Input
                type="number"
                value={processingErrorCountValue}
                onChange={(e) => setProcessingErrorCountValue(e.target.value)}
                placeholder="Processing Error count#"
                clearOnEscape
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Created By</div>
              <Select
                options={users}
                labelKey="label"
                valueKey="id"
                value={createdByValue}
                onChange={(params: any) => setCreatedByValue(params.value)}
                placeholder="Select User..."
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Updated By</div>
              <Select
                options={users}
                labelKey="label"
                valueKey="id"
                value={updatedByValue}
                onChange={(params: any) => setUpdatedByValue(params.value)}
                placeholder="Select User..."
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Released By</div>
              <Select
                options={users}
                labelKey="label"
                valueKey="id"
                value={releasedByValue}
                onChange={(params: any) => setReleasedByValue(params.value)}
                placeholder="Select User..."
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Order Value Min</div>
              <Input
                type="number"
                value={orderMinValue}
                onChange={(e) => setOrderMinValue(e.target.value)}
                placeholder="Order Min Value#"
                clearOnEscape
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Order Value Max</div>
              <Input
                type="number"
                value={orderMaxValue}
                onChange={(e) => setOrderMaxValue(e.target.value)}
                placeholder="Order Max Value#"
                clearOnEscape
              />
            </div>
          </FlexGridItem>
          <FlexGridItem></FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Products</div>
              <MultiSelect
                options={products}
                labelKey="label"
                valueKey="id"
                value={productsValue}
                onChange={handleProductChange}
                placeholder="Select options..."
              />
            </div>
          </FlexGridItem>
          <FlexGridItem display="none">
            This invisible one is needed so the margins line up
          </FlexGridItem>
          <FlexGridItem display="none">
            This invisible one is needed so the margins line up
          </FlexGridItem>

          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Vendors</div>
              <MultiSelect
                options={vendors}
                labelKey="label"
                valueKey="id"
                value={vendorsValue}
                onChange={handleVendorChange}
                placeholder="Select vendors..."
              />
            </div>
          </FlexGridItem>

          <FlexGridItem>
            <div className="button-wrapper">
              <button
                type="button"
                className={buttonStyleClear}
                onClick={handleClear}
              >
                Clear Filter
              </button>
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="btn-wrapper">
              <button type="button" className={buttonStyle} onClick={onSubmit}>
                Apply Filter
              </button>
            </div>
          </FlexGridItem>
        </FlexGrid>
      </div>

      <div className={`${!activeAllFilters ? 'visible' : 'hidden'}`}>
        <FlexGrid
          flexGridColumnCount={[1, 2, 7]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Date Period</div>
              <Select
                options={datesOptions}
                value={dateOption}
                placeholder="This Month"
                disabled={false}
                onChange={(params: any) => {
                  setDateOption(params.value);
                  setDateInInput(params.value);
                  if (params.value[0]?.id == 'custom') {
                    setIsDateDisable(false);
                  } else setIsDateDisable(true);
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Start Date</div>
              <DatePicker
                disabled={isDateDisable}
                required={true}
                value={startDate}
                onChange={({ date }) => {
                  setStartDate(Array.isArray(date) ? date : [date]);
                }}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">End Date</div>
              <DatePicker
                disabled={isDateDisable}
                required={true}
                value={endDate}
                onChange={({ date }) =>
                  setEndDate(Array.isArray(date) ? date : [date])
                }
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Filter By</div>
              <Select
                options={datesFilterOptions}
                value={dateFilter}
                placeholder="Creation Date"
                disabled={false}
                onChange={(params: any) => setDateFilter(params.value)}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Stores</div>
              <SelectWithCheckboxes
                options={storeOptions}
                disabled={validForAllStores}
                store={store}
                handleChange={(params: any) => setStore(params)}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem
            className={css({
              width: 'fit-content !important',
              marginLeft: '16px',
            })}
          >
            <div className="input-wrapper">
              <div className="input-label" style={{ marginBottom: '12px' }}>
                Valid for all stores
              </div>
              <Checkbox
                checked={validForAllStores}
                checkmarkType={STYLE_TYPE.toggle_round}
                overrides={{
                  Toggle: {
                    style: ({ $theme, $checked }) => ({
                      backgroundColor: $checked ? '#09B08C' : '#000',
                    }),
                  },
                }}
                onChange={(e) => {
                  setStore(stores);
                  setValidForAllStores(e.target.checked);
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="button-wrapper">
              <button type="button" className={buttonStyle} onClick={onSubmit}>
                Apply
              </button>
            </div>
          </FlexGridItem>
        </FlexGrid>
      </div>
    </div>
  );
};

export default FilterList;
