type exportFileOption = {
  label: string;
  id: number;
};
export const exportFileOptions: exportFileOption[] = [
  {
    label: 'Download PDF',
    id: 1,
  },
  {
    label: 'Dwonload Excel',
    id: 2,
  },
];
