import React, { useEffect, useRef } from 'react';
import { useStyletron } from 'styletron-react';
import * as xlsx from 'xlsx';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import CustomSearch from 'modules/shared/components/search';
import CustomIconButton from 'modules/shared/components/icon-button';
import ordersIconURL from 'assets/icons/orders.svg';
import exportIconURL from 'assets/icons/export.svg';
import { Axios } from 'core/services/http';
import Pagin from './components/pagination';
import { useReactToPrint } from 'react-to-print';
import FilterList from './components/search';
import { AppContext } from 'context';
import { UserRole } from 'modules/shared/enums/user-role.enum';
import { useNavigate } from 'react-router-dom';
import print from 'assets/icons/print.png';
import ReportList from 'modules/shared/components/report-list';
import numberWithCommas from 'modules/shared/number-with-commas';
import { toaster } from 'baseui/toast';
import { SIZE, Spinner } from 'baseui/spinner';
import config from 'config';
import colors from 'constants/colors';
import FilterInvoice from './components/filters';
import moment from 'moment';

const BREADCRUMB_ITEMS = [
  { name: 'Reports', route: '/reports' },
  { name: 'Invoice Posted Day Wise', route: '/reports/invoiceTodayListReport' },
];
const REPORT_LIST_HEADERS = [
  'Document No',
  'VAT Invoice No',
  'Store',
  'Creation Date',
  'Invoice Date',
  'Supplier',
  'Net Amount',
];

const InvoiceTodayListReport = () => {
  const [ordersList, setOrdersList] = React.useState<Array<any>>([]);
  const [stores, setStores] = React.useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totolCount, setTotalCount] = React.useState(0);
  const [numPages, setNumPages] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<any>([{ label: 20, id: '1' }]);
  const [startDate, setStartDate] = React.useState<any>([new Date()]);
  const [endDate, setEndDate] = React.useState<any>([new Date()]);
  const [dateOption, setDateOption] = React.useState<any>([]);
  const [dateFilter, setDateFilter] = React.useState<any>([]);
  const [store, setStore] = React.useState<any>([]);
  const [orderStateKey, setOrderStateKey] = React.useState('0');
  const [typeFilter, setTypeFilter] = React.useState<any>([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [allOrdersList, setAllOrdersList] = React.useState<Array<any>>([]);
  const [isClickApply, setIsClickApply] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [css] = useStyletron();
  const axios = new Axios().getInstance();
  const navigate = useNavigate();
  const componentRef: any = useRef();

  const {
    state: {
      user: { role },
    },
  } = React.useContext(AppContext);
  const isUserByRole = role === UserRole.User;

  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const actionsWrapper = css({
    padding: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '-10px',
    gap: '10px',
  });
  const searchWrapper = css({ width: '400px' });
  const customButtonWrapper = css({
    marginRight: '8px',
    marginBottom: '8px',
  });
  const listWrapper = css({
    padding: '12px',
    '@media (max-width: 540px)': {
      overflow: 'scroll',
    },
  });

  const prinListWrapper = css({
    padding: '12px',
    '@media (max-width: 540px)': {
      overflow: 'scroll',
    },
  });

  const wrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(13, 38, 107, 0.1)',
    borderRadius: '10px',
    backgroundColor: colors.white,
    padding: '6px 12px',
    cursor: 'pointer',
    height: 'fit-content',
    '@media (max-width: 600px)': {
      padding: '4px 10px',
    },
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        margin-bottom: 20px; /* Add 20px margin to all sides of each printed page */
        counter-increment: page
      }

      @media print {
        .page-break {
          page-break-before: always; /* Add a page break before each element with the "page-break" class */
        }
      }
    `,
  });

  const params: any = {
    period: JSON.stringify({
      label: dateOption.length > 0 ? dateOption[0]?.id : 'custom',
      startDate: startDate[0]
        ? moment(moment(startDate[0]).format('YYYY-MM-DD').toString())
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss[Z]')
        : moment().utc().clone().startOf('day').format(),
      endDate: startDate[0]
        ? moment(moment(startDate[0]).format('YYYY-MM-DD').toString())
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss[Z]')
        : moment().utc().clone().endOf('day').format(),
    }),
    filterBy: dateFilter.length > 0 ? dateFilter[0]?.id : 'createdAt',
    store: store.length > 0 ? store[0]?.id : '',
    type: typeFilter.length > 0 ? typeFilter[0]?.id : 'All',
    search: searchValue,
  };

  const getAllListData = async () => {
    try {
      setIsLoading(true);
      // const response = await getInoiceData(params);
      const response: any = await fetch(
        `${config.API_URL}invoice/pdf-print?` + new URLSearchParams(params),
        {
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('auth-token')}`,
          },
        }
      );

      if (response.status === 200) {
        setAllOrdersList(response?.data?.data?.items);
        setIsLoading(false);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        window.open(url);
      }
      setIsClickApply(true);
    } catch (error: any) {
      setIsLoading(false);
      setIsClickApply(false);
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  useEffect(() => {
    setIsClickApply(false);
  }, [
    dateOption,
    startDate,
    endDate,
    dateFilter,
    store,
    typeFilter,
    searchValue,
  ]);

  const getInvoiceLists = async () => {
    try {
      const paramerters = {
        ...params,
        pageSize: pageSize[0]?.label,
        pageNumber: currentPage,
      };
      const response = await getInoiceData(paramerters);
      if (response?.data?.success) {
        setOrdersList(response?.data?.data?.items);
        setNumPages(response?.data?.data?.meta?.totalPages);
        setTotalCount(response?.data?.data?.meta?.total);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getInoiceData = async (params: any) => {
    try {
      return await axios.get('/invoice/list', {
        params: params,
      });
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const onSubmit = () => {
    getInvoiceLists();
  };

  const getExportData = async () => {
    const response = await getInoiceData(params);
    if (response?.data?.success) {
      setAllOrdersList(response?.data?.data?.items);
    }
  };

  async function exportToCSV() {
    let dataToExport: Array<any> = [];
    try {
      await getExportData();
      allOrdersList?.forEach(
        ({
          vendorInvoiceNumber,
          vatInvoiceNumber,
          supplierName,
          createdAt,
          date,
          storeName,
          amount,
        }) => {
          const obj: any = {
            'Document No': vendorInvoiceNumber,
            'VAT Invoice No': vatInvoiceNumber,
            Store: storeName,
            'Creation Date': createdAt,
            'Invoice Date': date,
            Supplier: supplierName,
            'Net Amount': amount,
          };
          dataToExport.push(obj);
        }
      );
      const worksheet = xlsx.utils.json_to_sheet(dataToExport);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Invoice Lists');
      xlsx.writeFile(workbook, 'invoice_list.xlsx');
    } catch (err: any) {
      toaster.negative(err.message, {
        autoHideDuration: 4000,
      });
    }
  }

  const getStores = async () => {
    try {
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        const stores = response.data.data.map((store: any) => ({
          id: store.id,
          label: store.name,
        }));
        setStores(stores);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const onSearch = (e: any) => {
    e.preventDefault();
    setCurrentPage(1);
    getInvoiceLists();
  };

  useEffect(() => {
    Promise.all([getStores(), getInvoiceLists()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStateKey, currentPage, pageSize]);

  useEffect(() => {
    if (searchValue === '') {
      getInvoiceLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const listToRender = () => {
    return ordersList.map((item) => {
      return [
        item.vendorInvoiceNumber,
        item.vatInvoiceNumber,
        item.storeName,
        item.createdAt ? item.createdAt : '-',
        item?.date ? item?.date : '-',
        item.supplierName,
        item.amount ? `${numberWithCommas(item.amount)}` : '-',
      ];
    });
  };

  const allListToRender = () => {
    return allOrdersList.map((item) => {
      return [
        item.vendorInvoiceNumber,
        item.vatInvoiceNumber,
        item.supplierName,
        item.createdAt ? item.createdAt : '-',
        item?.date ? item?.date : '-',
        item.storeName,
        item.amount ? `${numberWithCommas(item.amount)}` : '-',
      ];
    });
  };

  return (
    <div className={css({ marginTop: '70px' })}>
      <div className={breadcrumbWrapper}>
        <img className={iconStyles} src={ordersIconURL} alt="Order" />
        <Breadcrumb items={BREADCRUMB_ITEMS} />
      </div>
      <div className={listWrapper}>
        <FilterList
          stores={stores}
          store={store}
          startDate={startDate}
          dateOption={dateOption}
          setStore={setStore}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSubmit={onSubmit}
          setIsFilterOpen={setIsFilterOpen}
        />
      </div>
      <div className={actionsWrapper}>
        <div className={searchWrapper}>
          <form onSubmit={onSearch}>
            <CustomSearch
              placeholder="Table search..."
              buttonName="Search"
              searchClicked={() => {
                setCurrentPage(1);
                getInvoiceLists();
              }}
              inputChange={(e) => setSearchValue(e.target.value)}
              inputValue={searchValue}
              setSearchValue={() => {}}
            />
          </form>
        </div>
        <div style={{ display: 'flex' }}>
          {!isUserByRole && (
            <div className={customButtonWrapper}>
              {!isLoading ? (
                <CustomIconButton
                  icon={print}
                  name="Print"
                  onClick={getAllListData}
                  isDisable={false}
                />
              ) : (
                <div className={wrapperStyles}>
                  <span> Printing...</span>
                  <Spinner $size={SIZE.small} />
                </div>
              )}
            </div>
          )}
          {!isUserByRole && (
            <div className={customButtonWrapper}>
              <CustomIconButton
                icon={exportIconURL}
                name="Export"
                onClick={exportToCSV}
              />
            </div>
          )}
        </div>
      </div>
      <div className={listWrapper} style={{ marginTop: '-5px' }}>
        <ReportList
          reportHeader={REPORT_LIST_HEADERS}
          reportData={listToRender()}
        />
      </div>
      <FilterInvoice
        isOpen={isFilterOpen}
        setIsOpen={setIsFilterOpen}
        stores={stores}
        store={store}
        startDate={startDate}
        dateOption={dateOption}
        setStore={setStore}
        setStartDate={setStartDate}
        onSubmit={onSubmit}
      />
      {/* <div style={{ display: 'none' }}>
        <div className="page" ref={componentRef}>
          <PrintInvoiceTemplate
            reportHeader={REPORT_LIST_HEADERS}
            reportData={allListToRender()}
            store={store}
            startDate={startDate}
            endDate={endDate}
            dateOption={dateOption}
            dateFilter={dateFilter}
          />
        </div>
      </div> */}
      <div className={listWrapper} style={{ display: 'flex', float: 'right' }}>
        <Pagin
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numPages={numPages}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default InvoiceTodayListReport;
