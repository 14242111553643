import { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { KIND as ButtonKind, SHAPE as ButtonShape } from 'baseui/button';
import { useStyletron } from 'styletron-react';
import * as xlsx from 'xlsx';
import { toaster } from 'baseui/toast';

import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { DatePicker } from 'baseui/datepicker';
import React from 'react';
import colors from 'constants/colors';
import { Select } from 'baseui/select';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';
import { useSelectedCompanies } from 'context/companies';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: any) => void;
  stores: Array<any>;
  store: any;
  setStore: any;
  startDate: Array<any>;
  dateOption: any;
  setStartDate: any;
  onSubmit: any;
};

const FilterInvoice = ({
  isOpen,
  setIsOpen,
  stores,
  store,
  setStore,
  startDate,
  dateOption,
  setStartDate,
  onSubmit,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [validForAllStores, setValidForAllStores] = React.useState(false);
  const [css] = useStyletron();
  const { storesOptionsByCompanies } = useSelectedCompanies();

  const storeOptions = storesOptionsByCompanies.map(({ id, label }: any) => ({
    label,
    id,
  }));
  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });

  const exportData = (exportPriceList: Array<any>) => {
    let dataToExport: Array<any> = [];
    try {
      exportPriceList.forEach(
        ({
          supplier: { name },
          product: { code },
          packagingUnit: { name: pName },
          priceWoVAT,
          agreementStartDate,
          agreementEndDate,
          state,
        }) => {
          const obj: any = {
            'Supplier Name': name,
            'Supplier Product Code': code,
            'Packaging Unit Name': pName,
            'Price without VAT': priceWoVAT,
            'Start Date': agreementStartDate,
            'End Date': agreementEndDate,
          };
          if (state) {
            obj['Status'] = state;
          }
          dataToExport.push(obj);
        }
      );
      const worksheet = xlsx.utils.json_to_sheet(dataToExport);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Price Lists');
      xlsx.writeFile(workbook, 'price_list.xlsx');
    } catch (err: any) {
      toaster.negative(err.message, {
        autoHideDuration: 4000,
      });
    }
  };

  return (
    <>
      <Modal
        onClose={() => setIsOpen(false)}
        closeable={false}
        isOpen={isOpen}
        animate
        autoFocus={false}
        size={SIZE.auto}
        role={ROLE.dialog}
      >
        <ModalHeader>
          Invoice Filters
          <ModalBody>
            <div className="create-order-list">
              <FlexGrid
                flexGridColumnCount={[1, 2, 3]}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
              >
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Date</div>
                    <DatePicker
                      disabled={false}
                      required={false}
                      value={startDate}
                      onChange={({ date }) => {
                        setStartDate(Array.isArray(date) ? date : [date]);
                      }}
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 9999 },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Stores</div>
                    <SelectWithCheckboxes
                      options={storeOptions}
                      disabled={validForAllStores}
                      store={store}
                      handleChange={(params: any) => setStore(params)}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="button-wrapper">
                    <button
                      type="button"
                      className={buttonStyle}
                      onClick={() => {
                        onSubmit();
                        setIsOpen(false);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </FlexGridItem>
              </FlexGrid>
            </div>
          </ModalBody>
        </ModalHeader>
        <ModalFooter>
          <ModalButton
            type="button"
            shape={ButtonShape.pill}
            kind={ButtonKind.secondary}
            onClick={() => setIsOpen(false)}
            disabled={isLoading}
          >
            Cancel
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FilterInvoice;
