import { AppContext } from 'context';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getSocketClient } from 'socket';
import { Socket } from 'socket.io-client';

interface WebSocketContextType {
  socket: Socket | null;
}

export const WebSocketContext = createContext<WebSocketContextType>({
  socket: null,
});

// Explicitly define props type
interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { state } = useContext(AppContext);

  useEffect(() => {
    if (state.token && !socket) {
      // Establish the WebSocket connection when there is a token
      const socketClient = getSocketClient();
      socketClient?.connect();
      setSocket(socketClient);
    } else if (!state.token && socket) {
      // Disconnect the WebSocket if the user logs out
      socket.disconnect();
      setSocket(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};
