import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStyletron } from 'styletron-react';

import colors from 'constants/colors';
import arrowRightIconURL from 'assets/icons/arrow-right.svg';
import breadcrumbsAngleIcon from 'assets/icons/breadcrumbs-angle-right.svg';
import { urls } from 'constants/urls';

interface IProps {
  items: Array<{ name: string; route: string }>;
}
const Breadcrumb = ({ items }: IProps) => {
  const navigate = useNavigate();
  const [css] = useStyletron();
  const location = useLocation();

  const navigateToRoute = (route: string, index: number) => {
    if (items.length - 1 === index) {
      return;
    }
    navigate(route);
  };

  const itemsWrapper = css({
    display: 'flex',
  });
  const itemWrapper = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
  });
  const buttonStyles = css({
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'poppins-medium',
  });
  const iconStyles = css({
    width: '16px',
    height: '12px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const BreadCrumbButtonStyles = css({
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '400',
  });
  const breadcrumbItem = css({
    padding: '0px !important',
  });

  return (
    <>
      {urls?.includes(location?.pathname) ? (
        <div className={itemsWrapper}>
          {items.map(({ name, route }, index) => (
            <div key={index} className={`${itemWrapper} ${breadcrumbItem}`}>
              <button
                className={BreadCrumbButtonStyles}
                style={{
                  color:
                    items.length - 1 !== index ? 'var(--black)' : 'var(--blue)',
                  opacity: items.length - 1 !== index ? '0.5' : '1',
                }}
                type="button"
                onClick={() => navigateToRoute(route, index)}
              >
                {name}
              </button>
              {items.length - 1 !== index ? (
                <img
                  className={iconStyles}
                  src={breadcrumbsAngleIcon}
                  alt={name}
                />
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <div className={itemsWrapper}>
          {items.map(({ name, route }, index) => (
            <div key={index} className={itemWrapper}>
              <button
                className={buttonStyles}
                style={{
                  color:
                    items.length - 1 !== index ? colors.darkBlue : colors.black,
                }}
                type="button"
                onClick={() => navigateToRoute(route, index)}
              >
                {name}
              </button>
              {items.length - 1 !== index ? (
                <img
                  className={iconStyles}
                  src={arrowRightIconURL}
                  alt={name}
                />
              ) : null}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Breadcrumb;
