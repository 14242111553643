import React, { useEffect } from 'react';
import priceListIconURL from 'assets/icons/price-list.svg';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import { useStyletron } from 'styletron-react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';
import { DatePicker } from 'baseui/datepicker';
import { Select } from 'baseui/select';
import { Input } from 'baseui/input';
import { useNavigate, useParams } from 'react-router-dom';
import { Axios } from 'core/services/http';
import { toaster } from 'baseui/toast';
import { Spinner } from 'baseui/icon';
import CustomIconButton from 'modules/shared/components/icon-button';
import editIconUrl from 'assets/icons/edit.svg';
import submitIconURL from 'assets/icons/pr-submitted.svg';
import numberWithCommas from 'modules/shared/number-with-commas';
import ExclusionModal from '../edit/ExclusionModal';
import moment from 'moment';

const BREADCRUMB_ITEMS = [
  { name: 'Price Contracts', route: '/price-contracts' },
  { name: 'View Amended Price Contract', route: '' },
];

interface ContractItem {
  _id: string;
  vendorProductId: {
    id: string;
    label: string;
  };
  priceWoVAT: number;
  isActive: boolean;
  defaultVendor: boolean;
  excludedBrandList: Array<any>;
  excludedStoreList: Array<any>;
  specialEmailAnyStore: boolean;
  specialEmailSpecificStoreList: Array<any>;
  hideInRequisition: boolean;
}

const ViewAmendedPriceContract = () => {
  const { id } = useParams();

  const [css] = useStyletron();
  const navigate = useNavigate();

  const axios = new Axios().getInstance();

  const [priceContract, setPriceContract] = React.useState<any>();
  const [basePriceContract, setBasePriceContract] = React.useState<any>();
  const [activePriceContract, setActivePriceContract] = React.useState<any>();
  const [isBaseContracShown, setIsBaseContracShown] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  //backend data
  const [vendors, setVendors] = React.useState<Array<any>>([]);
  const [brands, setBrands] = React.useState<Array<any>>([]);
  const [stores, setStores] = React.useState<Array<any>>([]);

  //frontend data for create price contract
  const [supplier, setSupplier] = React.useState<any>([]);
  const [isOpenContract, setIsOpenContract] = React.useState(true);
  const [startDate, setStartDate] = React.useState<any>([]);
  const [endDate, setEndDate] = React.useState<any>([]);
  const [validForAllBrands, setValidForAllBrands] = React.useState(true);
  const [includedBrandList, setIncludedBrandList] = React.useState([]);
  const [excludedBrandList, setExcludedBrandList] = React.useState([]);
  const [validForAllStores, setValidForAllStores] = React.useState(true);
  const [includedStoreList, setIncludedStoreList] = React.useState([]);
  const [excludedStoreList, setExcludedStoreList] = React.useState([]);
  const [contractItems, setContractItems] = React.useState<Array<ContractItem>>(
    []
  );

  const [baseSupplier, setBaseSupplier] = React.useState<any>([]);
  const [baseIsOpenContract, setBaseIsOpenContract] = React.useState(true);
  const [baseStartDate, setBaseStartDate] = React.useState<any>([]);
  const [baseEndDate, setBaseEndDate] = React.useState<any>([]);
  const [baseValidForAllBrands, setBaseValidForAllBrands] =
    React.useState(true);
  const [baseIncludedBrandList, setBaseIncludedBrandList] = React.useState([]);
  const [baseExcludedBrandList, setBaseExcludedBrandList] = React.useState([]);
  const [baseValidForAllStores, setBaseValidForAllStores] =
    React.useState(true);
  const [baseIncludedStoreList, setBaseIncludedStoreList] = React.useState([]);
  const [baseExcludedStoreList, setBaseExcludedStoreList] = React.useState([]);
  const [baseContractItems, setBaseContractItems] = React.useState<
    Array<ContractItem>
  >([]);

  const [exclusionsModalOpen, setExclusionsModalOpen] = React.useState(false);
  const [selectedContractItem, setSelectedContractItem] = React.useState<any>();

  const getVendors = async () => {
    try {
      const response = await axios.get('/vendors?all=true');
      if (response?.data?.success && response?.data?.data?.products) {
        setVendors(
          response?.data?.data?.products?.filter(
            (data: any) => data.deleted === false
          )
        );
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getStores = async () => {
    try {
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        setStores(response.data.data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const BrandStatusEnum = {
    Active: 'active',
    Archived: 'archived',
  };

  const getBrands = async () => {
    try {
      const response = await axios.get('/brands?status=active', {
        params: {
          status: BrandStatusEnum.Active,
        },
      });
      if (response?.data?.success) {
        setBrands(response.data.data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const buttonWrapper = css({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  });
  const flexGridItemClass = css({
    width: 'fit-content !important',
    '@media (max-width: 540px)': {
      width: '100%',
      marginRight: 'auto',
    },
  });
  const flexGridSmallItemClass = css({
    '@media (min-width: 541px)': {
      width: 'calc(((100% - 120px) / 12) - 0.5px) !important',
      marginRight: '12px !important',
    },
  });

  const newRequistionWrapper = css({
    display: 'grid',
    gridTemplateColumns: '10% 20% 15% 8% 5% 15% 10% 10% 3%',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 8px',
    minWidth: '900px',
  });
  const newRequisitionHeader = css({
    display: 'grid',
    gridTemplateColumns: '10% 20% 15% 8% 5% 15% 10% 10% 3%',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 6px',

    backgroundColor: '#063360',
    color: 'white',
    fontWeight: 700,
    fontSize: '16px',
  });
  const topLabelWrapper = css({
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  });
  const topLabel = css({
    fontSize: '18px',
    fontWeight: '500',
  });
  const topLabelValue = css({
    fontSize: '17px',
    fontFamily: 'poppins-regular',
  });

  const getVendorOptions = () => {
    return vendors.map((vendor: any) => ({
      id: vendor.id,
      label: vendor.name,
    }));
  };

  const getStoreOptions = () => {
    const storeOptions = stores.map((store: any) => ({
      id: store.id,
      label: store.name,
      brand: store.brand,
    }));

    const filteredStoreOptions = storeOptions.filter((storeOption) => {
      //if valid for all brands is true
      if (validForAllBrands) {
        //we check if the exluded brand list is empty then we return all stores
        if (excludedBrandList.length === 0) {
          return true;
        }
        //we return the stores that are not included in the excluded brand list
        const isExcluded = excludedBrandList.find(
          ({ id }: any) =>
            id === storeOption.brand?._id || id === storeOption.brand
        );
        return !isExcluded;
      } else {
        //we return the stores that are included in the included brand list
        const isIncluded = includedBrandList.find(
          ({ id }: any) =>
            id === storeOption.brand?._id || id === storeOption.brand
        );
        return isIncluded;
      }
    });

    return filteredStoreOptions;
  };

  const getQualifyingStoreOptions = () => {
    //if valid for all stores is true then we need to return all stores except excluded stores, if valid for all stores is false then we need to return included stores
    const options = getStoreOptions();
    if (validForAllStores) {
      return options.filter(
        (store: any) =>
          !excludedStoreList.find(({ id }: any) => id === store.id)
      );
    }
    return includedStoreList;
  };

  const getBrandOptions = () => {
    return brands.map((brand: any) => ({
      id: brand.id,
      label: brand.name,
    }));
  };

  const isAllInputsValid = () => {
    if (!supplier.length) {
      toaster.negative('Supplier is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!startDate.length) {
      toaster.negative('Start Date is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!isOpenContract && !endDate.length) {
      toaster.negative('End Date is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (endDate.length && startDate[0] > endDate[0]) {
      toaster.negative('End Date should be greater than Start Date', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!validForAllBrands && !includedBrandList.length) {
      toaster.negative('Included Brands is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!validForAllStores && !includedStoreList.length) {
      toaster.negative('Included Stores is required', {
        autoHideDuration: 4000,
      });
      return false;
    }

    return true;
  };

  const getPriceContract = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/priceContracts/${id}`);
      const contractData = response.data.data;
      setPriceContract(contractData);
      setActivePriceContract(contractData);
      setSupplier([
        {
          id: contractData?.vendorId?._id,
          label: contractData?.vendorId?.name,
        },
      ]);
      setIsOpenContract(contractData?.isOpenContract);
      setStartDate([new Date(contractData?.contractStartDate)]);
      setEndDate(
        contractData?.contractEndDate
          ? [new Date(contractData.contractEndDate)]
          : []
      );
      setValidForAllBrands(contractData.validForAllBrands);
      setIncludedBrandList(
        contractData?.includedBrandList.map((brand: any) => ({
          id: brand?._id,
          label: brand?.name,
        }))
      );
      setExcludedBrandList(
        contractData?.excludedBrandList.map((brand: any) => ({
          id: brand?._id,
          label: brand?.name,
        }))
      );
      setValidForAllStores(contractData?.validForAllStores);
      setIncludedStoreList(
        contractData?.includedStoreList.map((store: any) => ({
          id: store?._id,
          label: store?.name,
          brand: store?.brand?._id,
        }))
      );
      setExcludedStoreList(
        contractData?.excludedStoreList.map((store: any) => ({
          id: store?._id,
          label: store?.name,
          brand: store?.brand?._id,
        }))
      );
      const updatedContractItems = contractData?.contractItems.map(
        (item: any) => {
          return {
            _id: item?._id,
            vendorProductId: {
              id: item?.vendorProductId?._id,
              label: `${item?.vendorProductId?.supplierProductCode}-${item?.vendorProductId?.supplierProductName}-${item?.vendorProductId?.productPackagingUnit?.name}`,
              supplierProductCode: item?.vendorProductId?.supplierProductCode,
              supplierProductName: item?.vendorProductId?.supplierProductName,
              productPackagingUnit:
                item?.vendorProductId?.productPackagingUnit?.name,
            },
            priceWoVAT: item?.priceWoVAT,
            isActive: item?.isActive,
            excludedStoreList: item?.excludedStoreList.map((store: any) => ({
              id: store?._id,
              label: store?.name,
              brand: store?.brand?._id,
            })),
            specialEmailAnyStore: item?.specialEmailAnyStore,
            //if special email any store is true then we need to set special email specific store list to all stores
            specialEmailSpecificStoreList: item?.specialEmailAnyStore
              ? getStoreOptions()
              : item?.specialEmailSpecificStoreList.map((store: any) => ({
                  id: store?._id,
                  label: store?.name,
                  brand: store?.brand?._id,
                })),
            hideInRequisition: item?.hideInRequisition,
          };
        }
      );
      setContractItems(updatedContractItems);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getBasePriceContract = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/priceContracts/${priceContract?.baseContractId}`
      );
      const contractData = response.data.data;
      setBasePriceContract(contractData);
      setBaseSupplier([
        {
          id: contractData?.vendorId?._id,
          label: contractData?.vendorId?.name,
        },
      ]);
      setBaseIsOpenContract(contractData?.isOpenContract);
      setBaseStartDate([new Date(contractData?.contractStartDate)]);
      setBaseEndDate(
        contractData?.contractEndDate
          ? [new Date(contractData.contractEndDate)]
          : []
      );
      setBaseValidForAllBrands(contractData.validForAllBrands);
      setBaseIncludedBrandList(
        contractData?.includedBrandList.map((brand: any) => ({
          id: brand?._id,
          label: brand?.name,
        }))
      );
      setBaseExcludedBrandList(
        contractData?.excludedBrandList.map((brand: any) => ({
          id: brand?._id,
          label: brand?.name,
        }))
      );
      setBaseValidForAllStores(contractData?.validForAllStores);
      setBaseIncludedStoreList(
        contractData?.includedStoreList.map((store: any) => ({
          id: store?._id,
          label: store?.name,
          brand: store?.brand?._id,
        }))
      );
      setBaseExcludedStoreList(
        contractData?.excludedStoreList.map((store: any) => ({
          id: store?._id,
          label: store?.name,
          brand: store?.brand?._id,
        }))
      );
      const updatedContractItems = contractData?.contractItems.map(
        (item: any) => {
          return {
            _id: item?._id,
            vendorProductId: {
              id: item?.vendorProductId?._id,
              label: `${item?.vendorProductId?.supplierProductCode}-${item?.vendorProductId?.supplierProductName}-${item?.vendorProductId?.productPackagingUnit?.name}`,
              supplierProductCode: item?.vendorProductId?.supplierProductCode,
              supplierProductName: item?.vendorProductId?.supplierProductName,
              productPackagingUnit:
                item?.vendorProductId?.productPackagingUnit?.name,
            },
            priceWoVAT: item?.priceWoVAT,
            isActive: item?.isActive,
            excludedStoreList: item?.excludedStoreList.map((store: any) => ({
              id: store?._id,
              label: store?.name,
              brand: store?.brand?._id,
            })),
            specialEmailAnyStore: item?.specialEmailAnyStore,
            //if special email any store is true then we need to set special email specific store list to all stores
            specialEmailSpecificStoreList: item?.specialEmailAnyStore
              ? getStoreOptions()
              : item?.specialEmailSpecificStoreList.map((store: any) => ({
                  id: store?._id,
                  label: store?.name,
                  brand: store?.brand?._id,
                })),
            hideInRequisition: item?.hideInRequisition,
          };
        }
      );
      setBaseContractItems(updatedContractItems);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVendors();
    getStores();
    getBrands();
    if (id) {
      getPriceContract();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (priceContract) {
      getBasePriceContract();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceContract]);

  useEffect(() => {
    if (isBaseContracShown) {
      setActivePriceContract(basePriceContract);
      return;
    }
    setActivePriceContract(priceContract);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBaseContracShown]);

  const validateContractItem = (item: any) => {
    if (!item.vendorProductId?.label || !item.priceWoVAT) {
      toaster.negative('Product and Price w/o VAT is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    return true;
  };

  const submitPriceContractHandler = async () => {
    if (!isAllInputsValid()) {
      return;
    }
    //we need to validate each contract item
    if (contractItems.length) {
      const isValid = contractItems.every((item) => validateContractItem(item));
      if (!isValid) {
        return;
      }
    }
    try {
      const response = await axios.patch(`/priceContracts/submit/${id}`);
      if (response.data.success) {
        toaster.positive('Price Contract Submitted Successfully', {
          autoHideDuration: 4000,
        });
        navigate('/price-contracts');
      }
    } catch (error) {
      console.error(error);
      toaster.negative('Failed to submit price contract', {
        autoHideDuration: 4000,
      });
    }
  };

  const activePriceContractHandler = async () => {
    if (!isAllInputsValid()) {
      return;
    }
    //we need to validate each contract item
    if (contractItems.length) {
      const isValid = contractItems.every((item) => validateContractItem(item));
      if (!isValid) {
        return;
      }
    }
    try {
      const response = await axios.patch(`/priceContracts/active/${id}`);
      if (response.data.success) {
        toaster.positive('Price Contract Activated Successfully', {
          autoHideDuration: 4000,
        });
        navigate('/price-contracts');
      }
    } catch (error) {
      console.error(error);
      toaster.negative('Failed to activate price contract', {
        autoHideDuration: 4000,
      });
    }
  };

  const comparePriceContractItems = (item1: any, item2: any) => {
    //check priceWoVAT, isActive, hideInRequisition, specialEmailAnyStore, specialEmailSpecificStoreList and excludedStoreList
    // specialEmailSpecificStoreList is an array of strings and excludedStoreList is an array of strings
    if (
      item1.priceWoVAT !== item2.priceWoVAT ||
      item1.isActive !== item2.isActive ||
      item1.hideInRequisition !== item2.hideInRequisition ||
      item1.specialEmailAnyStore !== item2.specialEmailAnyStore
    ) {
      return true;
    }

    if (item1.excludedStoreList.length !== item2.excludedStoreList.length) {
      return true;
    }

    const excludedStoreList1 = item1.excludedStoreList.map(
      (store: any) => store.id
    );
    const excludedStoreList2 = item2.excludedStoreList.map(
      (store: any) => store.id
    );

    if (excludedStoreList1.length !== excludedStoreList2.length) {
      return true;
    }

    for (let i = 0; i < excludedStoreList1.length; i++) {
      if (excludedStoreList1[i] !== excludedStoreList2[i]) {
        return true;
      }
    }

    if (
      item1.specialEmailSpecificStoreList.length !==
      item2.specialEmailSpecificStoreList.length
    ) {
      return true;
    }

    const specialEmailSpecificStoreList1 =
      item1.specialEmailSpecificStoreList.map((store: any) => store.id);
    const specialEmailSpecificStoreList2 =
      item2.specialEmailSpecificStoreList.map((store: any) => store.id);

    if (
      specialEmailSpecificStoreList1.length !==
      specialEmailSpecificStoreList2.length
    ) {
      return true;
    }

    for (let i = 0; i < specialEmailSpecificStoreList1.length; i++) {
      if (
        specialEmailSpecificStoreList1[i] !== specialEmailSpecificStoreList2[i]
      ) {
        return true;
      }
    }

    return false;
  };

  const getContractItems = () => {
    //if isBaseContractShown is true then we need to return base contract items
    if (isBaseContracShown) {
      return baseContractItems;
    }

    //else we need to combine both and also compare, if contract item is present in base contract items then we need to add isDeleted flag to true, if that item is not present in base contract items then we need to add isNew flag to true, if that item is present in both and some fields are changed then we need to add isUpdated flag to true
    const updatedContractItems = contractItems.map((item) => {
      const baseItem = baseContractItems.find(
        (baseItem) => baseItem.vendorProductId.id === item.vendorProductId.id
      );
      if (!baseItem) {
        return {
          ...item,
          isNew: true,
        };
      }
      if (baseItem) {
        const isUpdated = comparePriceContractItems(item, baseItem);
        return {
          ...item,
          isUpdated,
        };
      }
      return item;
    });

    const updatedBaseContractItems = baseContractItems.map((item) => {
      const contractItem = contractItems.find(
        (contractItem) =>
          contractItem.vendorProductId.id === item.vendorProductId.id
      );
      if (!contractItem) {
        return {
          ...item,
          isDeleted: true,
        };
      }
      return item;
    });

    const combinedItems = [
      ...updatedContractItems,
      ...updatedBaseContractItems,
    ];

    const uniqueItems = combinedItems.reduce((acc: any, current: any) => {
      const x = acc.find(
        (item: any) => item.vendorProductId.id === current.vendorProductId.id
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    return uniqueItems;
  };

  const getIncludedBrandChanged = () => {
    if (isBaseContracShown) {
      return false;
    }

    if (validForAllBrands !== baseValidForAllBrands) {
      return true;
    }

    if (includedBrandList.length !== baseIncludedBrandList.length) {
      return true;
    }

    for (let i = 0; i < includedBrandList.length; i++) {
      if (includedBrandList[i] !== baseIncludedBrandList[i]) {
        return true;
      }
    }

    return false;
  };

  const getExcludedBrandChanged = () => {
    if (isBaseContracShown) {
      return false;
    }

    if (excludedBrandList.length !== baseExcludedBrandList.length) {
      return true;
    }

    for (let i = 0; i < excludedBrandList.length; i++) {
      if (excludedBrandList[i] !== baseExcludedBrandList[i]) {
        return true;
      }
    }

    return false;
  };

  const getIncludedStoreChanged = () => {
    if (isBaseContracShown) {
      return false;
    }

    if (validForAllStores !== baseValidForAllStores) {
      return true;
    }

    if (includedStoreList.length !== baseIncludedStoreList.length) {
      return true;
    }

    for (let i = 0; i < includedStoreList.length; i++) {
      if (includedStoreList[i] !== baseIncludedStoreList[i]) {
        return true;
      }
    }

    return false;
  };

  const getExcludedStoreChanged = () => {
    if (isBaseContracShown) {
      return false;
    }

    if (excludedStoreList.length !== baseExcludedStoreList.length) {
      return true;
    }

    for (let i = 0; i < excludedStoreList.length; i++) {
      if (excludedStoreList[i] !== baseExcludedStoreList[i]) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {!loading && (
        <div className={css({ marginTop: '70px', padding: '10px' })}>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            })}
          >
            <div className={breadcrumbWrapper}>
              <img
                className={iconStyles}
                src={priceListIconURL}
                alt="Price Contract"
              />
              <Breadcrumb items={BREADCRUMB_ITEMS} />
            </div>
            <div
              className={topLabelWrapper}
              style={{
                marginRight: '20px',
              }}
            >
              <p className={topLabel}>Status:</p>
              <span className={topLabelValue}>
                {activePriceContract?.status}
              </span>
            </div>
          </div>
          <div>
            <div>
              <FlexGrid
                flexGridColumnCount={[1, 1, 6]}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
              >
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Contract Code</div>
                    <Input
                      value={activePriceContract?.contractCode}
                      disabled
                      placeholder="Contract Code"
                      overrides={
                        activePriceContract?.contractCode !==
                        basePriceContract?.contractCode
                          ? {
                              Input: {
                                style: () => ({
                                  backgroundColor: '#a5f3fc',
                                }),
                              },
                            }
                          : {}
                      }
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Supplier Name</div>
                    <Select
                      options={getVendorOptions()}
                      value={isBaseContracShown ? baseSupplier : supplier}
                      placeholder="Select Supplier"
                      onChange={(params: any) => setSupplier(params.value)}
                      disabled
                      overrides={
                        !isBaseContracShown &&
                        supplier[0]?.id !== baseSupplier[0]?.id
                          ? {
                              ControlContainer: {
                                style: () => ({
                                  backgroundColor: '#a5f3fc',
                                }),
                              },
                            }
                          : {}
                      }
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Contract Start Date</div>
                    <DatePicker
                      value={isBaseContracShown ? baseStartDate : startDate}
                      onChange={({ date }) =>
                        setStartDate(Array.isArray(date) ? date : [date])
                      }
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 999 },
                              },
                            },
                          },
                        },
                        MonthYearSelectPopover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 9999 },
                              },
                            },
                          },
                        },
                        Input: {
                          props: {
                            overrides: {
                              InputContainer: {
                                style: () => ({
                                  backgroundColor:
                                    !isBaseContracShown &&
                                    startDate[0]?.getTime() !==
                                      baseStartDate[0]?.getTime()
                                      ? '#a5f3fc'
                                      : 'transparent',
                                }),
                              },
                            },
                          },
                        },
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Contract End Date</div>
                    <DatePicker
                      value={isBaseContracShown ? baseEndDate : endDate}
                      onChange={({ date }) =>
                        setEndDate(Array.isArray(date) ? date : [date])
                      }
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 999 },
                              },
                            },
                          },
                        },
                        MonthYearSelectPopover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 9999 },
                              },
                            },
                          },
                        },
                        Input: {
                          props: {
                            overrides: {
                              InputContainer: {
                                style: () => ({
                                  backgroundColor:
                                    !isBaseContracShown &&
                                    endDate[0]?.getTime() !==
                                      baseEndDate[0]?.getTime()
                                      ? '#a5f3fc'
                                      : 'transparent',
                                }),
                              },
                            },
                          },
                        },
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridSmallItemClass}>
                  <div className="input-wrapper">
                    <div
                      className="input-label"
                      style={{ marginBottom: '12px' }}
                    >
                      Open Contract
                    </div>
                    <Checkbox
                      checked={
                        isBaseContracShown ? baseIsOpenContract : isOpenContract
                      }
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Toggle: {
                          style: ({ $checked }) => ({
                            backgroundColor:
                              !isBaseContracShown &&
                              isOpenContract !== baseIsOpenContract
                                ? '#67e8f9'
                                : $checked
                                ? '#525252'
                                : '#a3a3a3',
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setIsOpenContract(e.target.checked);
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
              </FlexGrid>
              <br />
              <FlexGrid
                flexGridColumnCount={[1, 2, 6]}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
              >
                <FlexGridItem className={flexGridSmallItemClass}>
                  <div className="input-wrapper">
                    <div
                      className="input-label"
                      style={{ marginBottom: '12px' }}
                    >
                      All Brands
                    </div>
                    <Checkbox
                      checked={
                        isBaseContracShown
                          ? baseValidForAllBrands
                          : validForAllBrands
                      }
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Toggle: {
                          style: ({ $checked }) => ({
                            backgroundColor:
                              !isBaseContracShown &&
                              validForAllBrands !== baseValidForAllBrands
                                ? '#67e8f9'
                                : $checked
                                ? '#525252'
                                : '#a3a3a3',
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setIncludedBrandList([]);
                        setExcludedBrandList([]);
                        setValidForAllBrands(e.target.checked);
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Included Brands</div>
                    <SelectWithCheckboxes
                      //@ts-ignore
                      options={getBrandOptions()}
                      disabled={
                        isBaseContracShown
                          ? baseValidForAllBrands
                          : validForAllBrands
                      }
                      store={
                        isBaseContracShown
                          ? baseIncludedBrandList
                          : includedBrandList
                      }
                      name="Include Brands"
                      handleChange={() => {}}
                      isChanged={getIncludedBrandChanged()}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Excluded Brands</div>
                    <SelectWithCheckboxes
                      options={getBrandOptions()}
                      disabled={
                        isBaseContracShown
                          ? !baseValidForAllBrands
                          : !validForAllBrands
                      }
                      store={
                        isBaseContracShown
                          ? baseExcludedBrandList
                          : excludedBrandList
                      }
                      name="Exclude Brands"
                      handleChange={() => {}}
                      isChanged={getExcludedBrandChanged()}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridSmallItemClass}>
                  <div className="input-wrapper">
                    <div
                      className="input-label"
                      style={{ marginBottom: '12px' }}
                    >
                      All Stores
                    </div>
                    <Checkbox
                      checked={
                        isBaseContracShown
                          ? baseValidForAllStores
                          : validForAllStores
                      }
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Toggle: {
                          style: ({ $checked }) => ({
                            backgroundColor:
                              !isBaseContracShown &&
                              validForAllStores !== baseValidForAllStores
                                ? '#67e8f9'
                                : $checked
                                ? '#525252'
                                : '#a3a3a3',
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setIncludedStoreList([]);
                        setExcludedStoreList([]);
                        setValidForAllStores(e.target.checked);
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Included Stores</div>
                    <SelectWithCheckboxes
                      options={getStoreOptions()}
                      disabled={
                        isBaseContracShown
                          ? baseValidForAllStores
                          : validForAllStores
                      }
                      store={
                        isBaseContracShown
                          ? baseIncludedStoreList
                          : includedStoreList
                      }
                      handleChange={() => {}}
                      isChanged={getIncludedStoreChanged()}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Excluded Stores</div>
                    <SelectWithCheckboxes
                      options={getStoreOptions()}
                      disabled={
                        isBaseContracShown
                          ? !baseValidForAllStores
                          : !validForAllStores
                      }
                      store={
                        isBaseContracShown
                          ? baseExcludedStoreList
                          : excludedStoreList
                      }
                      handleChange={() => {}}
                      isChanged={getExcludedStoreChanged()}
                    />
                  </div>
                </FlexGridItem>
              </FlexGrid>
            </div>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '20px 0',
                borderTop: '1px solid #e5e5e5',
                paddingTop: '20px',
              })}
            >
              <div
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  gap: '30px',
                })}
              >
                <div className={topLabelWrapper}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#bbf7d0',
                      marginRight: '6px',
                    }}
                  ></div>
                  <span className={topLabel}>New</span>
                </div>
                <div className={topLabelWrapper}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#a5f3fc',
                      marginRight: '6px',
                    }}
                  ></div>
                  <span className={topLabel}>Changed</span>
                </div>
                <div className={topLabelWrapper}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#fecaca',
                      marginRight: '6px',
                    }}
                  ></div>
                  <span className={topLabel}>Deleted</span>
                </div>
              </div>
              <div
                className={css({
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'center',
                })}
              >
                <Select
                  options={[
                    {
                      id: priceContract?._id,
                      label: priceContract?.contractCode,
                    },
                    {
                      id: basePriceContract?._id,
                      label: basePriceContract?.contractCode,
                    },
                  ]}
                  value={
                    isBaseContracShown
                      ? [
                          {
                            id: basePriceContract?._id,
                            label: basePriceContract?.contractCode,
                          },
                        ]
                      : [
                          {
                            id: priceContract?._id,
                            label: priceContract?.contractCode,
                          },
                        ]
                  }
                  onChange={(params: any) => {
                    if (params.value[0].id === priceContract?._id) {
                      setIsBaseContracShown(false);
                    } else {
                      setIsBaseContracShown(true);
                    }
                  }}
                  clearable={false}
                />
                {priceContract?.status === 'Draft' && (
                  <div className={buttonWrapper}>
                    <CustomIconButton
                      icon={submitIconURL}
                      name="Submit Price Contract"
                      onClick={submitPriceContractHandler}
                    />
                  </div>
                )}
                {priceContract?.status === 'Submitted' && (
                  <div className={buttonWrapper}>
                    <CustomIconButton
                      icon={editIconUrl}
                      name="Activate"
                      onClick={activePriceContractHandler}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              {getContractItems()?.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                  <div className={`${newRequisitionHeader}`}>
                    <span>Product Code</span>
                    <span>Product Name</span>
                    <span>Product Packaging Unit</span>
                    <span
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      Price w/o VAT
                    </span>
                    <span>Active</span>
                    <span>Special Email Store</span>
                    <span>Hide In Requisition</span>
                    <span>Exclusions</span>
                    <span></span>
                  </div>
                  {getContractItems().map((item: any) => (
                    <div
                      className={newRequistionWrapper}
                      key={item.vendorProductId.id}
                      style={{
                        backgroundColor: item.isNew
                          ? '#bbf7d0'
                          : item.isUpdated
                          ? '#a5f3fc'
                          : item.isDeleted
                          ? '#fecaca'
                          : '',
                      }}
                    >
                      <span>
                        {item.vendorProductId?.supplierProductCode || '-'}
                      </span>
                      <span>
                        {item.vendorProductId?.supplierProductName || '-'}
                      </span>
                      <span>
                        {item.vendorProductId?.productPackagingUnit || '-'}
                      </span>
                      <span
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {numberWithCommas(item.priceWoVAT)}
                      </span>
                      <span>
                        <Checkbox
                          checked={item.isActive}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          overrides={{
                            Toggle: {
                              style: ({ $theme, $checked }) => ({
                                backgroundColor: $checked
                                  ? '#525252'
                                  : '#a3a3a3',
                              }),
                            },
                          }}
                          disabled
                        />
                      </span>
                      <span>
                        <SelectWithCheckboxes
                          options={getQualifyingStoreOptions()}
                          store={item?.specialEmailSpecificStoreList || []}
                          disabled={false}
                          name="Special Email Stores"
                          handleChange={() => {}}
                        />
                      </span>
                      <span>
                        <Checkbox
                          checked={item.hideInRequisition}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          overrides={{
                            Toggle: {
                              style: ({ $theme, $checked }) => ({
                                backgroundColor: $checked
                                  ? '#525252'
                                  : '#a3a3a3',
                              }),
                            },
                          }}
                          disabled
                        />
                      </span>
                      <span
                        className={css({
                          color: '#525252',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        })}
                        onClick={() => {
                          setSelectedContractItem(item);
                          setExclusionsModalOpen(true);
                        }}
                      >
                        {item?.excludedStoreList?.length > 0
                          ? item?.excludedStoreList?.length + ' Exclusions'
                          : 'None'}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
            })}
          >
            <div className={topLabelWrapper}>
              <p className={topLabel}>Created:</p>
              <span className={topLabelValue}>
                {moment(activePriceContract?.createdAt).format('DD/MM/YYYY')} by{' '}
                {activePriceContract?.createdBy?.firstName}{' '}
                {activePriceContract?.createdBy?.lastName}
              </span>
            </div>
            <div className={topLabelWrapper}>
              <p className={topLabel}>Updated:</p>
              <span className={topLabelValue}>
                {moment(activePriceContract?.updatedAt).format('DD/MM/YYYY')} by{' '}
                {activePriceContract?.updatedBy?.firstName}{' '}
                {activePriceContract?.updatedBy?.lastName}
              </span>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div
          className={css({
            marginTop: '70px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          })}
        >
          <Spinner $size={50} />
        </div>
      )}
      {exclusionsModalOpen && (
        <ExclusionModal
          isOpen={exclusionsModalOpen}
          onClose={() => setExclusionsModalOpen(false)}
          storeOptions={getQualifyingStoreOptions()}
          contractItem={selectedContractItem}
          onExclusionChange={() => {}}
        />
      )}
    </>
  );
};

export default ViewAmendedPriceContract;
