import * as React from 'react';
import { Modal, ModalHeader, ModalBody, SIZE, ROLE } from 'baseui/modal';
import { ResponsiveLine } from '@nivo/line';
import { Block } from 'baseui/block';
import { useStyletron } from 'styletron-react';
const DUMMY_GRAPH_DATA = [
  {
    id: 'Cost % on Net',
    color: 'hsla(32, 90%, 48%, 1)',
    data: [],
  },
  {
    id: 'Cost % on Gross',
    color: 'hsla(282, 68%, 38%, 1)',
    data: [],
  },
  {
    id: 'Cost',
    color: 'hsla(174, 63%, 40%, 1)',
    data: [
      { x: 'Jan', y: 10 },
      { x: 'Feb', y: 15 },
      { x: 'Mar', y: 24 },
      { x: 'Apr', y: 34 },
      { x: 'May', y: 43 },
      { x: 'Jun', y: 45 },
      { x: 'Jul', y: 51 },
      { x: 'Aug', y: 54 },
      { x: 'Sep', y: 69 },
      { x: 'Oct', y: 75 },
      { x: 'Nov', y: 79 },
      { x: 'Dec', y: 80 },
    ],
  },
  {
    id: 'Net Sale',
    color: 'hsla(207, 90%, 54%, 1)',
    data: [
      { x: 'Jan', y: 10 },
      { x: 'Feb', y: 27 },
      { x: 'Mar', y: 35 },
      { x: 'Apr', y: 45 },
      { x: 'May', y: 55 },
      { x: 'Jun', y: 65 },
      { x: 'Jul', y: 75 },
      { x: 'Aug', y: 85 },
      { x: 'Sep', y: 95 },
      { x: 'Oct', y: 95 },
      { x: 'Nov', y: 97 },
      { x: 'Dec', y: 90 },
    ],
  },
  {
    id: 'G Sale',
    color: 'hsla(291, 100%, 49%, 1)',
    data: [
      { x: 'Jan', y: 10 },
      { x: 'Feb', y: 20 },
      { x: 'Mar', y: 2 },
      { x: 'Apr', y: 30 },
      { x: 'May', y: 40 },
      { x: 'Jun', y: 30 },
      { x: 'Jul', y: 56 },
      { x: 'Aug', y: 71 },
      { x: 'Sep', y: 70 },
      { x: 'Oct', y: 82 },
      { x: 'Nov', y: 75 },
      { x: 'Dec', y: 60 },
    ],
  },
];

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ViewGraphModal = ({ isOpen, setIsOpen }: Props) => {
  const [css] = useStyletron();
  const modalHeaderTitle = css({
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    color: '#1F203A',
  });

  return (
    <Modal
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.auto}
      role={ROLE.alertdialog}
      overrides={{
        Dialog: {
          style: ({ $theme }) => ({
            border: '1px solid rgba(48, 51, 147, 0.2)',
            borderRadius: '4px',
          }),
        },
        Close: {
          style: () => ({
            display: 'none',
          }),
        },
      }}
    >
      <ModalHeader
        style={
          {
            //   width: '100%',
            //   backgroundColor: 'red',
          }
        }
      >
        <h3 className={modalHeaderTitle}>Cogs Pattern</h3>
      </ModalHeader>
      <ModalBody>
        <h3 className={modalHeaderTitle}>Outlet: TDBDM</h3>

        <div
          style={{
            height: '400px',
            width: '826px',
          }}
        >
          <ResponsiveLine
            data={DUMMY_GRAPH_DATA}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0, // Set the minimum value of the Y axis
              max: 100, // Set the maximum value of the Y axis
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Percentage',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Month',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Amount',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            pointSize={10}
            pointColor={'white'}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="yFormatted"
            pointLabelYOffset={-12}
            lineWidth={2}
            enableGridX={false}
            enableGridY={false}
            useMesh={true}
            colors={{ datum: 'color' }} // Use the color defined in the data
            legends={[
              {
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: -50,
                translateY: -40,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 135,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
              },
            ]}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ViewGraphModal;
