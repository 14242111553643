import classNames from 'classnames';
import './index.scss';
import colors from 'constants/colors';

interface IProps {
  reportHeader: Array<any>;
  reportData: Array<Array<any>>;
  type?: string;
  type2?: string;
  totalValues?: any;
}

const ReportList = ({
  reportHeader,
  reportData,
  type,
  type2,
  totalValues,
}: IProps) => {
  return (
    <div className="report-list">
      <div className={`report-list-header report-list-header-${type2}`}>
        {reportHeader.map((item, index) => (
          <div
            key={`header_${index}`}
            className={classNames({
              'report-list-header-columnNumber':
                index === 7 || (type === 'order' && index === 5),
              'report-list-header-column': !(index === 7),
            })}
          >
            <div
              className="report-list-header-column__label"
              style={
                index === 2 && type === 'delivery' ? { marginLeft: '70px' } : {}
              }
            >
              {item}
            </div>
          </div>
        ))}
      </div>
      <div className="report-list-rows">
        {reportData.map((item, i) => (
          <div key={`data_${i}`} className="report-list-row">
            {item.map((_item, j) => (
              <div
                key={`data_${i}_${j}`}
                className={classNames({
                  'report-list-row-columnNumber':
                    j === 7 || (type === 'order' && j === 5),
                  'report-list-row-column1': j === 1,
                  'report-list-row-column': !(j === 7 || j === 1),
                })}
                style={j === 1 ? { paddingLeft: '-30px' } : {}}
              >
                <div className="report-list-row-column__label">{_item}</div>
              </div>
            ))}
          </div>
        ))}
        {type2 === 'OR' && reportData.length > 0 && totalValues?.isLastPage ? (
          <div
            className="report-list-row"
            style={{
              backgroundColor: colors.lightGray,
              color: '#ffffff',
              fontWeight: 'bolder',
            }}
          >
            <div className="report-list-row-column">
              <div className="report-list-row-column__label">Totals</div>
            </div>
            <div className="report-list-row-column1">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-columnNumber report-list-row-column">
              <div className="report-list-row-column__label">
                {new Intl.NumberFormat().format(
                  totalValues?.totalValues[0]?.totalValue
                )}
              </div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-columnNumber">
              <div className="report-list-row-column__label">
                {new Intl.NumberFormat().format(
                  totalValues?.totalValues[0]?.totalReceivedAmount
                )}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {type2 === 'IL' && reportData.length > 0 && totalValues?.isLastPage ? (
          <div
            className="report-list-row"
            style={{
              backgroundColor: colors.lightGray,
              color: '#ffffff',
              fontWeight: 'bolder',
            }}
          >
            <div className="report-list-row-column">
              <div className="report-list-row-column__label">Totals</div>
            </div>
            <div className="report-list-row-column1">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
            <div className="report-list-row-columnNumber">
              <div className="report-list-row-column__label">
                {new Intl.NumberFormat().format(
                  totalValues?.totalValues[0]?.totalAmount
                )}
              </div>
            </div>
            <div className="report-list-row-column">
              <div className="report-list-row-column__label"></div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ReportList;
