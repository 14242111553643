import React from 'react';
import { Select } from 'baseui/select';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { DatePicker } from 'baseui/datepicker';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { useStyletron } from 'styletron-react';

import colors from 'constants/colors';
import './index.scss';
import { timeCheck } from 'modules/shared/time-check';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';
import { useSelectedCompanies } from 'context/companies';

interface IProps {
  dates: Array<any>;
  dateFilters: Array<any>;
  stores: Array<any>;
  store: any;
  setStore: any;
  startDate: Array<any>;
  endDate: Array<any>;
  dateOption: any;
  dateFilter: any;
  setStartDate: any;
  setEndDate: any;
  setDateOption: any;
  setDateFilter: any;
  typeFilters: any;
  typeFilter: any;
  onSubmit: any;
}

const FilterList = ({
  stores,
  store,
  setStore,
  dates,
  dateFilters,
  startDate,
  endDate,
  dateOption,
  setStartDate,
  dateFilter,
  setEndDate,
  setDateOption,
  setDateFilter,
  typeFilters,
  typeFilter,
  onSubmit,
}: IProps) => {
  const [css] = useStyletron();
  const { storesOptionsByCompanies } = useSelectedCompanies();
  const [supplier, setSupplier] = React.useState<any>([]);
  const [product, setProduct] = React.useState<any>([]);
  const [priceWOVat, setPriceWOVat] = React.useState<any>('');
  const [isDateDisable, setIsDateDisable] = React.useState<any>(true);
  const [validForAllStores, setValidForAllStores] = React.useState(true);

  const storeOptions = storesOptionsByCompanies.map(({ id, label }: any) => ({
    label,
    id,
  }));
  const datesOptions = dates.map(({ id, label }) => ({ label, id }));
  const datesFilterOptions = dateFilters.map(({ id, label }) => ({
    label,
    id,
  }));
  const typeFilterOptions = typeFilters.map(({ id, label }: any) => ({
    label,
    id,
  }));

  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });

  const resetInputs = () => {
    setStore([]);
    setSupplier([]);
    setProduct([]);
    setStartDate([]);
    setEndDate([]);
  };

  const setDateInInput = (value: any) => {
    const date = timeCheck(value[0]?.id);
    setStartDate([date.startTime]);
    setEndDate([date.endTime]);
  };

  return (
    <div className="create-order-list">
      <div>
        <FlexGrid
          flexGridColumnCount={[1, 2, 7]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Date Period</div>
              <Select
                options={datesOptions}
                value={dateOption}
                placeholder="Today"
                disabled={false}
                onChange={(params: any) => {
                  setDateOption(params.value);
                  setDateInInput(params.value);
                  if (params.value[0]?.id === 'custom') {
                    setIsDateDisable(false);
                  } else setIsDateDisable(true);
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Start Date</div>
              <DatePicker
                disabled={isDateDisable}
                required={true}
                value={startDate}
                onChange={({ date }) => {
                  setStartDate(Array.isArray(date) ? date : [date]);
                }}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">End Date</div>
              <DatePicker
                disabled={isDateDisable}
                required={true}
                value={endDate}
                onChange={({ date }) =>
                  setEndDate(Array.isArray(date) ? date : [date])
                }
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Filter By</div>
              <Select
                options={datesFilterOptions}
                value={dateFilter}
                placeholder="Creation Date"
                disabled={true}
                onChange={(params: any) => setDateFilter(params.value)}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Stores</div>
              <SelectWithCheckboxes
                options={storeOptions}
                disabled={validForAllStores}
                store={store}
                handleChange={(params: any) => setStore(params)}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem
            className={css({
              width: 'fit-content !important',
              marginLeft: '16px',
            })}
          >
            <div className="input-wrapper">
              <div className="input-label" style={{ marginBottom: '12px' }}>
                Valid for all stores
              </div>
              <Checkbox
                checked={validForAllStores}
                checkmarkType={STYLE_TYPE.toggle_round}
                overrides={{
                  Toggle: {
                    style: ({ $theme, $checked }) => ({
                      backgroundColor: $checked ? '#09B08C' : '#000',
                    }),
                  },
                }}
                onChange={(e) => {
                  setStore(stores);
                  setValidForAllStores(e.target.checked);
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="button-wrapper">
              <button type="button" className={buttonStyle} onClick={onSubmit}>
                Apply
              </button>
            </div>
          </FlexGridItem>
        </FlexGrid>
      </div>
    </div>
  );
};

export default FilterList;
