import colors from 'constants/colors';

import './OverlayLoader.scss';
import { useStyletron } from 'styletron-react';
import emailImageURL from 'assets/icons/email.svg';

type Props = {
  cancelProcessing: () => void;
  emailMeFile?: () => void;
  isExportLoading: boolean;
  type?: any;
};
const OverlayLoader = ({
  cancelProcessing,
  emailMeFile,
  isExportLoading,
  type,
}: Props) => {
  const [css] = useStyletron();
  const buttonStyle = css({
    padding: '12px 20px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.lightGray,
    color: colors.darkGray,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: '8px',
    height: '60px',
    position: 'relative',
    top: '25px',
  });
  const emailButton = css({
    backgroundColor: colors.primaryBlue,
    padding: '20px 15px',
    outline: 'none',
    border: 'none',
    fontSize: '14px',
    cursor: 'pointer',
    fontFamily: 'poppins-regular',
    color: colors.lightGray,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  });
  const contactIconStyles = css({
    position: 'relative',
    width: '25px',
    height: '60.5px',
    top: '26px',
    marginRight: '8px',
    backgroundColor: colors.primaryBlue,
    // borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    margin: '0px',
    paddingRight: '10px',
    paddingLeft: '15px',
  });

  return (
    <>
      <div className="overlay-loader">
        <div className="loader"></div>

        <div className="action-buttons">
          <button
            type="button"
            className={buttonStyle}
            onClick={cancelProcessing}
          >
            Cancel Processing
          </button>
          {isExportLoading && type && (
            <div>
              <img
                className={contactIconStyles}
                src={emailImageURL}
                alt="Email"
              />
              <button
                type="button"
                className={emailButton}
                onClick={emailMeFile}
              >
                Email Me The File When Ready
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OverlayLoader;
