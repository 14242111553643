import { lazy, useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { AppContext } from 'context';
import RequireAuth from '../require-auth';
import Reports from 'modules/reports';
import { ORDERS_DATE_FILTER } from 'constants/order';
import LightboxComponent from 'modules/lightbox';
import CreatePriceContract from 'modules/price-contract/components/create';
import EditPriceContract from 'modules/price-contract/components/edit';
import ViewPriceContract from 'modules/price-contract/components/view';
import ViewAmendedPriceContract from 'modules/price-contract/components/view-amended';
import Analytics from 'modules/analytics';
import CogsPattern from 'modules/cogspattern';
import Sale from 'modules/sale';
import FoodCost from 'modules/foodcost';
import EditAmendedPriceContract from 'modules/price-contract/components/edit-amended';
import CogsCategory from 'modules/cogscategory';
import CogsAccount from 'modules/cogsaccount';
import PermissionWrapper from './permissionWrapper';
import { Axios } from 'core/services/http';
const ViewTransferListTemplate = lazy(
  () => import('modules/transfer-list/components/create/ViewTransferList')
);
const TransfersOut = lazy(() => import('modules/transfers-order'));
const ProcessTransfersOut = lazy(
  () => import('modules/transfers-request/components/process')
);
const VewTransferOrder = lazy(
  () => import('modules/transfers-order/components/view')
);
const ReceiveTransferOrder = lazy(
  () => import('modules/transfers-order/components/receive')
);
const Auth = lazy(() => import('modules/auth'));
const Error = lazy(() => import('modules/error'));
const Dashboard = lazy(() => import('modules/dashboard'));
const MyAccount = lazy(() => import('modules/my-account'));
const Requisitions = lazy(() => import('modules/requisitions'));
const ProcessRequisition = lazy(
  () => import('modules/requisitions/components/process/ProcessRequisition')
);
const ProcessRequisitionOld = lazy(
  () =>
    import('modules/requisitions/components/process-old/ProcessRequisitionOld')
);
const CreateRequisition = lazy(
  () => import('modules/requisitions/components/create/CreateRequisition')
);
const ViewOldRequisition = lazy(
  () => import('modules/requisitions/components/view-old/ViewOldRequisition')
);
const ViewRequisition = lazy(
  () => import('modules/requisitions/components/view/ViewRequisition')
);
const EditRequisition = lazy(
  () => import('modules/requisitions/components/edit/EditRequisition')
);
const Orders = lazy(() => import('modules/orders'));
const CreateOrder = lazy(
  () => import('modules/orders/components/create-order-view')
);

const EditOrder = lazy(() => import('modules/orders/components/edit'));
const ViewOrder = lazy(() => import('modules/orders/components/view'));
const ReissueOrder = lazy(
  () => import('modules/orders/components/reissueOrder/index')
);
const AmendOrder = lazy(() => import('modules/orders/components/amend'));
const ReceivingItems = lazy(
  () => import('modules/orders/components/receiving-items')
);

const ReopenOrderView = lazy(
  () => import('modules/orders/components/reopen-order-view')
);

const Invoices = lazy(() => import('modules/invoices'));
const CreateInvoice = lazy(
  () => import('modules/invoices/components/create/CreateInvoice')
);
const EditInvoice = lazy(
  () => import('modules/invoices/components/edit/EditInvoice')
);
const ViewInvoice = lazy(
  () => import('modules/invoices/components/view/ViewInvoice')
);
const AmendInvoice = lazy(
  () => import('modules/invoices/components/amend/AmendInvoice')
);
const Templates = lazy(() => import('modules/templates'));
const CreateTemplate = lazy(
  () => import('modules/templates/components/create/CreateTemplate')
);
const ViewTemplate = lazy(
  () => import('modules/templates/components/view/ViewTemplate')
);
const EditTemplate = lazy(
  () => import('modules/templates/components/edit/EditTemplate')
);
const PriceList = lazy(() => import('modules/price-list'));
const Master = lazy(() => import('modules/master'));
const Setup = lazy(() => import('modules/setup'));
const PasswordReset = lazy(() => import('modules/password-reset'));
const ConflictInvoices = lazy(() => import('modules/conflict-invoices'));
const ResolveConflictInvoice = lazy(
  () => import('modules/conflict-invoices/components/resolve')
);
const ViewConflictInvoice = lazy(
  () => import('modules/conflict-invoices/components/view-conflict-invoice')
);
const EditConflictInvoice = lazy(
  () => import('modules/conflict-invoices/components/edit-conflict-invoice')
);
const TransfersRequest = lazy(() => import('modules/transfers-request'));
const CreateTransfersIn = lazy(
  () => import('modules/transfers-request/components/create')
);
const VewTransfersIn = lazy(
  () => import('modules/transfers-request/components/view')
);
const EditTransfersIn = lazy(
  () => import('modules/transfers-request/components/update')
);
const ReceiveTransferIn = lazy(
  () => import('modules/transfers-request/components/receive')
);
const ReturnTransferIn = lazy(
  () => import('modules/transfers-request/components/return')
);
const CreateTransferList = lazy(
  () => import('modules/transfer-list/components/create/index')
);
const TransferList = lazy(() => import('modules/transfer-list'));
const PriceContracts = lazy(() => import('modules/price-contract'));

const AppRouter = () => {
  const { state } = useContext(AppContext);
  const { token } = state;
  const [hasAccessToTransferModule, setHasAccessToTransferModule] =
    useState<any>(null);
  const axios = new Axios().getInstance();

  useEffect(() => {
    const sessionDateFilter = localStorage.getItem(ORDERS_DATE_FILTER);
    const parseSessionDateFilter = sessionDateFilter
      ? JSON.parse(sessionDateFilter)
      : null;
    if (sessionDateFilter) {
      const getCurrentHours = new Date().getHours();
      if (Number(parseSessionDateFilter?.expiry) < getCurrentHours) {
        localStorage.removeItem(ORDERS_DATE_FILTER);
      }
    }
    checkModularAccess();
  }, []);
  const checkModularAccess = async () => {
    try {
      if (token) {
        const data = await axios.get('/users/get-module-access');
        setHasAccessToTransferModule(
          !!data?.data?.data?.hasAccessToTransferModule
        );
      }
    } catch (error) {
      setHasAccessToTransferModule(false);
    }
  };
  return (
    <Routes>
      <Route
        path="/"
        key="home"
        element={
          token && token !== '' ? (
            <Navigate replace to="/dashboard" />
          ) : (
            <Auth />
          )
        }
      />
      <Route path="/error" key="error" element={<Error />} />
      <Route path="/auth/:type" key="auth" element={<Auth />} />
      <Route path="/lightbox" element={<LightboxComponent />} />
      <Route
        path="/conflict-invoices"
        key="conflict-invoices"
        element={
          <RequireAuth>
            <ConflictInvoices />
          </RequireAuth>
        }
      />
      <Route
        path="/conflict-invoice/edit/:id"
        key="conflict-invoices"
        element={
          <RequireAuth>
            <EditConflictInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/conflict-invoice/resolve/:id"
        key="conflict-invoices"
        element={
          <RequireAuth>
            <ResolveConflictInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/conflict-invoice/view/:id"
        key="conflict-invoices"
        element={
          <RequireAuth>
            <ViewConflictInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/password-reset/:token"
        key="passwordReset"
        element={<PasswordReset />}
      />
      <Route
        path="/dashboard"
        key="dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />

      <Route
        path="/requisitions"
        key="requisitions"
        element={
          <RequireAuth>
            <Requisitions />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/create"
        key="requisition"
        element={
          <RequireAuth>
            <CreateRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/view-old/:id"
        key="requisition"
        element={
          <RequireAuth>
            <ViewOldRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/view/:id"
        key="requisition"
        element={
          <RequireAuth>
            <ViewRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/edit/:id"
        key="requisition"
        element={
          <RequireAuth>
            <EditRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/process/:id"
        key="requisition"
        element={
          <RequireAuth>
            <ProcessRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/process-old/:id"
        key="requisition"
        element={
          <RequireAuth>
            <ProcessRequisitionOld />
          </RequireAuth>
        }
      />
      <Route
        path="/orders"
        key="orders"
        element={
          <RequireAuth>
            <Orders />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/create"
        key="create-order"
        element={
          <RequireAuth>
            <CreateOrder />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/edit/:id"
        key="orders"
        element={
          <RequireAuth>
            <EditOrder />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/view/:id"
        key="orders"
        element={
          <RequireAuth>
            <ViewOrder />
          </RequireAuth>
        }
      />
      <Route
        path="/reissue/order/:id"
        key="orders"
        element={
          <RequireAuth>
            <ReissueOrder />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/receive-items/:id"
        key="orders"
        element={
          <RequireAuth>
            <ReceivingItems />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/amend/:id"
        key="orders"
        element={
          <RequireAuth>
            <AmendOrder />
          </RequireAuth>
        }
      />

      <Route
        path="/orders/reopen/:id"
        key="orders-reopen"
        element={
          <RequireAuth>
            <ReopenOrderView />
          </RequireAuth>
        }
      />
      <Route
        path="/transfers"
        key="transfers-all"
        element={<RequireAuth>{/* <TransferList /> */}</RequireAuth>}
      />
      <Route
        path="/transfer-list"
        key="transfer-list"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<TransferList />}
          />
        }
      />
      <Route
        path="/transfers-order"
        key="transfer-out-list"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<TransfersOut />}
          />
        }
      />
      <Route
        path="/view/transfers-order/:id"
        key="transfer-out-list"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<VewTransferOrder />}
          />
        }
      />
      <Route
        path="/resolve/transfers-order/:id"
        key="transfer-out-list"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<VewTransferOrder resolve={true} />}
          />
        }
      />
      <Route
        path="/transfers-request/process/:id"
        key="transfer-out-list"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<ProcessTransfersOut />}
          />
        }
      />
      <Route
        path="/transfers-order/shipped/:id"
        key="transfer-in-create"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<VewTransfersIn shipped={true} />}
          />
        }
      />
      <Route
        path="/transfers-request"
        key="transfer-in-list"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<TransfersRequest />}
          />
        }
      />
      <Route
        path="/transfers-request/create"
        key="transfer-in-create"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<CreateTransfersIn />}
          />
        }
      />
      <Route
        path="/transfers-request/view/:id"
        key="transfer-in-create"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<VewTransfersIn />}
          />
        }
      />
      <Route
        path="/transfers-request/edit/:id"
        key="transfer-in-create"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<EditTransfersIn />}
          />
        }
      />
      <Route
        path="/transfers-order/receive/:id"
        key="transfer-in-create"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<ReceiveTransferOrder />}
          />
        }
      />
      <Route
        path="/transfers-request/return/:id"
        key="transfer-request-create"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<ReturnTransferIn />}
          />
        }
      />
      <Route
        path="/transfer-list/create"
        key="create-transfer-list"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<CreateTransferList />}
          />
        }
      />
      <Route
        path="/transfer-list/edit/:id"
        key="update-transfer-list"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<CreateTransferList page="update" />}
          />
        }
      />
      <Route
        path="/transfer-list/view/:id"
        key="update-transfer-list"
        element={
          <PermissionWrapper
            hasAccessToTransferModule={hasAccessToTransferModule}
            Component={<ViewTransferListTemplate page="view" />}
          />
        }
      />

      <Route
        path="/invoices"
        key="invoices"
        element={
          <RequireAuth>
            <Invoices />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices/create"
        key="invoices"
        element={
          <RequireAuth>
            <CreateInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices/edit/:id"
        key="invoices"
        element={
          <RequireAuth>
            <EditInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices/amend/:id"
        key="invoices"
        element={
          <RequireAuth>
            <AmendInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices/view/:id"
        key="invoices"
        element={
          <RequireAuth>
            <ViewInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/templates"
        key="templates"
        element={
          <RequireAuth>
            <Templates />
          </RequireAuth>
        }
      />
      <Route
        path="/templates/create"
        key="template"
        element={
          <RequireAuth>
            <CreateTemplate />
          </RequireAuth>
        }
      />
      <Route
        path="/templates/view/:id"
        key="template"
        element={
          <RequireAuth>
            <ViewTemplate />
          </RequireAuth>
        }
      />
      <Route
        path="/templates/edit/:id"
        key="template"
        element={
          <RequireAuth>
            <EditTemplate />
          </RequireAuth>
        }
      />
      <Route
        path="/priceList"
        key="priceList"
        element={
          <RequireAuth>
            <PriceList />
          </RequireAuth>
        }
      />
      <Route
        path="/price-contracts"
        key="price-contracts"
        element={
          <RequireAuth>
            <PriceContracts />
          </RequireAuth>
        }
      />
      <Route
        path="/price-contracts/create"
        key="price-contracts"
        element={
          <RequireAuth>
            <CreatePriceContract />
          </RequireAuth>
        }
      />
      <Route
        path="/price-contracts/edit/:id"
        key="price-contracts"
        element={
          <RequireAuth>
            <EditPriceContract />
          </RequireAuth>
        }
      />
      <Route
        path="/price-contracts/view/:id"
        key="price-contracts"
        element={
          <RequireAuth>
            <ViewPriceContract />
          </RequireAuth>
        }
      />
      <Route
        path="/price-contracts/view-amended/:id"
        key="price-contracts"
        element={
          <RequireAuth>
            <ViewAmendedPriceContract />
          </RequireAuth>
        }
      />
      <Route
        path="/price-contracts/edit-amended/:id"
        key="price-contracts"
        element={
          <RequireAuth>
            <EditAmendedPriceContract />
          </RequireAuth>
        }
      />
      <Route
        path="/master/*"
        key="master"
        element={
          <RequireAuth>
            <Master />
          </RequireAuth>
        }
      />
      <Route
        path="/setup/*"
        key="setup"
        element={
          <RequireAuth>
            <Setup />
          </RequireAuth>
        }
      />
      <Route
        path="/my-account"
        key="my-account"
        element={
          <RequireAuth>
            <MyAccount />
          </RequireAuth>
        }
      />
      <Route
        path="/reports/*"
        key="reports"
        element={
          <RequireAuth>
            <Reports />
          </RequireAuth>
        }
      />

      <Route
        path="/analytics"
        key="analytics"
        element={
          <RequireAuth>
            <Analytics />
          </RequireAuth>
        }
      />

      <Route
        path="/cogs-pattern"
        key="cogs-pattern"
        element={
          <RequireAuth>
            <CogsPattern />
          </RequireAuth>
        }
      />

      <Route
        path="/cogs-by-account"
        key="cogs-account"
        element={
          <RequireAuth>
            <CogsAccount />
          </RequireAuth>
        }
      />

      <Route
        path="/cogs-by-category"
        key="cogs-category"
        element={
          <RequireAuth>
            <CogsCategory />
          </RequireAuth>
        }
      />

      <Route
        path="/sale"
        key="sale"
        element={
          // <RequireAuth>
          <Sale />
          // </RequireAuth>
        }
      />

      <Route
        path="/foodcost"
        key="foodcost"
        element={
          // <RequireAuth>
          <FoodCost />
          // </RequireAuth>
        }
      />

      <Route path="*" element={<Navigate replace to="/dashboard" />} />
    </Routes>
  );
};

export default AppRouter;
