import React from 'react';
import { Button, KIND } from 'baseui/button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';

const ExclusionModal = ({
  isOpen,
  onClose,
  contractItem,
  storeOptions,
  onExclusionChange,
}: any) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          },
        },
      }}
    >
      <ModalHeader>Item Level Store Exclusion</ModalHeader>
      <ModalBody>
        <div
          style={{
            display: 'flex',
            gap: '2rem',
            alignItems: 'center',
          }}
        >
          <SelectWithCheckboxes
            options={storeOptions}
            store={contractItem?.excludedStoreList || []}
            disabled={false}
            name="Excluded Stores"
            handleChange={(params: any) => {
              onExclusionChange('excludedStoreList', params);
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind={KIND.secondary} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExclusionModal;
