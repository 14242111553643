/* eslint-disable prettier/prettier */
import AnalyticsFilters from 'modules/analytics/components/filters';
import React, { useEffect, useRef, useState } from 'react';
import { useStyletron } from 'styletron-react';
import CollapsibleArrow from '../../assets/icons/table-row-collapsible.svg';
import Plus from '../../assets/icons/plus.svg';
import Minus from '../../assets/icons/minus.svg';
import { Spinner } from 'baseui/spinner';
import ArrangeIcon from '../../assets/icons/up-down-arrow.svg';
import informationIcon from 'assets/icons/infomation_i_icon.png';

interface visibleTableBodyProps {
  [key: number]: boolean;
}
interface SortParams {
  store: string; // e.g., "TDBDM", "TDBMOE"
  column: 'value' | 'percentage_sales';
}

const CogsAccount = () => {
  const [tableData, setTableData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [visibleTableBody, setVisibleTableBody] =
    useState<visibleTableBodyProps>({});
  const [visibleTableBodytwo, setVisibleTableBodytwo] =
    useState<visibleTableBodyProps>({});

  const handleCollapsibleBody = (id: number) => {
    setVisibleTableBody((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleCollapsibleBodytwo = (id: number) => {
    setVisibleTableBodytwo((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const [css] = useStyletron();

  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeoutRef = useRef<any>(null);

  const handleScroll = () => {
    // User has started scrolling
    setIsScrolling(true);

    // Clear the previous timeout if it exists
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }
  };

  const mainWapper = css({
    padding: '16px',
  });
  const stickyColumn = css({
    position: 'sticky',
    left: 0,
    zIndex: 1,
    boxShadow: '0px 10px 20px 0px rgba(48, 51, 147, .10)',
  });
  const CellLightGray = css({
    backgroundColor: '#eff0ff',
  });
  const arrangeIconStyle = css({
    position: 'absolute',
    top: '50%',
    right: '4.5px',
    transform: 'translateY(-50%)',
  });
  const pointerCursor = css({
    cursor: 'pointer',
  });
  const cellDarkGray = css({
    backgroundColor: '#e8e8ff',
  });
  const collapsibleArrowStyle = css({
    position: 'relative',
    top: '50%',
    transition: '0.3s all ease-in',
  });
  const storeTitle = css({
    paddingLeft: '8px',
  });
  const loader = css({
    height: 'calc(100vh - 380px)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  });

  useEffect(() => {
    if (tableData) {
      const allBodiesVisible: visibleTableBodyProps = {};
      const allNestedBodiesVisible: visibleTableBodyProps = {};

      // Initialize all the first-level bodies to be visible
      tableData.data?.forEach((item: any, index: number) => {
        allBodiesVisible[index] = true;

        // Initialize all the second-level bodies to be visible
        item?.data?.forEach((storeData: any, storeIndex: number) => {
          allNestedBodiesVisible[storeIndex] = true;
        });
      });

      setVisibleTableBody(allBodiesVisible);
      setVisibleTableBodytwo(allNestedBodiesVisible);
    }
  }, [tableData]);

  const [sortConfig, setSortConfig] = useState<{
    store: string;
    column: 'value' | 'percentage_sales';
    order: 'asc' | 'desc';
  } | null>(null);

  const handleSort = ({ store, column }: SortParams) => {
    // Determine sort order based on previous state
    const newOrder =
      sortConfig &&
      sortConfig.store === store &&
      sortConfig.column === column &&
      sortConfig.order === 'desc'
        ? 'asc'
        : 'desc';

    // Clone the tableData to avoid mutating the original data
    const sortedData = { ...tableData };

    // Find the 'Total Cost' group
    sortedData.data = sortedData.data.map((group: any) => {
      if (group.name === 'Total Cost') {
        // Sort each subgroup (stores_data) within the 'Total Cost' group
        group.data = group.data.sort((a: any, b: any) => {
          const storeObjA = a.stores_data.find((obj: any) => obj[store]);
          const storeObjB = b.stores_data.find((obj: any) => obj[store]);

          const storeValueA = storeObjA
            ? parseFloat(storeObjA[store][column].replace(/,/g, ''))
            : 0;
          const storeValueB = storeObjB
            ? parseFloat(storeObjB[store][column].replace(/,/g, ''))
            : 0;

          return newOrder === 'desc'
            ? storeValueB - storeValueA // Descending order
            : storeValueA - storeValueB; // Ascending order
        });

        // Sort each sub-subgroup (accounts_data) within each subgroup
        group.data = group.data.map((subgroup: any) => {
          subgroup.data = subgroup.data.sort((c: any, d: any) => {
            const accountObjC = c.accounts_data.find((obj: any) => obj[store]);
            const accountObjD = d.accounts_data.find((obj: any) => obj[store]);

            const subStoreValueA = accountObjC
              ? parseFloat(accountObjC[store][column].replace(/,/g, ''))
              : 0;
            const subStoreValueB = accountObjD
              ? parseFloat(accountObjD[store][column].replace(/,/g, ''))
              : 0;

            return newOrder === 'desc'
              ? subStoreValueB - subStoreValueA // Descending order
              : subStoreValueA - subStoreValueB; // Ascending order
          });
          return subgroup;
        });
      }

      return group;
    });

    // Update the state with the sorted data (assuming you are using useState for tableData)
    setTableData(sortedData);

    // Update the sorting configuration state
    setSortConfig({ store, column, order: newOrder });
  };

  return (
    <div className={`${mainWapper} font-family-poppins`}>
      <AnalyticsFilters
        setTableData={(data: any) => {
          setTableData(data);
        }}
        setIsLoading={setIsLoading}
        isCogsAccount={true}
        isLoading={isLoading}
      />
      <div className="cogs-table-wrapper" onScroll={handleScroll}>
        {tableData ? (
          <table className="cogs-table cogs-table-pattern cogs-table-account">
            {isLoading && (
              <tr>
                <td colSpan={13} className="tdreset">
                  <div className={loader}>
                    <Spinner $size="50" />
                  </div>
                </td>
              </tr>
            )}
            <thead>
              {tableData?.columns && (
                <>
                  <tr className="text-center">
                    <th
                      className={`${stickyColumn} first-col`}
                      style={{ position: 'sticky' }}
                      rowSpan={2}
                    >
                      {tableData.columns.column}
                    </th>

                    {Object.keys(tableData.columns)
                      .filter((key) => key !== 'column')
                      .map((colKey, index) => {
                        return (
                          <th
                            key={index}
                            colSpan={2}
                            style={{
                              width:
                                Object.keys(tableData.columns).length > 7
                                  ? '180px'
                                  : '100%',
                            }}
                            className={`${
                              index % 2 === 0 ? CellLightGray : cellDarkGray
                            }`}
                          >
                            {tableData.columns[colKey].name}
                            {/* Add the small "i" icon */}
                            <span
                              style={{
                                marginLeft: '8px',
                                position: 'relative',
                                cursor: 'pointer',
                                display: 'inline-block',
                                top: '4px', // Ensures the icon stays in a proper layout
                              }}
                              onMouseEnter={(e) => {
                                const tooltip: any =
                                  e.currentTarget.querySelector(
                                    '.tooltip-content'
                                  );
                                if (tooltip) {
                                  tooltip.style.visibility = 'visible';
                                  tooltip.style.opacity = '1';
                                }
                              }}
                              onMouseLeave={(e) => {
                                const tooltip: any =
                                  e.currentTarget.querySelector(
                                    '.tooltip-content'
                                  );
                                if (tooltip) {
                                  tooltip.style.visibility = 'hidden';
                                  tooltip.style.opacity = '0';
                                }
                              }}
                            >
                              {/* Styled "i" icon as a circle */}
                              <span
                                style={{
                                  display: 'inline-flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '18px', // Adjust as needed
                                  height: '18px', // Adjust as needed
                                  cursor: 'pointer',
                                  transition: 'background-color 0.2s ease',
                                }}
                              >
                                <img
                                  src={informationIcon} // Update with the actual path to the image
                                  alt="Info icon"
                                  style={{
                                    width: '100%', // Fit within the outer circle container
                                    height: '100%', // Fit within the outer circle container
                                    borderRadius: '50%', // Optional: keeps a circular shape
                                  }}
                                />
                              </span>

                              {/* Tooltip that shows on hover */}
                              <span
                                style={{
                                  position: 'absolute',
                                  top: '120%',
                                  left: '130%', // Move the tooltip to the right
                                  transform: 'translateY(-50%)', // Center the tooltip vertically
                                  marginLeft: '10px', // Space between icon and tooltip
                                  backgroundColor: '#000',
                                  color: '#fff',
                                  padding: '5px',
                                  borderRadius: '4px',
                                  whiteSpace: 'pre-line', // Allow line breaks
                                  visibility: 'hidden',
                                  opacity: 0,
                                  transition: 'opacity 0.3s ease',
                                  fontSize: '10px',
                                  width: '150px',
                                  zIndex: '10000', // Ensure tooltip is on top of other elements
                                  fontStyle: 'italic',
                                }}
                                className="tooltip-content"
                              >
                                {`Sales last fetched at:\n ${
                                  tableData.columns[colKey].lastSalesFetchedDate ||
                                  'N/A'
                                }\nCogs last fetched at:\n ${
                                  tableData.columns[colKey].lastCogsFetchedDate ||
                                  'N/A'
                                }`}
                              </span>
                            </span>
                          </th>
                        );
                      })}
                  </tr>
                  <tr className="text-left second-row">
                    {Object.keys(tableData.columns)
                      .filter((key) => key !== 'column')
                      .map((colKey, index) => (
                        <React.Fragment key={index}>
                          <th
                            className={`${
                              index % 2 === 0 ? CellLightGray : cellDarkGray
                            } ${pointerCursor}`}
                            onClick={() =>
                              handleSort({
                                store: tableData.columns[colKey].name,
                                column: 'value',
                              })
                            }
                          >
                            Value
                            <img
                              src={ArrangeIcon}
                              alt=""
                              className={arrangeIconStyle}
                              width="10"
                              height="10"
                            />
                          </th>
                          <th
                            className={`${
                              index % 2 === 0 ? CellLightGray : cellDarkGray
                            } ${pointerCursor}`}
                            onClick={() =>
                              handleSort({
                                store: tableData.columns[colKey].name,
                                column: 'percentage_sales',
                              })
                            }
                          >
                            % of Sales
                            <img
                              src={ArrangeIcon}
                              alt=""
                              className={arrangeIconStyle}
                              width="10"
                              height="10"
                            />
                          </th>
                        </React.Fragment>
                      ))}
                  </tr>
                </>
              )}
            </thead>
            <tbody>
              {tableData?.data?.map((item: any, id: number) => (
                <tr key={id}>
                  <td colSpan={33} className="tdreset">
                    <table className="cogs-table">
                      <thead
                        className="nested-data"
                        onClick={() => handleCollapsibleBody(id)}
                        data-id={id}
                      >
                        <tr className="main-row">
                          <td className={`${stickyColumn} first-col`}>
                            {item?.data && (
                              <img
                                src={CollapsibleArrow}
                                alt=""
                                className={collapsibleArrowStyle}
                                style={{
                                  transform: visibleTableBody[id]
                                    ? 'rotate(0deg)'
                                    : 'rotate(180deg)',
                                }}
                              />
                            )}
                            <span className={storeTitle}>{item?.name}</span>
                          </td>
                          {item?.sales_data?.map(
                            (salesData: any, index: number) => (
                              <React.Fragment key={index}>
                                {Object.keys(salesData).map((key, colIndex) => (
                                  <React.Fragment key={colIndex}>
                                    <td
                                      className="number-cell"
                                      style={{ width: '100%' }}
                                    >
                                      {salesData[key]?.value}
                                    </td>
                                    <td
                                      className="number-cell"
                                      style={{
                                        width: '100%',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {salesData[key]?.percentage_sales}
                                    </td>
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody
                        style={{ display: visibleTableBody[id] ? '' : 'none' }}
                        className={visibleTableBody[id] ? 'add' : 'remove'}
                      >
                        {item?.data?.map((storeData: any, storeId: number) => (
                          <tr key={storeId}>
                            <td colSpan={33} className="tdreset">
                              <table className="cogs-table nested-table-nested">
                                <thead
                                  className="nested-data"
                                  onClick={() =>
                                    handleCollapsibleBodytwo(storeId)
                                  }
                                  data-id={storeId}
                                >
                                  <tr className="main-row">
                                    <td className={`${stickyColumn} first-col`}>
                                      <div style={{ paddingLeft: '6px' }}>
                                        {storeData?.data && (
                                          <img
                                            src={
                                              visibleTableBodytwo[storeId]
                                                ? Minus
                                                : Plus
                                            }
                                            alt=""
                                            className={collapsibleArrowStyle}
                                            style={{
                                              width: '8px',
                                              height: '8px',
                                            }}
                                          />
                                        )}
                                        <span className={storeTitle}>
                                          {storeData?.name}
                                        </span>
                                      </div>
                                    </td>
                                    {storeData?.stores_data?.map(
                                      (storeItem: any, index: number) => (
                                        <React.Fragment key={index}>
                                          {Object.keys(storeItem).map(
                                            (key, colIndex) => (
                                              <React.Fragment key={colIndex}>
                                                <td className="number-cell">
                                                  {storeItem[key]?.value}
                                                </td>
                                                <td
                                                  className="number-cell"
                                                  style={{ fontWeight: 'bold' }}
                                                >
                                                  {
                                                    storeItem[key]
                                                      ?.percentage_sales
                                                  }
                                                </td>
                                              </React.Fragment>
                                            )
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                  </tr>
                                </thead>
                                <tbody
                                  style={{
                                    display: visibleTableBodytwo[storeId]
                                      ? ''
                                      : 'none',
                                  }}
                                  className={
                                    visibleTableBodytwo[storeId]
                                      ? 'add'
                                      : 'remove'
                                  }
                                >
                                  {storeData?.data?.map(
                                    (accountsData: any, accId: number) => (
                                      <tr key={accId}>
                                        <td
                                          className={`${stickyColumn} first-col`}
                                          style={{
                                            paddingLeft: '30px',
                                            backgroundColor: isScrolling
                                              ? 'white'
                                              : '',
                                          }}
                                        >
                                          {accountsData?.name}
                                        </td>
                                        {accountsData?.accounts_data?.map(
                                          (
                                            accountsDataItem: any,
                                            index: number
                                          ) => (
                                            <React.Fragment key={index}>
                                              {Object.keys(
                                                accountsDataItem
                                              ).map((key, colIndex) => (
                                                <React.Fragment key={colIndex}>
                                                  <td className="number-cell">
                                                    {
                                                      accountsDataItem[key]
                                                        ?.value
                                                    }
                                                  </td>
                                                  <td
                                                    className="number-cell"
                                                    style={{
                                                      fontWeight: 'bold',
                                                    }}
                                                  >
                                                    {
                                                      accountsDataItem[key]
                                                        ?.percentage_sales
                                                    }
                                                  </td>
                                                </React.Fragment>
                                              ))}
                                            </React.Fragment>
                                          )
                                        )}
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          'No Data Found'
        )}
      </div>
    </div>
  );
};

export default CogsAccount;
