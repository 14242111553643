import { useEffect, useState } from 'react';
import './index.scss';
import menu from '../../../../../../assets/icons/dots.png';
import NotificationToggleMenu from '../components/notification-toggle-menu';
import { StyledTab, Tab, Tabs } from 'baseui/tabs';
import { LabelMedium } from 'baseui/typography';
import colors from 'constants/colors';
import NotificationCard from '../components/notificationCard';
import { Axios } from 'core/services/http';
import { INotification } from '../../../../interfaces/notification.interface';
import { Spinner } from 'baseui/spinner';
import { useStyletron } from 'styletron-react';

interface INotifications {
  today: INotification[];
  yesterday: INotification[];
  older: INotification[];
}

function TabOverride({ children, ...rest }: any) {
  return (
    <StyledTab {...rest}>
      <LabelMedium
        overrides={{
          Block: {
            style: {
              color: 'inherit',
              ':hover': { color: 'inherit' },
            },
          },
        }}
      >
        {children}
      </LabelMedium>
    </StyledTab>
  );
}
const tabStyle = ({ $active, $disabled, $theme }: any) => ({
  color: $active ? colors.darkGreen : colors.darkBlue,
  borderBottomWidth: '3px',
  borderBottomStyle: 'solid',
  borderBottomColor: $active ? colors.darkGreen : 'transparent',
  paddingBottom: '7px',
  ':hover': $disabled
    ? {}
    : {
        color: colors.darkGreen,
      },
  ':focus': $disabled
    ? {}
    : {
        color: colors.darkGreen,
      },
});
const NotificationList = ({
  notifications,
  markAsRead,
  currentTab,
}: {
  notifications: INotification[];
  markAsRead?: (id: string) => void;
  currentTab: 'New' | 'Read';
}) => {
  return (
    <>
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.id}
          {...notification}
          markAsRead={markAsRead}
          currentTab={currentTab}
        />
      ))}
    </>
  );
};

interface IProps {
  notificationData: INotification[];
  refetch: () => void;
  handleScroll: () => void;
  setNotificationType: any;
  loading?: boolean;
}
const Notifications = ({
  notificationData,
  refetch,
  handleScroll,
  setNotificationType,
  loading,
}: IProps) => {
  const axios = new Axios().getInstance();
  const [activeMenu, setActiveMenu] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [tabValue, setTabValue] = useState('New');
  const [unreadNotifications, setUnreadNotifications] =
    useState<INotifications>({
      today: [],
      yesterday: [],
      older: [],
    });
  const [readNotifications, setReadNotifications] = useState<INotifications>({
    today: [],
    yesterday: [],
    older: [],
  });
  const [css] = useStyletron();
  const markNotificationsAsRead = async (notificationIds: Array<string>) => {
    try {
      const response = await axios.patch('/notifications/read', {
        notificationIds,
      });
      if (response?.data?.success) {
        refetch();
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          console.log(message);
        });
      }
    }
  };

  const markAsRead = async (type: 'all' | 'single', id?: string) => {
    if (type === 'all') {
      const unreadIds = notifications
        .filter((notification) => !notification.read)
        .map((notification) => notification.id);
      if (unreadIds.length > 0) {
        await markNotificationsAsRead(unreadIds);
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) => ({
            ...notification,
            read: true,
          }))
        );
        setReadNotifications((prevNotifications) => ({
          ...prevNotifications,
          today: [
            ...prevNotifications.today,
            ...(notificationData.filter((notification) =>
              unreadIds.includes(notification.id)
            ) ?? []),
          ],
        }));
      }
    } else if (id) {
      await markNotificationsAsRead([id]);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === id
            ? { ...notification, read: true }
            : notification
        )
      );
      setReadNotifications((prevNotifications) => ({
        ...prevNotifications,
        today: [
          ...prevNotifications.today,
          ...(notificationData.filter(
            (notification) => notification.id === id
          ) ?? []),
        ],
      }));
    }
  };

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
    const todayNotifications: INotification[] = [];
    const yesterdayNotifications: INotification[] = [];
    const olderNotifications: INotification[] = [];
    // setReadNotifications({
    //   today: [],
    //   yesterday: [],
    //   older: [],
    // });
    // setUnreadNotifications({
    //   today: [],
    //   yesterday: [],
    //   older: [],
    // });
    notifications.forEach((notification) => {
      const notificationDate = new Date(notification.date);
      if (notificationDate.toDateString() === today.toDateString()) {
        if (notification.read) {
          setReadNotifications((prev) => ({
            ...prev,
            today: [...prev.today, notification],
          }));
        } else {
          todayNotifications.push(notification);
        }
      } else if (notificationDate.toDateString() === yesterday.toDateString()) {
        if (notification.read) {
          setReadNotifications((prev) => ({
            ...prev,
            yesterday: [...prev.yesterday, notification],
          }));
        } else {
          yesterdayNotifications.push(notification);
        }
      } else {
        if (notification.read) {
          setReadNotifications((prev) => ({
            ...prev,
            older: [...prev.older, notification],
          }));
        } else {
          olderNotifications.push(notification);
        }
      }
    });

    setUnreadNotifications({
      today: todayNotifications,
      yesterday: yesterdayNotifications,
      older: olderNotifications,
    });
  }, [notifications]);

  useEffect(() => {
    setNotifications(notificationData);
  }, [notificationData]);

  return (
    <div className="main-wrapper" onScroll={handleScroll}>
      <div className="main-wrapper-top-header">
        <h2 className="main-wrapper-top-header-heading">Notifications</h2>
        <img
          className="main-wrapper-top-header-menu"
          src={menu}
          alt="Menu"
          onClick={() => {
            setActiveMenu(!activeMenu);
          }}
        />
        {activeMenu && <NotificationToggleMenu />}
      </div>
      <Tabs
        activeKey={tabValue}
        onChange={(e: any) => {
          setTabValue(e.activeKey);
          setNotificationType(e.activeKey.toLowerCase());
        }}
        overrides={{
          Tab: { component: TabOverride, style: tabStyle },
          TabBar: { style: { backgroundColor: 'white' } },
        }}
      >
        <Tab title="New" key="New">
          <p className="mark-read" onClick={() => markAsRead('all')}>
            Mark all as read
          </p>
          {unreadNotifications.today.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Today</h2>
          )}
          <NotificationList
            notifications={unreadNotifications.today}
            markAsRead={(id: string) => markAsRead('single', id)}
            currentTab="New"
          />
          {unreadNotifications.yesterday.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Yesterday</h2>
          )}
          <NotificationList
            notifications={unreadNotifications.yesterday}
            markAsRead={(id: string) => markAsRead('single', id)}
            currentTab="New"
          />
          {unreadNotifications.older.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Older</h2>
          )}
          <NotificationList
            notifications={unreadNotifications.older}
            markAsRead={(id: string) => markAsRead('single', id)}
            currentTab="New"
          />
          {loading && (
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <Spinner />
            </div>
          )}
        </Tab>
        <Tab title="Read" key="Read">
          {readNotifications.today.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Today</h2>
          )}
          <NotificationList
            markAsRead={(id: string) => markAsRead('single', id)}
            notifications={readNotifications.today}
            currentTab="Read"
          />
          {readNotifications.yesterday.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Yesterday</h2>
          )}
          <NotificationList
            markAsRead={(id: string) => markAsRead('single', id)}
            notifications={readNotifications.yesterday}
            currentTab="Read"
          />
          {readNotifications.older.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Older</h2>
          )}
          <NotificationList
            markAsRead={(id: string) => markAsRead('single', id)}
            notifications={readNotifications.older}
            currentTab="Read"
          />
          {loading && (
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <Spinner />
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default Notifications;
