import React, { useRef, useState } from 'react';
import { Select } from 'baseui/select';
import { DatePicker } from 'baseui/datepicker';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { useStyletron } from 'styletron-react';

import './index.scss';
import { timeCheck } from 'modules/shared/time-check';
import colors from 'constants/colors';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';
import moment from 'moment';
import filter from '../../../../../../assets/icons/filter.png';
import { useSelectedCompanies } from 'context/companies';

interface IProps {
  stores: Array<any>;
  store: any;
  setStore: any;
  startDate: Array<any>;
  dateOption: any;
  setStartDate: any;
  setEndDate: any;
  onSubmit: any;
  setIsFilterOpen: any;
  brand: any;
  brands: any;
  setBrand: any;
  dates: any;
  dateFilters: Array<any>;
  endDate: any;
  setDateOption: any;
  dateFilter: any;
  setDateFilter: any;
  disableFilter: boolean;
}

const FilterList = ({
  stores,
  store,
  setStore,
  brands,
  brand,
  setBrand,
  dates,
  dateFilters,
  startDate,
  endDate,
  dateOption,
  setStartDate,
  dateFilter,
  setEndDate,
  setDateOption,
  setDateFilter,
  onSubmit,
  disableFilter,
}: IProps) => {
  const [css] = useStyletron();
  const { storesOptionsByCompanies } = useSelectedCompanies();
  const [isDateDisable, setIsDateDisable] = useState<any>(true);
  const [activeAllFilters, setActiveAllFilters] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const storeOptions = storesOptionsByCompanies?.map(({ id, label }: any) => ({
    label,
    id,
  }));

  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });

  const resetInputs = () => {
    setStore([]);
    setStartDate([]);
  };

  const setDateInInput = (value: any) => {
    const date = timeCheck(value[0]?.id);
    setStartDate([date.startTime]);
    setEndDate([date.endTime]);
  };

  React.useEffect(() => {
    // resetInputs();
  }, [dateOption]);
  const datesOptions = dates.map(({ id, label }: any) => ({ label, id }));
  const brandOptions = brands.map(({ id, name }: any) => ({
    label: name,
    id: id,
  }));
  const flexGridItemClass = css({
    width: '175px !important',
    '@media (max-width: 540px)': {
      width: '100%',
      marginRight: 'auto',
    },
  });
  const filterWrapper = css({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  });
  const dateWrapper = css({
    display: 'flex',
    alignItems: 'center',
    padding: '12px 25px',
    borderRadius: '10px',
    background: colors.white,
    margin: '10px 0',
    boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  });

  const dateText = css({
    color: colors.darkBlue,
    fontSize: '20px',
    margin: 0,
    fontWeight: 500,
  });
  const toggleFilter = css({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '12px 8px',
    borderRadius: '10px',
    background: activeAllFilters ? colors.darkGreen : colors.white,
    cursor: 'pointer',
    margin: '10px 0',
    boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  });

  const filterImg = css({
    width: '22px',
    filter: activeAllFilters
      ? 'brightness(0) invert(1)'
      : 'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  return (
    <>
      <div className={filterWrapper}>
        <div
          className={toggleFilter}
          onClick={() => setActiveAllFilters(!activeAllFilters)}
        >
          <img src={filter} alt="Filter" title="Filter" className={filterImg} />
        </div>

        <div className={dateWrapper}>
          <p className={dateText}>
            {dateOption[0] ? dateOption[0].label : 'Today'}
          </p>
        </div>
      </div>
      <div
        ref={contentRef}
        className={`create-order-list ${
          activeAllFilters ? 'visible' : 'hidden'
        }`}
      >
        <div>
          <FlexGrid
            flexGridColumnCount={[1, 3, 8]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <div className="input-wrapper">
                <div className="input-label">Date Period</div>
                <Select
                  options={datesOptions}
                  value={dateOption}
                  placeholder="Today"
                  onChange={(params: any) => {
                    setDateOption(params.value);
                    setDateInInput(params.value);
                    if (params.value[0]?.id === 'custom') {
                      setIsDateDisable(false);
                    } else setIsDateDisable(true);
                  }}
                />
              </div>
            </FlexGridItem>

            <FlexGridItem>
              <div className="input-wrapper">
                <div className="input-label">Start Date</div>
                <DatePicker
                  disabled={isDateDisable}
                  required={true}
                  value={startDate}
                  onChange={({ date }) => {
                    setStartDate(Array.isArray(date) ? date : [date]);
                  }}
                  overrides={{
                    Popover: {
                      props: {
                        overrides: {
                          Body: {
                            style: { zIndex: 9999 },
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem>
              <div className="input-wrapper">
                <div className="input-label">End Date</div>
                <DatePicker
                  disabled={isDateDisable}
                  required={true}
                  value={endDate}
                  onChange={({ date }) => {
                    const endDate = Array.isArray(date)
                      ? new Date(moment(date[0]).endOf('day').format())
                      : new Date(moment(date).endOf('day').format());
                    setEndDate([endDate]);
                  }}
                  overrides={{
                    Popover: {
                      props: {
                        overrides: {
                          Body: {
                            style: { zIndex: 9999 },
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem className={flexGridItemClass}>
              <div className="input-wrapper">
                <div className="input-label">Brands</div>
                <SelectWithCheckboxes
                  options={brandOptions}
                  disabled={false}
                  store={brand}
                  name="Brand"
                  handleChange={(params: any) => setBrand(params)}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem className={flexGridItemClass}>
              <div className="input-wrapper">
                <div className="input-label">Stores</div>
                <SelectWithCheckboxes
                  options={storeOptions}
                  disabled={false}
                  store={store}
                  handleChange={(params: any) => {
                    setStore(params);
                  }}
                />
              </div>
            </FlexGridItem>
            <div className="button-wrapper apply-btn">
              <button
                type="button"
                disabled={disableFilter}
                className={buttonStyle}
                onClick={onSubmit}
              >
                Apply
              </button>
            </div>
          </FlexGrid>
        </div>
      </div>
    </>
  );
};

export default FilterList;
