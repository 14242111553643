import { io, Socket } from 'socket.io-client';

export class SocketClient {
  private url: string;
  private token: string;
  private socketInstance: Socket;

  constructor(url: string, token: string) {
    this.url = url;
    this.token = token;
  }

  getInstance(): Socket {
    this.socketInstance = io(this.url, {
      autoConnect: false,
      auth: { token: this.token },
    });
    return this.socketInstance;
  }
}
