import invoicesIconURL from 'assets/icons/invoices.svg';
import invoicesActiveIconURL from 'assets/icons/invoices-white.svg';
import ordersIconURL from 'assets/icons/orders.svg';
import ordersActiveIconURL from 'assets/icons/orders-white.svg';
import conflictIconURL from 'assets/icons/conflict.svg';
import conflictActiveIconURL from 'assets/icons/conflict-white.svg';
import dashboardIconURL from 'assets/icons/dashboard.svg';
import dashboardActiveIconURL from 'assets/icons/dashboard-white.svg';
import requisitionsIconURL from 'assets/icons/requisitions.svg';
import requisitionsActiveIconURL from 'assets/icons/requisition-white.svg';
import templatesIconURL from 'assets/icons/templates.svg';
import templatesActiveIconURL from 'assets/icons/templates-white.svg';
import priceListIconURL from 'assets/icons/price-list.svg';
import priceListActiveIconURL from 'assets/icons/price-list-white.svg';
import masterIconURL from 'assets/icons/master.svg';
import masterActiveIconURL from 'assets/icons/master-white.svg';
import setupIconURL from 'assets/icons/setup.svg';
import setupWhiteIconURL from 'assets/icons/setup-white.svg';
import reportsIcon from 'assets/icons/reports.svg';
import reportsIconActive from 'assets/icons/reports-white.svg';
import transfermain from 'assets/icons/transfersmain.svg';
import transfersin from 'assets/icons/transfersin.png';
import transfersout from 'assets/icons/transfersorder.png';
import transferlist from 'assets/icons/transferslist.svg';
import SidebarAnalytics from 'assets/icons/sidebar-analytics.svg';

export const NAVIGATION_LINKS = [
  {
    id: 1,
    icon: dashboardIconURL,
    iconActive: dashboardActiveIconURL,
    name: 'Dashboard',
    route: '/dashboard',
    isActive: true,
  },
  {
    id: 2,
    icon: requisitionsIconURL,
    iconActive: requisitionsActiveIconURL,
    name: 'Requisitions',
    route: '/requisitions',
    isActive: false,
  },
  {
    id: 3,
    icon: ordersIconURL,
    iconActive: ordersActiveIconURL,
    name: 'Orders',
    route: '/orders',
    isActive: false,
  },
  {
    id: 5,
    icon: conflictIconURL,
    iconActive: conflictActiveIconURL,
    name: 'Conflict Invoices',
    route: '/conflict-invoices',
    isActive: false,
  },
  {
    id: 6,
    icon: invoicesIconURL,
    iconActive: invoicesActiveIconURL,
    name: 'Invoices',
    route: '/invoices',
    isActive: false,
  },
  {
    id: 7,
    icon: templatesIconURL,
    iconActive: templatesActiveIconURL,
    name: 'Templates',
    route: '/templates',
    isActive: false,
  },
  {
    id: 8,
    icon: priceListIconURL,
    iconActive: priceListActiveIconURL,
    name: 'Price List',
    route: '/priceList',
    isActive: false,
  },
  {
    id: 9,
    icon: priceListIconURL,
    iconActive: priceListActiveIconURL,
    name: 'Price Contracts',
    route: '/price-contracts',
    isActive: false,
  },
  {
    id: 10,
    icon: masterIconURL,
    iconActive: masterActiveIconURL,
    name: 'Master',
    route: '/master',
    isActive: false,
  },
  {
    id: 11,
    icon: setupIconURL,
    iconActive: setupWhiteIconURL,
    name: 'Setup',
    route: '/setup',
    isActive: false,
  },
  {
    id: 12,
    icon: reportsIcon,
    iconActive: reportsIconActive,
    name: 'Reports',
    route: '/reports',
    isActive: false,
  },
];

export const ANALYTICS_NAVIGATION_LINKS = [
  {
    id: 114,
    icon: SidebarAnalytics,
    iconActive: SidebarAnalytics,
    name: 'Food Cost',
    hasSubMenus: true,
    openSubMenus: true,
    isActive: false,
    route: '/analytics',
    isOpen: true,
    subMenus: [
      {
        name: 'COGS Summary',
        route: '/analytics',
      },
      {
        name: 'COGS Pattern',
        route: '/cogs-pattern',
      },
      {
        name: 'COGS by Account',
        route: '/cogs-by-account',
      },
      {
        name: 'COGS by Category',
        route: '/cogs-by-category',
      },
    ],
  },
];
