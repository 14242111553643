import React, { useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import CustomSearch from 'modules/shared/components/search';
import CustomIconButton from 'modules/shared/components/icon-button';
import ordersIconURL from 'assets/icons/orders.svg';
import orderReleaseIconURL from 'assets/icons/order-released.svg';
import orderCancelledIconURL from 'assets/icons/order-cancelled.svg';
import exportIconURL from 'assets/icons/export.svg';
import { Axios } from 'core/services/http';
import Pagin from './components/pagination';
import { Select, SIZE } from 'baseui/select';
import { dateFilters, dates, pageSizeOption } from 'modules/shared/constant';
import FilterList from './components/search';
import { AppContext } from 'context';
import { UserRole } from 'modules/shared/enums/user-role.enum';
import { useNavigate } from 'react-router-dom';
import exportAllIconUrl from 'assets/icons/export-all.png';
import ReportList from 'modules/shared/components/report-list';
import moment from 'moment';

const BREADCRUMB_ITEMS = [
  { name: 'Reports', route: '/reports' },
  { name: 'Requisition List', route: '/reports/requisitionListReport' },
];
const REPORT_LIST_HEADERS = [
  'Order No',
  'Supplier',
  'Store',
  'Order Date',
  'Supply Date',
  'Order Value',
  'Received Value',
  'PR No.',
];

const InvoiceListReport = () => {
  const [ordersList, setOrdersList] = React.useState<Array<any>>([]);
  const [stores, setStores] = React.useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totolCount, setTotalCount] = React.useState(0);
  const [numPages, setNumPages] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<any>([{ label: 10, id: '1' }]);
  const [startDate, setStartDate] = React.useState<any>([new Date()]);
  const [endDate, setEndDate] = React.useState<any>([new Date()]);
  const [dateOption, setDateOption] = React.useState<any>([]);
  const [dateFilter, setDateFilter] = React.useState<any>([]);
  const [store, setStore] = React.useState<any>([]);
  const [orderStateKey, setOrderStateKey] = React.useState('0');
  const [searchValue, setSearchValue] = React.useState('');
  const [css] = useStyletron();
  const axios = new Axios().getInstance();
  const navigate = useNavigate();

  const {
    state: {
      user: { role },
    },
  } = React.useContext(AppContext);
  const isUserByRole = role === UserRole.User;

  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const actionsWrapper = css({
    padding: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '-10px',
    gap: '10px',
  });
  const searchWrapper = css({ width: '400px' });
  const customButtonWrapper = css({
    marginRight: '8px',
    marginBottom: '8px',
  });
  const listWrapper = css({
    padding: '12px',
    '@media (max-width: 540px)': {
      overflow: 'scroll',
    },
  });

  const getOrderLists = async () => {
    try {
      const response = await axios.get('/orders', {
        params: {
          period: {
            label: dateOption.length > 0 ? dateOption[0]?.id : 'today',
            startDate: startDate[0],
            endDate: endDate[0],
          },
          orderStateKey,
          filterBy: dateFilter.length > 0 ? dateFilter[0]?.id : 'CreatedAt',
          store: store.length > 0 ? store[0]?.id : '',
          pageSize: pageSize[0]?.label,
          pageNumber: currentPage,
        },
      });
      if (response?.data?.success) {
        setOrdersList(response?.data?.data?.items);
        setNumPages(response?.data?.data?.meta?.totalPages);
        setTotalCount(response?.data?.data?.meta?.total);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const onSubmit = () => {
    getOrderLists();
  };

  function exportToCSV() {
    if (ordersList.length! > 0) return;
    const headers = Object.keys(ordersList[0]).join(',');
    const rows = ordersList.map((row) => Object.values(row).join(','));
    const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows.join(
      '\n'
    )}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Order List.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const getStores = async () => {
    try {
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        const stores = response.data.data.map((store: any) => ({
          id: store.id,
          label: store.name,
        }));
        setStores(stores);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  useEffect(() => {
    getStores();
    getOrderLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStateKey, currentPage, pageSize]);

  const listToRender = () => {
    return ordersList.map((item) => {
      return [
        item.orderNumber,
        `(${item.supplierName})`,
        `${item.storeName}(${item.storePhone})`,
        item.date,
        moment(item?.SupplyDate).format('MM/DD/YY'),
        0,
        0,
        `(${item.prNumber})`,
      ];
    });
  };

  return (
    <div className={css({ marginTop: '70px' })}>
      <div className={breadcrumbWrapper}>
        <img className={iconStyles} src={ordersIconURL} alt="Order" />
        <Breadcrumb items={BREADCRUMB_ITEMS} />
      </div>
      <div className={listWrapper}>
        <FilterList
          dates={dates}
          dateFilters={dateFilters}
          stores={stores}
          store={store}
          startDate={startDate}
          endDate={endDate}
          dateOption={dateOption}
          dateFilter={dateFilter}
          setStore={setStore}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setDateOption={setDateOption}
          setDateFilter={setDateFilter}
          onSubmit={onSubmit}
        />
      </div>
      <div className={actionsWrapper}>
        <div className={searchWrapper}>
          <CustomSearch
            placeholder="Table search..."
            buttonName="Search"
            searchClicked={() => {}}
            inputChange={(evenet) => setSearchValue(evenet.target.value)}
            inputValue={searchValue}
            setSearchValue={() => setSearchValue('')}
          />
        </div>
        <div style={{ display: 'flex' }}>
          {!isUserByRole && (
            <div className={customButtonWrapper}>
              <CustomIconButton
                icon={exportAllIconUrl}
                name="Export All"
                onClick={() => {}}
              />
            </div>
          )}
          {!isUserByRole && (
            <div className={customButtonWrapper}>
              <CustomIconButton
                icon={exportIconURL}
                name="Export Current List"
                onClick={exportToCSV}
              />
            </div>
          )}
        </div>
      </div>
      <div className={listWrapper}>
        <ReportList
          reportHeader={REPORT_LIST_HEADERS}
          reportData={listToRender()}
        />
      </div>
      <div className={listWrapper}>
        <Pagin
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numPages={numPages}
          pageSize={pageSize}
        />
        <div className={css({ width: '100px', marginTop: '10px' })}>
          <Select
            size={SIZE.mini}
            options={pageSizeOption}
            value={pageSize}
            placeholder="10"
            onChange={(params) => setPageSize(params.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceListReport;
