import { Pagination } from 'baseui/pagination';

interface IProps {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  numPages: number;
  getOrderLists: any;
}

const Pagin = ({
  currentPage,
  setCurrentPage,
  numPages,
  getOrderLists,
}: IProps) => {
  const handlePageChange = (nextPage: any) => {
    setCurrentPage(nextPage);
    getOrderLists(nextPage);
  };
  return (
    <Pagination
      numPages={numPages}
      currentPage={currentPage}
      onPageChange={({ nextPage }) => {
        handlePageChange(Math.min(Math.max(nextPage, 1), numPages));
      }}
    />
  );
};

export default Pagin;
