// import React, { useState, useRef, useEffect, useCallback } from 'react';
// import './index.scss';
// import { useStyletron } from 'styletron-react';

// interface Option {
//   id: string;
//   label: string;
// }

// interface SelectWithCheckboxesProps {
//   options: Option[];
//   store: Option[];
//   disabled: boolean;
//   name?: string;
//   handleChange: (store: Option[]) => void;
//   isChanged?: boolean;
//   isFullWidth?: boolean;
// }

// const SelectWithCheckboxes: React.FC<SelectWithCheckboxesProps> = ({
//   options,
//   store,
//   disabled,
//   name = 'Store',
//   handleChange,
//   isChanged = false,
//   isFullWidth = false,
// }) => {
//   const [showOptions, setShowOptions] = useState(false);
//   const selectRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (
//         selectRef.current &&
//         !selectRef.current.contains(event.target as Node)
//       ) {
//         setShowOptions(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [selectRef]);

//   const handleOptionClick = useCallback(
//     (option: Option) => {
//       const index = store.findIndex(
//         (selectedOption) => selectedOption.id === option.id
//       );

//       if (index === -1) {
//         handleChange([...store, option]);
//       } else {
//         handleChange([...store.slice(0, index), ...store.slice(index + 1)]);
//       }
//     },
//     [store, handleChange]
//   );

//   const handleSelectAll = useCallback(() => {
//     if (store.length === options.length) {
//       handleChange([]);
//     } else {
//       handleChange(options);
//     }
//   }, [options, store, handleChange]);

//   const selectedOptionsText = () => {
//     const numSelected = disabled ? options.length : store.length;
//     return `${numSelected} ${name}${numSelected === 1 ? '' : 's'} Selected`;
//   };
//   const [css] = useStyletron();
//   return (
//     <div
//       className={`custom-select ${disabled ? 'disabled' : ''}
//       ${isChanged ? 'changed' : ''}`}
//       ref={selectRef}
//       style={{
//         maxWidth: '100%',
//         width: isFullWidth ? '100%' : 'auto',
//       }}
//     >
//       <div
//         className="select-trigger"
//         onClick={() => {
//           !disabled && setShowOptions(!showOptions);
//         }}
//       >
//         <span>{selectedOptionsText()}</span>
//         <span>&#x25BC;</span>
//       </div>
//       {showOptions && (
//         <div className="options">
//           <div className="option select-all" key="select-all">
//             <input
//               type="checkbox"
//               id="select-all"
//               checked={store.length === options.length}
//               onChange={handleSelectAll}
//               className={css({
//                 border: '2px solid #eee',
//                 backgroundColor: '#fff',
//                 borderRadius: '0.5rem',
//               })}
//             />
//             <label htmlFor="select-all">Select All</label>
//           </div>
//           {options.map((option) => (
//             <div className="option" key={option.id}>
//               <input
//                 type="checkbox"
//                 id={option.id}
//                 checked={store.some(
//                   (selectedOption) => selectedOption.id === option.id
//                 )}
//                 className={css({
//                   border: '2px solid #eee',
//                   backgroundColor: '#fff',
//                   borderRadius: '0.5rem',
//                 })}
//                 onChange={() => handleOptionClick(option)}
//               />
//               <label htmlFor={option.id}>{option.label}</label>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default SelectWithCheckboxes;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import './index.scss';
import { useStyletron } from 'styletron-react';

interface Option {
  id: string;
  label: string;
}

interface SelectWithCheckboxesProps {
  options: Option[];
  store: Option[] | Option | null;
  disabled: boolean;
  name?: string;
  handleChange: (store: Option[] | Option) => void;
  isChanged?: boolean;
  isFullWidth?: boolean;
  isSingleSelect?: boolean; // New prop to control single selection
}

const SelectWithCheckboxes: React.FC<SelectWithCheckboxesProps> = ({
  options,
  store,
  disabled,
  name = 'Store',
  handleChange,
  isChanged = false,
  isFullWidth = false,
  isSingleSelect = false,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const [css] = useStyletron();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const handleOptionClick = useCallback(
    (option: Option) => {
      if (isSingleSelect) {
        handleChange(option); // Single selection: set the selected option
      } else {
        const currentStore = store as Option[];
        const index = currentStore.findIndex(
          (selectedOption) => selectedOption.id === option.id
        );
        if (index === -1) {
          handleChange([...currentStore, option]);
        } else {
          handleChange([
            ...currentStore.slice(0, index),
            ...currentStore.slice(index + 1),
          ]);
        }
      }
    },
    [store, handleChange, isSingleSelect]
  );

  const handleSelectAll = useCallback(() => {
    if (!isSingleSelect) {
      const currentStore = store as Option[];
      if (currentStore.length === options.length) {
        handleChange([]);
      } else {
        handleChange(options);
      }
    }
  }, [options, store, handleChange, isSingleSelect]);

  const selectedOptionsText = () => {
    if (isSingleSelect) {
      const selected = store as any | null;

      return selected ? selected.label || selected[0].label : `Select ${name}`;
    } else {
      const numSelected = disabled
        ? options.length
        : (store as Option[]).length;
      return `${numSelected} ${name}${numSelected === 1 ? '' : 's'} Selected`;
    }
  };

  return (
    <div
      className={`custom-select ${disabled ? 'disabled' : ''} ${
        isChanged ? 'changed' : ''
      }`}
      ref={selectRef}
      style={{
        maxWidth: '100%',
        width: isFullWidth ? '100%' : 'auto',
      }}
    >
      <div
        className="select-trigger"
        onClick={() => {
          !disabled && setShowOptions(!showOptions);
        }}
      >
        <span>{selectedOptionsText()}</span>
        <span>&#x25BC;</span>
      </div>

      {showOptions && (
        <div className="options">
          {!isSingleSelect && (
            <div className="option select-all" key="select-all">
              <input
                type="checkbox"
                id="select-all"
                checked={(store as Option[]).length === options.length}
                onChange={handleSelectAll}
                className={css({
                  border: '2px solid #eee',
                  backgroundColor: '#fff',
                  borderRadius: '0.5rem',
                })}
              />
              <label htmlFor="select-all">Select All</label>
            </div>
          )}
          {options.map((option) => (
            <div className="option" key={option.id}>
              <input
                type={isSingleSelect ? 'checkbox' : 'checkbox'}
                id={option.id}
                name={isSingleSelect ? 'single-select' : undefined}
                checked={
                  isSingleSelect
                    ? (store as Option | null)?.id === option.id
                    : (store as Option[]).some(
                        (selectedOption) => selectedOption.id === option.id
                      )
                }
                onChange={() => handleOptionClick(option)}
                className={css({
                  border: '2px solid #eee',
                  backgroundColor: '#fff',
                  borderRadius: '0.5rem',
                })}
              />
              <label htmlFor={option.id}>{option.label}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectWithCheckboxes;
