import { useState } from 'react';

const useLoader = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isPrinting, setPrinting] = useState<boolean>(false);
  const triggerLoading = () => {
    setLoading(true);
  };
  const cancelLoading = () => {
    setLoading(false);
  };
  const printing = () => {
    setPrinting(true);
  };
  const stopPrinting = () => {
    setPrinting(false);
  };
  return {
    triggerLoading,
    cancelLoading,
    loading: isLoading,
    isPrinting,
    printing,
    stopPrinting,
  };
};

export default useLoader;
