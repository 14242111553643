import React, { useEffect } from 'react';
import { v4 as uuidv4, v4 } from 'uuid';
import priceListIconURL from 'assets/icons/price-list.svg';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import { useStyletron } from 'styletron-react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';
import { DatePicker } from 'baseui/datepicker';
import { Select } from 'baseui/select';
import { Input, SIZE } from 'baseui/input';
import { useNavigate, useParams } from 'react-router-dom';
import { Axios } from 'core/services/http';
import moment from 'moment';
import { toaster } from 'baseui/toast';
import { Spinner } from 'baseui/icon';
import CustomIconButton from 'modules/shared/components/icon-button';
import addIconUrl from 'assets/icons/create.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import editIconUrl from 'assets/icons/edit.svg';
import importIconURL from 'assets/icons/import.svg';
import exportIconURL from 'assets/icons/export.svg';
import submitIconURL from 'assets/icons/pr-submitted.svg';
import { StatefulTooltip } from 'baseui/tooltip';
import { Block } from 'baseui/block';
import NumberInput from 'modules/shared/components/numberInput';
import colors from 'constants/colors';
import ExclusionModal from './ExclusionModal';
import ImportPriceContractsItems from '../import';
import * as xlsx from 'xlsx';

const BREADCRUMB_ITEMS = [
  { name: 'Price Contracts', route: '/price-contracts' },
  { name: 'Edit Price Contract', route: '' },
];

interface ContractItem {
  _id: string;
  vendorProductId: {
    id: string;
    label: string;
    supplierProductCode: string;
    supplierProductName: string;
    productPackagingUnit: string;
  };
  priceWoVAT: number;
  isActive: boolean;
  defaultVendor: boolean;
  excludedStoreList: Array<any>;
  specialEmailAnyStore: boolean;
  specialEmailSpecificStoreList: Array<any>;
  hideInRequisition: boolean;
}

const EditPriceContract = () => {
  const { id } = useParams();

  const [css] = useStyletron();
  const navigate = useNavigate();

  const axios = new Axios().getInstance();

  const [priceContract, setPriceContract] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);

  //backend data
  const [vendors, setVendors] = React.useState<Array<any>>([]);
  const [brands, setBrands] = React.useState<Array<any>>([]);
  const [stores, setStores] = React.useState<Array<any>>([]);
  const [products, setProducts] = React.useState<Array<any>>([]);

  //frontend data for create price contract
  const [supplier, setSupplier] = React.useState<any>([]);
  const [isOpenContract, setIsOpenContract] = React.useState(true);
  const [startDate, setStartDate] = React.useState<any>([]);
  const [endDate, setEndDate] = React.useState<any>([]);
  const [validForAllBrands, setValidForAllBrands] = React.useState(true);
  const [includedBrandList, setIncludedBrandList] = React.useState([]);
  const [excludedBrandList, setExcludedBrandList] = React.useState([]);
  const [validForAllStores, setValidForAllStores] = React.useState(true);
  const [includedStoreList, setIncludedStoreList] = React.useState([]);
  const [excludedStoreList, setExcludedStoreList] = React.useState([]);

  const [exclusionsModalOpen, setExclusionsModalOpen] = React.useState(false);
  const [selectedContractItem, setSelectedContractItem] = React.useState<any>();

  const [importModalOpen, setImportModalOpen] = React.useState(false);

  const [isNewItemShown, setIsNewItemShown] = React.useState<boolean>(false);
  const [newContractItem, setNewContractItem] = React.useState<ContractItem>({
    _id: v4(),
    vendorProductId: {
      id: uuidv4(),
      label: '',
      supplierProductCode: '',
      supplierProductName: '',
      productPackagingUnit: '',
    },
    priceWoVAT: 0,
    isActive: true,
    defaultVendor: false,
    excludedStoreList: [],
    specialEmailAnyStore: false,
    specialEmailSpecificStoreList: [],
    hideInRequisition: false,
  });
  const [contractItems, setContractItems] = React.useState<Array<ContractItem>>(
    [
      {
        _id: v4(),
        vendorProductId: {
          id: uuidv4(),
          label: '',
          supplierProductCode: '',
          supplierProductName: '',
          productPackagingUnit: '',
        },
        priceWoVAT: 0,
        isActive: true,
        defaultVendor: false,
        excludedStoreList: [],
        specialEmailAnyStore: false,
        specialEmailSpecificStoreList: [],
        hideInRequisition: false,
      },
    ]
  );

  const getVendors = async () => {
    try {
      const response = await axios.get('/vendors?all=true');
      if (response?.data?.success && response?.data?.data?.products) {
        setVendors(
          response?.data?.data?.products?.filter(
            (data: any) => data.deleted === false
          )
        );
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getStores = async () => {
    try {
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        setStores(response.data.data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const BrandStatusEnum = {
    Active: 'active',
    Archived: 'archived',
  };

  const getBrands = async () => {
    try {
      const response = await axios.get('/brands?status=active', {
        params: {
          status: BrandStatusEnum.Active,
        },
      });
      if (response?.data?.success) {
        setBrands(response.data.data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };
  const getProducts = async () => {
    try {
      const response = await axios.get('/supplierProducts', {
        params: {
          all: true,
        },
      });
      if (response?.data?.success) {
        setProducts(
          response.data?.data?.products?.filter(
            (data: any) => data.statusActive === true
          )
        );
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const buttonWrapper = css({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  });
  const flexGridItemClass = css({
    width: 'fit-content !important',
    '@media (max-width: 540px)': {
      width: '100%',
      marginRight: 'auto',
    },
  });
  const flexGridSmallItemClass = css({
    '@media (min-width: 541px)': {
      width: 'calc(((100% - 120px) / 12) - 0.5px) !important',
      marginRight: '12px !important',
    },
  });

  const newRequistionWrapper = css({
    display: 'grid',
    gridTemplateColumns: '10% 20% 15% 8% 5% 15% 10% 10% 3%',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 8px',
    minWidth: '900px',
  });
  const newRequisitionHeader = css({
    display: 'grid',
    gridTemplateColumns: '10% 20% 15% 8% 5% 15% 10% 10% 3%',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 6px',

    backgroundColor: '#063360',
    color: 'white',
    fontWeight: 700,
    fontSize: '16px',
  });
  const addWrapper = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  });
  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });

  const cancelButtonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.red,
    boxShadow: '0px 8px 20px rgba(255, 0, 0, 0.2)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });
  const topLabelWrapper = css({
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  });
  const topLabel = css({
    fontSize: '18px',
    fontWeight: '500',
  });
  const topLabelValue = css({
    fontSize: '17px',
    fontFamily: 'poppins-regular',
  });

  const getVendorOptions = () => {
    return vendors.map((vendor: any) => ({
      id: vendor.id,
      label: vendor.name,
    }));
  };

  const getStoreOptions = () => {
    const storeOptions = stores.map((store: any) => ({
      id: store.id,
      label: store.name,
      brand: store.brand,
    }));

    const filteredStoreOptions = storeOptions.filter((storeOption) => {
      //if valid for all brands is true
      if (validForAllBrands) {
        //we check if the exluded brand list is empty then we return all stores
        if (excludedBrandList.length === 0) {
          return true;
        }
        //we return the stores that are not included in the excluded brand list
        const isExcluded = excludedBrandList.find(
          ({ id }: any) =>
            id === storeOption.brand?._id || id === storeOption.brand
        );
        return !isExcluded;
      } else {
        //we return the stores that are included in the included brand list
        const isIncluded = includedBrandList.find(
          ({ id }: any) =>
            id === storeOption.brand?._id || id === storeOption.brand
        );
        return isIncluded;
      }
    });

    return filteredStoreOptions;
  };

  const getQualifyingStoreOptions = () => {
    //if valid for all stores is true then we need to return all stores except excluded stores, if valid for all stores is false then we need to return included stores
    const options = getStoreOptions();
    if (validForAllStores) {
      return options.filter(
        (store: any) =>
          !excludedStoreList.find(({ id }: any) => id === store.id)
      );
    }
    return includedStoreList;
  };

  const updateContractItemsAccordingToStore = () => {
    const updatedItems = contractItems.map((item) => {
      const excludedStoreList = item.excludedStoreList.filter((store: any) =>
        getQualifyingStoreOptions().find(({ id }: any) => id === store.id)
      );
      const specialEmailSpecificStoreList =
        item.specialEmailSpecificStoreList.filter((store: any) =>
          getQualifyingStoreOptions().find(({ id }: any) => id === store.id)
        );
      return {
        ...item,
        excludedStoreList,
        specialEmailSpecificStoreList,
      };
    });
    setContractItems(updatedItems);
  };

  //when valid for all stores, included stores, excluded stores are changed we need to update the contract items excluded store list and special email specific store list
  useEffect(() => {
    updateContractItemsAccordingToStore();
    // eslint-disable-next-line
  }, [validForAllStores, includedStoreList, excludedStoreList]);

  const getBrandOptions = () => {
    return brands.map((brand: any) => ({
      id: brand.id,
      label: brand.name,
    }));
  };

  const getProductOptions = () => {
    return products.map((product: any) => ({
      id: product.id,
      label: `${product?.supplierProductCode}-${product?.supplierProductName}-${product?.packagingUnit?.name}`,
      supplierProductCode: product?.supplierProductCode,
      supplierProductName: product?.supplierProductName,
      productPackagingUnit: product?.packagingUnit?.name,
    }));
  };

  const getPriceContract = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/priceContracts/${id}`);
      const contractData = response.data.data;
      setPriceContract(contractData);
      setSupplier([
        {
          id: contractData?.vendorId?._id,
          label: contractData?.vendorId?.name,
        },
      ]);
      setIsOpenContract(contractData?.isOpenContract);
      setStartDate(
        contractData?.contractStartDate
          ? [new Date(contractData.contractStartDate)]
          : []
      );
      setEndDate(
        contractData?.contractEndDate
          ? [new Date(contractData.contractEndDate)]
          : []
      );
      setValidForAllBrands(contractData.validForAllBrands);
      setIncludedBrandList(
        contractData?.includedBrandList.map((brand: any) => ({
          id: brand?._id,
          label: brand?.name,
        }))
      );
      setExcludedBrandList(
        contractData?.excludedBrandList.map((brand: any) => ({
          id: brand?._id,
          label: brand?.name,
        }))
      );
      setValidForAllStores(contractData?.validForAllStores);
      setIncludedStoreList(
        contractData?.includedStoreList.map((store: any) => ({
          id: store?._id,
          label: store?.name,
          brand: store?.brand?._id,
        }))
      );
      setExcludedStoreList(
        contractData?.excludedStoreList.map((store: any) => ({
          id: store?._id,
          label: store?.name,
          brand: store?.brand?._id,
        }))
      );
      const updatedContractItems = contractData?.contractItems.map(
        (item: any) => {
          return {
            _id: item?._id,
            vendorProductId: {
              id: item?.vendorProductId?._id,
              label: `${item?.vendorProductId?.supplierProductCode}-${item?.vendorProductId?.supplierProductName}-${item?.vendorProductId?.productPackagingUnit?.name}`,
              supplierProductCode: item?.vendorProductId?.supplierProductCode,
              supplierProductName: item?.vendorProductId?.supplierProductName,
              productPackagingUnit:
                item?.vendorProductId?.productPackagingUnit?.name,
            },
            priceWoVAT: item?.priceWoVAT,
            isActive: item?.isActive,
            defaultVendor: item?.defaultVendor,
            excludedStoreList: item?.excludedStoreList.map((store: any) => ({
              id: store?._id,
              label: store?.name,
              brand: store?.brand?._id,
            })),
            specialEmailAnyStore: item?.specialEmailAnyStore,
            //if special email any store is true then we need to set special email specific store list to all stores
            specialEmailSpecificStoreList: item?.specialEmailAnyStore
              ? getStoreOptions()
              : item?.specialEmailSpecificStoreList.map((store: any) => ({
                  id: store?._id,
                  label: store?.name,
                  brand: store?.brand?._id,
                })),
            hideInRequisition: item?.hideInRequisition,
          };
        }
      );
      setContractItems(updatedContractItems);
      //if status is not draft then we need to navigate to view page
      if (contractData?.status !== 'Draft') {
        navigate(`/price-contracts/view/${id}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVendors();
    getStores();
    getBrands();
    getProducts();
    if (id) {
      getPriceContract();
    }

    // eslint-disable-next-line
  }, [id]);

  const addNewContractItemHandler = () => {
    if (
      !newContractItem.vendorProductId?.label ||
      !newContractItem.priceWoVAT
    ) {
      toaster.negative('Product and Price w/o VAT is required', {
        autoHideDuration: 4000,
      });
      return;
    }
    //we need to check if the contract item already exists in the contract items
    const isAlreadyExists = contractItems.find(
      (item) => item.vendorProductId.id === newContractItem.vendorProductId.id
    );
    if (isAlreadyExists) {
      //we need to update the existing contract item
      const updatedItems = contractItems.map((item) => {
        if (item.vendorProductId.id === newContractItem.vendorProductId.id) {
          return newContractItem;
        }
        return item;
      });
      setContractItems(updatedItems);
      setNewContractItem({
        _id: v4(),
        vendorProductId: {
          id: uuidv4(),
          label: '',
          supplierProductCode: '',
          supplierProductName: '',
          productPackagingUnit: '',
        },
        priceWoVAT: 0,
        isActive: true,
        defaultVendor: false,
        excludedStoreList: [],
        specialEmailAnyStore: false,
        specialEmailSpecificStoreList: [],
        hideInRequisition: false,
      });
      setIsNewItemShown(false);
      return;
    }
    setContractItems((prevItems) => [...prevItems, newContractItem]);
    setNewContractItem({
      _id: v4(),
      vendorProductId: {
        id: uuidv4(),
        label: '',
        supplierProductCode: '',
        supplierProductName: '',
        productPackagingUnit: '',
      },
      priceWoVAT: 0,
      isActive: true,
      defaultVendor: false,
      excludedStoreList: [],
      specialEmailAnyStore: false,
      specialEmailSpecificStoreList: [],
      hideInRequisition: false,
    });
    setIsNewItemShown(false);
  };

  const updatePriceContractHandler = async () => {
    const data = {
      priceContractId: id,
      vendorId: supplier[0]?.id,
      contractStartDate: startDate.length
        ? moment(startDate[0]).format('YYYY-MM-DD')
        : null,
      contractEndDate: endDate.length
        ? moment(endDate[0]).format('YYYY-MM-DD')
        : null,
      isOpenContract,
      validForAllBrands,
      includedBrandList: validForAllBrands
        ? []
        : includedBrandList.map((brand: any) => brand.id),
      excludedBrandList: !validForAllBrands
        ? []
        : excludedBrandList.map((brand: any) => brand.id),
      validForAllStores,
      includedStoreList: validForAllStores
        ? []
        : includedStoreList.map((store: any) => store.id),
      excludedStoreList: !validForAllStores
        ? []
        : excludedStoreList.map((store: any) => store.id),
      contractItems: contractItems.map((item) => {
        return {
          vendorProductId: item.vendorProductId.id,
          priceWoVAT: item.priceWoVAT,
          isActive: item.isActive,
          defaultVendor: item.defaultVendor,
          excludedStoreList: item.excludedStoreList.map(
            (store: any) => store.id
          ),
          //if the specialEmailSpecificStoreList and getStoreOptions are equal then we need to set specialEmailAnyStore to true
          specialEmailAnyStore:
            item.specialEmailSpecificStoreList.length ===
            getQualifyingStoreOptions().length
              ? true
              : false,
          specialEmailSpecificStoreList:
            item.specialEmailSpecificStoreList.length ===
            getQualifyingStoreOptions().length
              ? []
              : item.specialEmailSpecificStoreList.map(
                  (store: any) => store.id
                ),
          hideInRequisition: item.hideInRequisition,
        };
      }),
    };

    try {
      const response = await axios.put(`/priceContracts/update`, data);
      if (response.data.success) {
        console.log('Price Contract Updated Successfully');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteContractItemHandler = (item: ContractItem) => {
    const filteredItems = contractItems.filter(
      (contractItem) => contractItem._id !== item._id
    );

    setContractItems(filteredItems);
  };

  const submitPriceContractHandler = async () => {
    //we need to first update the price contract
    await updatePriceContractHandler();
    try {
      const response = await axios.patch(`/priceContracts/submit/${id}`);
      if (response.data.success) {
        toaster.positive('Price Contract Submitted Successfully', {
          autoHideDuration: 4000,
        });
        navigate('/price-contracts');
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          toaster.negative(message, {
            autoHideDuration: 4000,
          });
        });
      }
    }
  };

  const updateContratItemHandler = (id: string, key: string, value: any) => {
    const updatedItems = contractItems.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });
    setContractItems(updatedItems);
  };

  const exclusionChangeHandler = (key: string, value: any) => {
    if (selectedContractItem._id !== newContractItem._id) {
      const updatedItems = contractItems.map((item) => {
        if (item._id === selectedContractItem._id) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });
      setContractItems(updatedItems);
      setSelectedContractItem((prevItem: any) => ({
        ...prevItem,
        [key]: value,
      }));
      return;
    }
    setNewContractItem((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
    setSelectedContractItem((prevItem: any) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  //we need to update the contract in backend when ever contract headers are changed or when ever contract items are changed
  useEffect(() => {
    if (id) {
      updatePriceContractHandler();
    }
    // eslint-disable-next-line
  }, [
    supplier,
    startDate,
    endDate,
    validForAllBrands,
    includedBrandList,
    excludedBrandList,
    validForAllStores,
    includedStoreList,
    excludedStoreList,
    contractItems,
  ]);

  const importPriceContractItemsHandler = async (data: any) => {
    let existingItemsIds = [] as Array<string>;
    const updatedItems = data.map((item: any) => {
      const isAlreadyExists = contractItems.find(
        (contractItem) =>
          contractItem.vendorProductId.id === item.vendorProductId.id
      );
      if (isAlreadyExists) {
        existingItemsIds.push(isAlreadyExists._id);
        const excludedStoreList = getQualifyingStoreOptions().filter(
          (store: any) =>
            item.excludedStoreList.find(
              (excludedStore: any) => excludedStore.id === store.id
            )
        );
        return {
          ...isAlreadyExists,
          priceWoVAT: item.priceWoVAT,
          isActive: item.isActive,
          defaultVendor: item.defaultVendor,
          excludedStoreList,
          specialEmailAnyStore: item.specialEmailAnyStore,
          specialEmailSpecificStoreList: item.specialEmailAnyStore
            ? getQualifyingStoreOptions().map((store: any) => store.id)
            : item.specialEmailSpecificStoreList,
          hideInRequisition: item.hideInRequisition,
        };
      }
      return {
        ...item,
        specialEmailSpecificStoreList: item.specialEmailAnyStore
          ? getQualifyingStoreOptions().map((store: any) => store.id)
          : item.specialEmailSpecificStoreList,
        excludedStoreList: getQualifyingStoreOptions().filter((store: any) =>
          item.excludedStoreList.find(
            (excludedStore: any) => excludedStore.id === store.id
          )
        ),
      };
    });
    //we need to filter out the existing items from the contract items
    const updatedContractItems = contractItems.filter(
      (item) => !existingItemsIds.includes(item._id)
    );
    setContractItems([...updatedContractItems, ...updatedItems]);
    setImportModalOpen(false);
  };

  const getIsSpecialEmailAnyStore = (item: ContractItem) => {
    return item.specialEmailSpecificStoreList.length ===
      getQualifyingStoreOptions().length
      ? true
      : false;
  };

  const exportContractItemsHandler = async () => {
    let dataToExport: Array<any> = [];
    try {
      contractItems.forEach((contractItem: ContractItem) => {
        const obj: any = {
          'Product Code': contractItem?.vendorProductId?.supplierProductCode,
          'Product Name': contractItem?.vendorProductId?.supplierProductName,
          'Product Packaging Unit':
            contractItem?.vendorProductId?.productPackagingUnit,
          'Price without VAT': contractItem?.priceWoVAT,
          Active: contractItem?.isActive ? 'TRUE' : 'FALSE',
          'Special Email All Stores': getIsSpecialEmailAnyStore(contractItem)
            ? 'TRUE'
            : 'FALSE',
          'Special Email Stores': getIsSpecialEmailAnyStore(contractItem)
            ? '-'
            : contractItem?.specialEmailSpecificStoreList
                .map((store: any) => store?.label)
                .join(', '),
          'Hide in Requisition': contractItem?.hideInRequisition
            ? 'TRUE'
            : 'FALSE',
          'Excluded Stores': contractItem?.excludedStoreList
            .map((store: any) => store?.label)
            .join(', '),
        };
        dataToExport.push(obj);
      });
      const worksheet = xlsx.utils.json_to_sheet(dataToExport);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Price Contract Items');
      xlsx.writeFile(workbook, 'price_contract_items.xlsx');
    } catch (err: any) {
      toaster.negative(err.message, {
        autoHideDuration: 4000,
      });
    }
  };

  return (
    <>
      {!loading && (
        <div className={css({ marginTop: '70px', padding: '10px' })}>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            })}
          >
            <div className={breadcrumbWrapper}>
              <img
                className={iconStyles}
                src={priceListIconURL}
                alt="Price Contract"
              />
              <Breadcrumb items={BREADCRUMB_ITEMS} />
            </div>
            <div
              className={topLabelWrapper}
              style={{
                marginRight: '20px',
              }}
            >
              <p className={topLabel}>Status:</p>
              <span className={topLabelValue}>{priceContract?.status}</span>
            </div>
          </div>
          <div>
            <div>
              <FlexGrid
                flexGridColumnCount={[1, 1, 6]}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
              >
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Contract Code</div>
                    <Input
                      value={priceContract?.contractCode}
                      disabled
                      placeholder="Contract Code"
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Supplier Name</div>
                    <Select
                      options={getVendorOptions()}
                      value={supplier}
                      placeholder="Select Supplier"
                      onChange={(params: any) => setSupplier(params.value)}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Contract Start Date</div>
                    <DatePicker
                      value={startDate}
                      onChange={({ date }) =>
                        setStartDate(Array.isArray(date) ? date : [date])
                      }
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 999 },
                              },
                            },
                          },
                        },
                        MonthYearSelectPopover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 9999 },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Contract End Date</div>
                    <DatePicker
                      value={endDate}
                      onChange={({ date }) => {
                        setEndDate(Array.isArray(date) ? date : [date]);
                        setIsOpenContract(false);
                      }}
                      minDate={startDate[0]}
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 999 },
                              },
                            },
                          },
                        },
                        MonthYearSelectPopover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 9999 },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridSmallItemClass}>
                  <div className="input-wrapper">
                    <div
                      className="input-label"
                      style={{ marginBottom: '12px' }}
                    >
                      Open Contract
                    </div>
                    <Checkbox
                      checked={isOpenContract}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Toggle: {
                          style: ({ $theme, $checked }) => ({
                            backgroundColor: $checked ? '#09B08C' : '#000',
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setIsOpenContract(e.target.checked);
                        //if contract is open then set end date to empty array
                        if (e.target.checked) {
                          setEndDate([]);
                        }
                      }}
                    />
                  </div>
                </FlexGridItem>
              </FlexGrid>
              <br />
              <FlexGrid
                flexGridColumnCount={[1, 2, 6]}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
              >
                <FlexGridItem className={flexGridSmallItemClass}>
                  <div className="input-wrapper">
                    <div
                      className="input-label"
                      style={{ marginBottom: '12px' }}
                    >
                      All Brands
                    </div>
                    <Checkbox
                      checked={validForAllBrands}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Toggle: {
                          style: ({ $theme, $checked }) => ({
                            backgroundColor: $checked ? '#09B08C' : '#000',
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setIncludedBrandList([]);
                        setExcludedBrandList([]);
                        setValidForAllBrands(e.target.checked);
                      }}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Included Brands</div>
                    <SelectWithCheckboxes
                      //@ts-ignore
                      options={getBrandOptions()}
                      disabled={validForAllBrands}
                      store={includedBrandList}
                      name="Include Brands"
                      handleChange={(params: any) =>
                        setIncludedBrandList(params)
                      }
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Excluded Brands</div>
                    <SelectWithCheckboxes
                      options={getBrandOptions()}
                      disabled={!validForAllBrands}
                      store={excludedBrandList}
                      name="Exclude Brands"
                      handleChange={(params: any) =>
                        setExcludedBrandList(params)
                      }
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridSmallItemClass}>
                  <div className="input-wrapper">
                    <div
                      className="input-label"
                      style={{ marginBottom: '12px' }}
                    >
                      All Stores
                    </div>
                    <Checkbox
                      checked={validForAllStores}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Toggle: {
                          style: ({ $theme, $checked }) => ({
                            backgroundColor: $checked ? '#09B08C' : '#000',
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setIncludedStoreList([]);
                        setExcludedStoreList([]);
                        setValidForAllStores(e.target.checked);
                      }}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Included Stores</div>
                    <SelectWithCheckboxes
                      options={getStoreOptions()}
                      disabled={validForAllStores}
                      store={includedStoreList}
                      handleChange={(params: any) =>
                        setIncludedStoreList(params)
                      }
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Excluded Stores</div>
                    <SelectWithCheckboxes
                      options={getStoreOptions()}
                      disabled={!validForAllStores}
                      store={excludedStoreList}
                      handleChange={(params: any) =>
                        setExcludedStoreList(params)
                      }
                    />
                  </div>
                </FlexGridItem>
              </FlexGrid>
            </div>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '20px 0',
                borderTop: '1px solid #e5e5e5',
                paddingTop: '20px',
              })}
            >
              <div className={buttonWrapper}>
                <CustomIconButton
                  icon={addIconUrl}
                  name="Add line Item"
                  onClick={() => setIsNewItemShown((prevValue) => !prevValue)}
                />
                <CustomIconButton
                  icon={importIconURL}
                  name="Import Items"
                  onClick={() => setImportModalOpen(true)}
                />
                <CustomIconButton
                  icon={exportIconURL}
                  name="Export Items"
                  onClick={exportContractItemsHandler}
                />
                <CustomIconButton
                  icon={submitIconURL}
                  name="Submit Price Contract"
                  onClick={submitPriceContractHandler}
                />
                <CustomIconButton
                  icon={editIconUrl}
                  name="Save Draft"
                  onClick={() => {
                    updatePriceContractHandler();
                    toaster.positive('Price Contract Saved Successfully', {
                      autoHideDuration: 4000,
                    });
                    navigate('/price-contracts');
                  }}
                />
              </div>
            </div>
            <div>
              <div className={`${newRequisitionHeader}`}>
                <span>Product Code</span>
                <span>Product Name</span>
                <span>Product Packaging Unit</span>
                <span>Price w/o VAT</span>
                <span>Active</span>
                <span>Special Email Store</span>
                <span>Hide In Requisition</span>
                <span>Exclusions</span>
                <span></span>
              </div>
              {isNewItemShown && (
                <div
                  style={{
                    marginBottom: '10px',
                    backgroundColor: '#fff',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    borderRadius: '12px',
                    padding: '6px',
                    marginTop: '10px',
                  }}
                >
                  <div
                    className={newRequistionWrapper}
                    style={{ paddingBottom: '10px' }}
                  >
                    <span>
                      <Select
                        positive
                        size={SIZE.compact}
                        placeholder="Select Product"
                        options={getProductOptions()}
                        value={
                          newContractItem.vendorProductId.label === ''
                            ? []
                            : [
                                {
                                  id: '1',
                                  label:
                                    newContractItem.vendorProductId
                                      .supplierProductCode,
                                },
                              ]
                        }
                        onChange={(params: any) =>
                          setNewContractItem({
                            ...newContractItem,
                            vendorProductId: params.value[0],
                          })
                        }
                      />
                    </span>
                    <span>
                      <Input
                        positive
                        size={SIZE.compact}
                        value={
                          newContractItem.vendorProductId.supplierProductName
                        }
                        placeholder="Product Name"
                        readOnly
                      />
                    </span>
                    <span>
                      <Input
                        positive
                        size={SIZE.compact}
                        value={
                          newContractItem.vendorProductId.productPackagingUnit
                        }
                        placeholder="Product Packaging Unit"
                        readOnly
                      />
                    </span>
                    <span>
                      <NumberInput
                        positive
                        value={newContractItem.priceWoVAT}
                        onValueChange={(e) =>
                          setNewContractItem({
                            ...newContractItem,
                            priceWoVAT: e.target.value,
                          })
                        }
                        placeholder="Price w/o VAT"
                      />
                    </span>
                    <span>
                      <Checkbox
                        checked={newContractItem.isActive}
                        checkmarkType={STYLE_TYPE.toggle_round}
                        overrides={{
                          Toggle: {
                            style: ({ $theme, $checked }) => ({
                              backgroundColor: $checked ? '#09B08C' : '#000',
                            }),
                          },
                        }}
                        onChange={(e) =>
                          setNewContractItem({
                            ...newContractItem,
                            isActive: e.target.checked,
                          })
                        }
                      />
                    </span>
                    <span>
                      <SelectWithCheckboxes
                        options={getQualifyingStoreOptions()}
                        store={
                          newContractItem?.specialEmailSpecificStoreList || []
                        }
                        disabled={false}
                        name="Special Email Stores"
                        handleChange={(params: any) =>
                          setNewContractItem({
                            ...newContractItem,
                            specialEmailSpecificStoreList: params,
                          })
                        }
                      />
                    </span>
                    <span>
                      <Checkbox
                        checked={newContractItem.hideInRequisition}
                        checkmarkType={STYLE_TYPE.toggle_round}
                        overrides={{
                          Toggle: {
                            style: ({ $theme, $checked }) => ({
                              backgroundColor: $checked ? '#09B08C' : '#000',
                            }),
                          },
                        }}
                        onChange={(e) =>
                          setNewContractItem({
                            ...newContractItem,
                            hideInRequisition: e.target.checked,
                          })
                        }
                      />
                    </span>
                    <span
                      className={css({
                        color: '#09B08C',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      })}
                      onClick={() => {
                        setSelectedContractItem(newContractItem);
                        setExclusionsModalOpen(true);
                      }}
                    >
                      {newContractItem?.excludedStoreList?.length > 0
                        ? newContractItem?.excludedStoreList?.length +
                          ' Exclusions'
                        : 'None'}
                    </span>
                    <span></span>
                  </div>
                  <div
                    className={css({
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '10px',
                    })}
                  >
                    <button
                      onClick={() => setIsNewItemShown(false)}
                      className={cancelButtonStyle}
                    >
                      Cancel
                    </button>

                    <button
                      onClick={addNewContractItemHandler}
                      className={buttonStyle}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
              {contractItems?.length > 0 && (
                <div style={{ marginTop: '10px' }}>
                  {contractItems.map((item: any) => (
                    <div
                      className={newRequistionWrapper}
                      key={item.vendorProductId.id}
                    >
                      <span>
                        {item.vendorProductId?.supplierProductCode || '-'}
                      </span>
                      <span>
                        {item.vendorProductId?.supplierProductName || '-'}
                      </span>
                      <span>
                        {item.vendorProductId?.productPackagingUnit || '-'}
                      </span>
                      <span>
                        <NumberInput
                          positive
                          value={item.priceWoVAT}
                          onValueChange={(e) =>
                            updateContratItemHandler(
                              item._id,
                              'priceWoVAT',
                              e.target.value
                            )
                          }
                          placeholder="Price w/o VAT"
                        />
                      </span>
                      <span>
                        <Checkbox
                          checked={item.isActive}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          overrides={{
                            Toggle: {
                              style: ({ $theme, $checked }) => ({
                                backgroundColor: $checked ? '#09B08C' : '#000',
                              }),
                            },
                          }}
                          onChange={(e) =>
                            updateContratItemHandler(
                              item._id,
                              'isActive',
                              e.target.checked
                            )
                          }
                        />
                      </span>
                      <span>
                        <SelectWithCheckboxes
                          options={getQualifyingStoreOptions()}
                          store={item?.specialEmailSpecificStoreList || []}
                          disabled={false}
                          name="Special Email Stores"
                          handleChange={(params: any) =>
                            updateContratItemHandler(
                              item._id,
                              'specialEmailSpecificStoreList',
                              params
                            )
                          }
                        />
                      </span>
                      <span>
                        <Checkbox
                          checked={item.hideInRequisition}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          overrides={{
                            Toggle: {
                              style: ({ $theme, $checked }) => ({
                                backgroundColor: $checked ? '#09B08C' : '#000',
                              }),
                            },
                          }}
                          onChange={(e) =>
                            updateContratItemHandler(
                              item._id,
                              'hideInRequisition',
                              e.target.checked
                            )
                          }
                        />
                      </span>
                      <span
                        className={css({
                          color: '#09B08C',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        })}
                        onClick={() => {
                          setSelectedContractItem(item);
                          setExclusionsModalOpen(true);
                        }}
                      >
                        {item?.excludedStoreList?.length > 0
                          ? item?.excludedStoreList?.length + ' Exclusions'
                          : 'None'}
                      </span>
                      <span>
                        <StatefulTooltip
                          content={() => <Block padding={'3px'}>Hide</Block>}
                          showArrow
                          returnFocus
                          autoFocus
                        >
                          <span
                            className={addWrapper}
                            onClick={() => deleteContractItemHandler(item)}
                          >
                            <DeleteIcon />
                          </span>
                        </StatefulTooltip>
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
            })}
          >
            <div className={topLabelWrapper}>
              <p className={topLabel}>Created:</p>
              <span className={topLabelValue}>
                {moment(priceContract?.createdAt).format('DD/MM/YYYY')} by{' '}
                {priceContract?.createdBy?.firstName}{' '}
                {priceContract?.createdBy?.lastName}
              </span>
            </div>
            <div className={topLabelWrapper}>
              <p className={topLabel}>Updated:</p>
              <span className={topLabelValue}>
                {moment(priceContract?.updatedAt).format('DD/MM/YYYY')} by{' '}
                {priceContract?.updatedBy?.firstName}{' '}
                {priceContract?.updatedBy?.lastName}
              </span>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div
          className={css({
            marginTop: '70px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          })}
        >
          <Spinner $size={50} />
        </div>
      )}
      {exclusionsModalOpen && (
        <ExclusionModal
          isOpen={exclusionsModalOpen}
          onClose={() => setExclusionsModalOpen(false)}
          storeOptions={getQualifyingStoreOptions()}
          contractItem={selectedContractItem}
          onExclusionChange={exclusionChangeHandler}
        />
      )}
      {importModalOpen && (
        <ImportPriceContractsItems
          isOpen={importModalOpen}
          setIsOpen={setImportModalOpen}
          stores={getStoreOptions()}
          products={getProductOptions()}
          onImport={importPriceContractItemsHandler}
        />
      )}
    </>
  );
};

export default EditPriceContract;
