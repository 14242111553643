import React, { useEffect, useRef } from 'react';
import { useStyletron } from 'styletron-react';
import * as xlsx from 'xlsx';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import CustomIconButton from 'modules/shared/components/icon-button';
import ordersIconURL from 'assets/icons/orders.svg';
import exportIconURL from 'assets/icons/export.svg';
import { Axios } from 'core/services/http';
import Pagin from './components/pagination';
import { useReactToPrint } from 'react-to-print';
import {
  dateFilters,
  dates,
  sortByTypes,
  typeFilterOptions,
} from 'modules/shared/constant';
import FilterList from './components/search';
import { AppContext } from 'context';
import { UserRole } from 'modules/shared/enums/user-role.enum';
import print from 'assets/icons/print.png';
import numberWithCommas from 'modules/shared/number-with-commas';
import { toaster } from 'baseui/toast';
import { Spinner } from 'baseui/spinner';
import config from 'config';
import colors from 'constants/colors';
import Loader from 'modules/shared/components/loader';
import moment from 'moment';
import './index.scss';
import ReportList from './components/report-list';
import ReportingInternalTableLayout from 'layout/ReportingInternalTableLayout';
import useExportPdf from 'core/hooks/exportPdf';
import SelectOption from 'modules/shared/components/selectoption';
import { exportFileOptions } from 'constants/options';
import { SIZE, Select } from 'baseui/select';
import { Sort } from 'modules/shared/components/sort';

const BREADCRUMB_ITEMS = [
  { name: 'Reports', route: '/reports' },
  {
    name: ' Daily Receiving Report (DRR)',
    route: '/reports/dailyRecievingInvoiceReport',
  },
];
const REPORT_LIST_HEADERS = [
  'S No',
  'Doc No',
  'Date Time',
  'Type',
  'VAT Invoice No',
  'Order No',
  'Current Status',
  'Corr.Inv No',
  'Cor. Inv Date',
  'No. of Items',
  'Inv Net Amount',
];

const DailyReceivingInvoiceReport = () => {
  const [ordersList, setOrdersList] = React.useState<Array<any>>([]);
  const [totalValues, setTotalValues] = React.useState<any>(null);
  const [stores, setStores] = React.useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totolCount, setTotalCount] = React.useState(0);
  const [conflictItemPerPage, setConflictItemPerPage] = React.useState(0);
  const [startConflictPage, setStartConflictPage] = React.useState();
  const [numPages, setNumPages] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<any>([{ label: 20, id: '1' }]);
  const [startDate, setStartDate] = React.useState<any>([
    new Date(moment().utc().clone().startOf('day').format()),
  ]);
  const [endDate, setEndDate] = React.useState<any>([
    new Date(moment().utc().clone().endOf('day').format()),
  ]);
  const [dateOption, setDateOption] = React.useState<any>([]);
  const [dateFilter, setDateFilter] = React.useState<any>([]);
  const [store, setStore] = React.useState<any>([]);
  const [orderStateKey, setOrderStateKey] = React.useState('0');
  const [typeFilter, setTypeFilter] = React.useState<any>([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [allOrdersList, setAllOrdersList] = React.useState<Array<any>>([]);
  const [isClickApply, setIsClickApply] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPageLoading, setIsPageLoading] = React.useState(false);
  const [isExportLoading, setIsExportLoading] = React.useState(false);
  const [sort, setSort] = React.useState<any>([]);
  const [pdfBlobUrl, setPdfBlobUrl] = React.useState<string>('');
  const [exportValue, setExportValue] = React.useState<any>(null);
  const [controller, setController] = React.useState<any>(
    new AbortController()
  );
  const [AscDescOrder, setAscDescOrder] = React.useState<any>([]);
  const [css] = useStyletron();
  const axios = new Axios().getInstance();
  const componentRef: any = useRef();
  const exportPdf = useExportPdf({ pdfBlobUrl, isLoading, setPdfBlobUrl });
  const {
    state: {
      user: { role },
    },
  } = React.useContext(AppContext);
  const isUserByRole = role === UserRole.User;

  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const actionsWrapper = css({
    padding: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '-10px',
    gap: '10px',
  });
  const customButtonWrapper = css({
    marginRight: '8px',
    marginBottom: '8px',
  });
  const listWrapper = css({
    padding: '12px',
    '@media (max-width: 540px)': {
      overflow: 'scroll',
    },
  });

  const wrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(13, 38, 107, 0.1)',
    borderRadius: '10px',
    backgroundColor: colors.white,
    padding: '6px 12px',
    cursor: 'pointer',
    height: 'fit-content',
    '@media (max-width: 600px)': {
      padding: '4px 10px',
    },
  });
  const searchCountWrapper = css({
    display: 'flex',
    minWidth: '530px',
    alignItems: 'center',
  });
  const invoiceCountWrapper = css({
    marginLeft: '2%',
    marginTop: '2px',
  });
  const countTextStyle = css({
    fontFamily: 'poppins-regular',
    color: 'rgb(68, 85, 120)',
    lineHeight: '12px',
  });
  const selectOverrides = {
    ControlContainer: {
      style: () => ({
        border: '1px solid rgba(13, 38, 107, 0.1)',
        borderRadius: '10px',
        backgroundColor: colors.white,
        color: colors.darkBlue,
        width: '180px',
      }),
    },
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        margin-bottom: 20px; /* Add 20px margin to all sides of each printed page */
        counter-increment: page
      }

      @media print {
        .page-break {
          page-break-before: always; /* Add a page break before each element with the "page-break" class */
        }
      }
    `,
  });

  const params: any = {
    period: JSON.stringify({
      label: dateOption.length > 0 ? dateOption[0]?.id : 'today',
      startDate: startDate[0],
      endDate: endDate[0],
    }),
    filterBy: dateFilter.length > 0 ? dateFilter[0]?.id : 'createdAt',
    store: JSON.stringify(store.length > 0 ? store : []),
    type: typeFilter.length > 0 ? typeFilter[0]?.id : 'All',
    search: searchValue,
    sortBy: sort.length > 0 ? sort[0].id : 'vendorInvoiceNumber',
    sortOrder:
      AscDescOrder.length > 0 && AscDescOrder[0].id === 'desc' ? -1 : 1,
    listType: 'report',
    conflictItemPerPage,
    startConflictPage,
    pageSize: pageSize[0]?.label,
    pageNumber: currentPage,
  };

  const getAllListData = async () => {
    try {
      setIsLoading(true);
      const response: any = await fetch(
        `${config.API_URL}/invoice/pdf-print?` + new URLSearchParams(params),
        {
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('auth-token')}`,
          },
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        if (exportValue) {
          return url;
        }
        setPdfBlobUrl(url);
        setIsLoading(false);
      }
      setIsClickApply(true);
    } catch (error: any) {
      setIsLoading(false);
      setIsClickApply(false);
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  useEffect(() => {
    setIsClickApply(false);
  }, [
    dateOption,
    startDate,
    endDate,
    dateFilter,
    store,
    typeFilter,
    searchValue,
  ]);

  const getInvoiceLists = async () => {
    try {
      setIsPageLoading(true);
      const paramerters = {
        ...params,
        conflictItemPerPage,
        startConflictPage,
        pageSize: pageSize[0]?.label,
        pageNumber: currentPage,
      };
      const response = await getInoiceData(paramerters);
      if (response?.data?.success) {
        setOrdersList(response?.data?.data?.items);
        setNumPages(response?.data?.data?.meta?.totalPages);
        setTotalValues({
          totalValues: response?.data?.data?.meta?.totalAmount,
        });
        setTotalCount(response?.data?.data?.meta?.total);
        setConflictItemPerPage(response?.data?.data?.meta?.conflictItem);
        setStartConflictPage(
          response?.data?.data?.meta?.startConflictPageNumber
        );
      }
      setIsPageLoading(false);
    } catch (error: any) {
      setIsPageLoading(false);
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getInoiceData = async (params: any) => {
    try {
      return await axios.get('/report/daily-invoice', {
        params: params,
        signal: controller.signal,
      });
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const onSubmit = () => {
    getInvoiceLists();
  };

  const getExportData = async (): Promise<any[]> => {
    setIsExportLoading(true);
    const response = await getInoiceData(params);
    setIsExportLoading(false);
    if (response?.data?.success) {
      setAllOrdersList(response?.data?.data?.items);
      return response?.data?.data?.items;
    }
    return [];
  };

  async function exportToCSV() {
    let dataToExport: Array<any> = [{}, {}, {}];
    try {
      const orderData = await getExportData();
      orderData?.forEach(
        (
          {
            amount,
            vendorInvoiceNumber,
            orderId,
            createdAt,
            createdDate,
            type,
            vatInvoiceNumber,
            invoiceLines,
            erpStatus,
          },
          index
        ) => {
          const obj: any = {
            's.no': index,
            amount: amount,
            vendorInvoiceNumber: vendorInvoiceNumber,
            vendorInvoiceNumber2: vendorInvoiceNumber,
            orderId: orderId,
            createdAt: createdAt,
            createdDate: createdDate,
            type: type,
            vatInvoiceNumber: vatInvoiceNumber,
            invoiceLines: invoiceLines,
            erpStatus: erpStatus,
          };
          dataToExport.push(obj);
        }
      );
      const columnWidths = [
        { wch: 5 },
        { wch: 10 },
        { wch: 25 },
        { wch: 15 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
        { wch: 40 },
        { wch: 20 },
        { wch: 10 },
        { wch: 25 },
      ];
      const worksheet = xlsx.utils.json_to_sheet(dataToExport);
      if (!worksheet['!merges']) worksheet['!merges'] = [];
      worksheet['!merges'].push(xlsx.utils.decode_range('A1:K1'));
      xlsx.utils.sheet_add_aoa(worksheet, [['Order List Report']], {
        origin: 'A1',
      });
      worksheet['!cols'] = columnWidths;
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [
          [
            'S.no',
            'Amount',
            'Vendor Invoice No',
            'Doc No',
            'Order No.',
            'Cor. Inv Date',
            'Invoice Date',
            'Type',
            'VAT Invoice No',
            'No. of Items',
            'Status',
          ],
        ],
        {
          origin: 'A4',
        }
      );
      setExportValue(null);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Invoice Lists');
      if (orderData.length) {
        xlsx.writeFile(workbook, 'daily-receiving-list.xlsx');
      }
    } catch (err: any) {
      toaster.negative(err.message, {
        autoHideDuration: 4000,
      });
    }
  }

  const getStores = async () => {
    try {
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        const stores = response.data.data.map((store: any) => ({
          id: store.id,
          label: store.name,
        }));
        setStores(stores);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  useEffect(() => {
    Promise.all([getStores(), getInvoiceLists()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStateKey, currentPage, pageSize, sort, AscDescOrder]);

  useEffect(() => {
    if (searchValue === '') {
      getInvoiceLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const listToRender = () => {
    return ordersList.map((item, index) => {
      return [
        index + 1,
        item.vendorInvoiceNumber,
        item.createdAt ? item.createdAt : '-',
        item.type,
        item.vatInvoiceNumber,
        item.orderId,
        item.erpStatus,
        item.correctInvoiceNum,
        item.createdDate,
        item.invoiceLines,
        item.amount ? `${numberWithCommas(item.amount)}` : '-',
      ];
    });
  };
  const cancelProcessing = (event?: any, isEmailProcessStarted?: boolean) => {
    if (!isEmailProcessStarted) {
      setExportValue(null);
    }
    controller.abort();
    setIsLoading(false);
    setIsExportLoading(false);
    const newController = new AbortController();
    setController(newController);
  };
  const downloadPdf = async () => {
    const file = await getAllListData();
    if (file) {
      var anchor: any = document.createElement('a');
      anchor.id = 'invoice-list-report-pdf-anchor';
      anchor.href = file;
      anchor.style.display = 'none';
      anchor.download = 'daily-invoice-report.pdf';
      document.body.appendChild(anchor);
      setIsLoading(false);
      setIsExportLoading(false);
      setExportValue(null);
      anchor.click();
    }
  };
  const downloadFile = () => {
    if (exportValue[0]?.id === 1) {
      setIsExportLoading(true);
      return downloadPdf();
    }
    if (exportValue[0]?.id === 2) return exportToCSV();
  };
  useEffect(() => {
    if (exportValue) {
      downloadFile();
    }
  }, [exportValue]);
  const user = JSON.parse(localStorage.getItem('auth-user') || '{}') as any;
  const emailMeFile = async () => {
    cancelProcessing({}, true);
    if (exportValue[0]?.id === 2) {
      setExportValue(null);
      try {
        await axios.get('/report/daily-invoice', {
          params: {
            ...params,
            emailFile: 'excel',
            userEmail: user && user.email ? user.email : '',
            conflictItemPerPage,
            startConflictPage,
            pageSize: pageSize[0]?.label,
            pageNumber: currentPage,
          },
        });
      } catch (error: any) {
        const errors = error?.response?.data?.errors;
        if (errors?.length) {
          errors.forEach((message: string) => console.error(message));
        }
      }
    }
    if (exportValue[0]?.id === 1) {
      try {
        setExportValue(null);
        const response: any = await fetch(
          `${config.API_URL}/invoice/pdf-print?` +
            new URLSearchParams({
              ...params,
              emailFile: 'pdf',
              userEmail: user && user.email ? user.email : '',
            }),
          {
            method: 'GET',
            headers: {
              authorization: `Bearer ${localStorage.getItem('auth-token')}`,
            },
          }
        );
      } catch (error) {
        setExportValue(null);
      }
    }
  };
  const sortByOptions = [
    { id: 'invoiceNumber', label: 'Document No' },
    { id: 'vatInvoiceNumber', label: 'VAT Invoice No' },
    { id: 'createdAt', label: 'Created Date' },
    { id: 'orderId', label: 'Order Number' },
  ];
  const sortOption = css({
    marginRight: '5px',
    width: '250px',
    fontFamily: 'poppins-regular',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: '5px',
  });
  const inputWrapper = css({
    display: 'flex',
  });

  return (
    <ReportingInternalTableLayout
      isLoading={isLoading}
      isExportLoading={isExportLoading}
      cancelProcessing={cancelProcessing}
      type={exportValue}
      emailMeFile={emailMeFile}
    >
      <div className={breadcrumbWrapper}>
        <img className={iconStyles} src={ordersIconURL} alt="Order" />
        <Breadcrumb items={BREADCRUMB_ITEMS} />
      </div>
      <div className={listWrapper}>
        <FilterList
          typeFilters={typeFilterOptions}
          typeFilter={typeFilter}
          dates={dates}
          dateFilters={dateFilters}
          stores={stores}
          store={store}
          startDate={startDate}
          endDate={endDate}
          dateOption={dateOption}
          dateFilter={dateFilter}
          setStore={setStore}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setDateOption={setDateOption}
          setDateFilter={setDateFilter}
          onSubmit={onSubmit}
        />
      </div>
      <div className={actionsWrapper}>
        <div className={searchCountWrapper}>
          <div className={invoiceCountWrapper}>
            <div>
              <p style={{ display: 'inline' }} className={countTextStyle}>
                {totolCount} Invoice(s)
              </p>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={customButtonWrapper}>
            <div className={inputWrapper}>
              <Sort sortOption={sortOption} />
              <Select
                size={SIZE.default}
                options={sortByOptions}
                value={sort}
                placeholder="Sort"
                disabled={false}
                onChange={(params: any) => setSort(params.value)}
                overrides={selectOverrides}
              />
            </div>
          </div>
          <div style={{ margin: '0 5px' }}>
            <Select
              size={SIZE.default}
              options={sortByTypes}
              placeholder="ASC"
              disabled={false}
              value={AscDescOrder}
              onChange={(params: any) => setAscDescOrder(params.value)}
              overrides={selectOverrides}
              clearable={false}
            />
          </div>
          {!isUserByRole && (
            <div className={customButtonWrapper}>
              {!isLoading || isExportLoading ? (
                <CustomIconButton
                  icon={print}
                  name="Print"
                  onClick={getAllListData}
                  isDisable={false}
                />
              ) : (
                <div className={wrapperStyles}>
                  <span> Printing...</span>
                  <Spinner $size={20} />
                </div>
              )}
            </div>
          )}
          {!isUserByRole &&
            (!isExportLoading ? (
              <div className={customButtonWrapper}>
                <SelectOption
                  name="Export"
                  exportFileOptions={exportFileOptions}
                  value={exportValue}
                  selectOption={(params) => {
                    setExportValue(params.value);
                  }}
                  selectOverrides={selectOverrides}
                />
              </div>
            ) : (
              <div className={wrapperStyles}>
                <span> Exporting...</span>
                <Spinner $size={20} />
              </div>
            ))}
        </div>
      </div>
      <div className={listWrapper} style={{ marginTop: '-5px' }}>
        {isPageLoading && !isExportLoading && !isLoading ? (
          <div className="loader-container">
            <Loader size="small" />
          </div>
        ) : (
          <ReportList
            reportHeader={REPORT_LIST_HEADERS}
            reportData={listToRender()}
            type="invoice"
            type2="DDR"
            totalValues={totalValues}
          />
        )}
      </div>
      <div className={listWrapper} style={{ display: 'flex', float: 'right' }}>
        <Pagin
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numPages={numPages}
          pageSize={pageSize}
        />
      </div>
    </ReportingInternalTableLayout>
  );
};

export default DailyReceivingInvoiceReport;
