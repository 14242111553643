const soundOptions = [
  {
    id: 'defaultSound',
    label: 'Default Sound (Pop)',
    url: 'http://codeskulptor-demos.commondatastorage.googleapis.com/pang/pop.mp3',
  },
  {
    id: 'sound1',
    label: 'Brrrrringg',
    url: 'http://commondatastorage.googleapis.com/codeskulptor-assets/week7-brrring.m4a',
  },
  {
    id: 'sound2',
    label: 'Button Click',
    url: 'http://commondatastorage.googleapis.com/codeskulptor-assets/week7-button.m4a',
  },
];

export default soundOptions;
