import React from 'react';
import priceListIconURL from 'assets/icons/price-list.svg';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import { useStyletron } from 'styletron-react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';
import { DatePicker } from 'baseui/datepicker';
import { Select } from 'baseui/select';
import colors from 'constants/colors';
import { useNavigate } from 'react-router-dom';
import { Axios } from 'core/services/http';
import moment from 'moment';
import { toaster } from 'baseui/toast';

const BREADCRUMB_ITEMS = [
  { name: 'Price Contracts', route: '/price-contracts' },
  { name: 'Create Price Contract', route: '' },
];

const CreatePriceContract = () => {
  const [css] = useStyletron();
  const navigate = useNavigate();

  const axios = new Axios().getInstance();

  //backend data
  const [vendors, setVendors] = React.useState<Array<any>>([]);
  const [brands, setBrands] = React.useState<Array<any>>([]);
  const [stores, setStores] = React.useState<Array<any>>([]);

  //frontend data for create price contract
  const [supplier, setSupplier] = React.useState<any>([]);
  const [isOpenContract, setIsOpenContract] = React.useState(true);
  const [startDate, setStartDate] = React.useState<any>([]);
  const [endDate, setEndDate] = React.useState<any>([]);
  const [validForAllBrands, setValidForAllBrands] = React.useState(true);
  const [includedBrandList, setIncludedBrandList] = React.useState([]);
  const [excludedBrandList, setExcludedBrandList] = React.useState([]);
  const [validForAllStores, setValidForAllStores] = React.useState(true);
  const [includedStoreList, setIncludedStoreList] = React.useState([]);
  const [excludedStoreList, setExcludedStoreList] = React.useState([]);

  const getVendors = async () => {
    try {
      const response = await axios.get('/vendors?all=true');
      if (response?.data?.success && response?.data?.data?.products) {
        setVendors(
          response?.data?.data?.products?.filter(
            (data: any) => data.deleted === false
          )
        );
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getStores = async () => {
    try {
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        setStores(response.data.data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const BrandStatusEnum = {
    Active: 'active',
    Archived: 'archived',
  };

  const getBrands = async () => {
    try {
      const response = await axios.get('/brands?status=active', {
        params: {
          status: BrandStatusEnum.Active,
        },
      });
      if (response?.data?.success) {
        setBrands(response.data.data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  React.useEffect(() => {
    getVendors();
    getStores();
    getBrands();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });
  const cancelButtonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
    backgroundColor: colors.darkGray,
    marginLeft: '10px',
  });
  const buttonWrapper = css({
    margin: '24px 10px',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 540px)': {
      width: '100%',
      marginTop: '24px',
    },
  });
  const flexGridItemClass = css({
    width: 'fit-content !important',
    '@media (max-width: 540px)': {
      width: '100%',
      marginRight: 'auto',
    },
  });
  const flexGridSmallItemClass = css({
    '@media (min-width: 541px)': {
      width: 'calc(((100% - 120px) / 12) - 0.5px) !important',
      marginRight: '12px !important',
    },
  });

  const getVendorOptions = () => {
    return vendors.map((vendor: any) => ({
      id: vendor.id,
      label: vendor.name,
    }));
  };

  const getStoreOptions = () => {
    const storeOptions = stores.map((store: any) => ({
      id: store.id,
      label: store.name,
      brand: store.brand,
    }));

    const filteredStoreOptions = storeOptions.filter((storeOption) => {
      //if valid for all brands is true
      if (validForAllBrands) {
        //we check if the exluded brand list is empty then we return all stores
        if (excludedBrandList.length === 0) {
          return true;
        }
        //we return the stores that are not included in the excluded brand list
        const isExcluded = excludedBrandList.find(
          ({ id }: any) =>
            id === storeOption.brand?._id || id === storeOption.brand
        );
        return !isExcluded;
      } else {
        //we return the stores that are included in the included brand list
        const isIncluded = includedBrandList.find(
          ({ id }: any) =>
            id === storeOption.brand?._id || id === storeOption.brand
        );
        return isIncluded;
      }
    });

    return filteredStoreOptions;
  };

  const getBrandOptions = () => {
    return brands.map((brand: any) => ({
      id: brand.id,
      label: brand.name,
    }));
  };

  const isAllInputsValid = () => {
    if (!supplier.length) {
      toaster.negative('Supplier is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!startDate.length) {
      toaster.negative('Start Date is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!isOpenContract && !endDate.length) {
      toaster.negative('End Date is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (endDate.length && startDate[0] > endDate[0]) {
      toaster.negative('End Date should be greater than Start Date', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!validForAllBrands && !includedBrandList.length) {
      toaster.negative('Included Brands is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!validForAllStores && !includedStoreList.length) {
      toaster.negative('Included Stores is required', {
        autoHideDuration: 4000,
      });
      return false;
    }

    return true;
  };

  const createPriceContractHandler = async () => {
    if (!isAllInputsValid()) {
      return;
    }
    const props = {
      vendorId: supplier[0]?.id,
      contractStartDate: moment(startDate[0]).format(),
      contractEndDate: endDate[0] ? moment(endDate[0]).format() : null,
      validForAllStores,
      validForAllBrands,
      includedBrandList: includedBrandList?.map((brand: any) => brand?.id),
      excludedBrandList: excludedBrandList?.map((brand: any) => brand?.id),
      includedStoreList: includedStoreList?.map((store: any) => store?.id),
      excludedStoreList: excludedStoreList?.map((store: any) => store?.id),
      isOpenContract,
    };

    //axios call to create price contract
    try {
      const response = await axios.post('/priceContracts/create', props);
      if (response?.data?.success) {
        toaster.positive('Price Contract created successfully', {
          autoHideDuration: 4000,
        });
        navigate(`/price-contracts/edit/${response?.data?.data?.contractId}`);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) =>
          toaster.negative(message, {
            autoHideDuration: 4000,
          })
        );
      }
    }
  };

  return (
    <div className={css({ marginTop: '70px', padding: '10px' })}>
      <div className={breadcrumbWrapper}>
        <img
          className={iconStyles}
          src={priceListIconURL}
          alt="Price Contract"
        />
        <Breadcrumb items={BREADCRUMB_ITEMS} />
      </div>
      <div>
        <div>
          <FlexGrid
            flexGridColumnCount={[1, 1, 6]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <div className="input-wrapper">
                <div className="input-label">Supplier Name</div>
                <Select
                  options={getVendorOptions()}
                  value={supplier}
                  placeholder="Select Supplier"
                  onChange={(params: any) => setSupplier(params.value)}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem>
              <div className="input-wrapper">
                <div className="input-label">Contract Start Date</div>
                <DatePicker
                  value={startDate}
                  onChange={({ date }) =>
                    setStartDate(Array.isArray(date) ? date : [date])
                  }
                  overrides={{
                    Popover: {
                      props: {
                        overrides: {
                          Body: {
                            style: { zIndex: 999 },
                          },
                        },
                      },
                    },
                    MonthYearSelectPopover: {
                      props: {
                        overrides: {
                          Body: {
                            style: { zIndex: 9999 },
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem>
              <div className="input-wrapper">
                <div className="input-label">Contract End Date</div>
                <DatePicker
                  value={endDate}
                  minDate={startDate[0]}
                  onChange={({ date }) => {
                    setEndDate(Array.isArray(date) ? date : [date]);
                    setIsOpenContract(false);
                  }}
                  overrides={{
                    Popover: {
                      props: {
                        overrides: {
                          Body: {
                            style: { zIndex: 999 },
                          },
                        },
                      },
                    },
                    MonthYearSelectPopover: {
                      props: {
                        overrides: {
                          Body: {
                            style: { zIndex: 9999 },
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem className={flexGridSmallItemClass}>
              <div className="input-wrapper">
                <div className="input-label" style={{ marginBottom: '12px' }}>
                  Open Contract
                </div>
                <Checkbox
                  checked={isOpenContract}
                  checkmarkType={STYLE_TYPE.toggle_round}
                  overrides={{
                    Toggle: {
                      style: ({ $theme, $checked }) => ({
                        backgroundColor: $checked ? '#09B08C' : '#000',
                      }),
                    },
                  }}
                  onChange={(e) => {
                    setIsOpenContract(e.target.checked);
                    //if contract is open then set end date to empty array
                    if (e.target.checked) {
                      setEndDate([]);
                    }
                  }}
                />
              </div>
            </FlexGridItem>
          </FlexGrid>
          <br />
          <FlexGrid
            flexGridColumnCount={[1, 2, 6]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem className={flexGridSmallItemClass}>
              <div className="input-wrapper">
                <div className="input-label" style={{ marginBottom: '12px' }}>
                  All Brands
                </div>
                <Checkbox
                  checked={validForAllBrands}
                  checkmarkType={STYLE_TYPE.toggle_round}
                  overrides={{
                    Toggle: {
                      style: ({ $theme, $checked }) => ({
                        backgroundColor: $checked ? '#09B08C' : '#000',
                      }),
                    },
                  }}
                  onChange={(e) => {
                    setIncludedBrandList([]);
                    setExcludedBrandList([]);
                    setValidForAllBrands(e.target.checked);
                  }}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem className={flexGridItemClass}>
              <div className="input-wrapper">
                <div className="input-label">Included Brands</div>
                <SelectWithCheckboxes
                  //@ts-ignore
                  options={getBrandOptions()}
                  disabled={validForAllBrands}
                  store={includedBrandList}
                  name="Include Brands"
                  handleChange={(params: any) => setIncludedBrandList(params)}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem className={flexGridItemClass}>
              <div className="input-wrapper">
                <div className="input-label">Excluded Brands</div>
                <SelectWithCheckboxes
                  options={getBrandOptions()}
                  disabled={!validForAllBrands}
                  store={excludedBrandList}
                  name="Exclude Brands"
                  handleChange={(params: any) => setExcludedBrandList(params)}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem className={flexGridSmallItemClass}>
              <div className="input-wrapper">
                <div className="input-label" style={{ marginBottom: '12px' }}>
                  All Stores
                </div>
                <Checkbox
                  checked={validForAllStores}
                  checkmarkType={STYLE_TYPE.toggle_round}
                  overrides={{
                    Toggle: {
                      style: ({ $theme, $checked }) => ({
                        backgroundColor: $checked ? '#09B08C' : '#000',
                      }),
                    },
                  }}
                  onChange={(e) => {
                    setIncludedStoreList([]);
                    setExcludedStoreList([]);
                    setValidForAllStores(e.target.checked);
                  }}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem className={flexGridItemClass}>
              <div className="input-wrapper">
                <div className="input-label">Included Stores</div>
                <SelectWithCheckboxes
                  options={getStoreOptions()}
                  disabled={validForAllStores}
                  store={includedStoreList}
                  handleChange={(params: any) => setIncludedStoreList(params)}
                />
              </div>
            </FlexGridItem>
            <FlexGridItem className={flexGridItemClass}>
              <div className="input-wrapper">
                <div className="input-label">Excluded Stores</div>
                <SelectWithCheckboxes
                  options={getStoreOptions()}
                  disabled={!validForAllStores}
                  store={excludedStoreList}
                  handleChange={(params: any) => setExcludedStoreList(params)}
                />
              </div>
            </FlexGridItem>
          </FlexGrid>
        </div>
        <div className={buttonWrapper}>
          <button className={buttonStyle} onClick={createPriceContractHandler}>
            Create
          </button>
          <button
            className={cancelButtonStyle}
            onClick={() => navigate('/price-contracts')}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePriceContract;
