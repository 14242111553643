import OverlayLoader from 'modules/shared/components/overlayloader';
import React from 'react';
import { useStyletron } from 'styletron-react';
type Props = {
  children: React.ReactNode;
  isLoading: boolean;
  isExportLoading: boolean;
  cancelProcessing: () => void;
  emailMeFile?: () => void;
  type?: any;
};
const ReportingInternalTableLayout = ({
  children,
  isLoading,
  isExportLoading,
  cancelProcessing,
  emailMeFile,
  type,
}: Props) => {
  const [css] = useStyletron();
  return (
    <div className={css({ marginTop: '70px' })}>
      {(isLoading || isExportLoading) && (
        <OverlayLoader
          cancelProcessing={cancelProcessing}
          isExportLoading={isExportLoading}
          emailMeFile={emailMeFile}
          type={type && (type[0]?.id === 2 || type[0]?.id === 1)}
        />
      )}
      {children}
    </div>
  );
};

export default ReportingInternalTableLayout;
