import RequireAuth from '../require-auth';
import { Navigate } from 'react-router-dom';
interface IProps {
  hasAccessToTransferModule: boolean;
  Component: React.ReactNode;
}
const PermissionWrapper = ({
  hasAccessToTransferModule,
  Component,
}: IProps) => {
  if (!hasAccessToTransferModule) return <Navigate to="/dashboard" />;
  return <RequireAuth>{Component}</RequireAuth>;
};

export default PermissionWrapper;
