import { useStyletron } from 'styletron-react';
import colors from 'constants/colors';
import { useLocation } from 'react-router-dom';
import { urls } from 'constants/urls';
import { Link } from 'react-router-dom';

interface IProps {
  icon: string;
  iconActive: string;
  name: string;
  route: string;
  clicked: () => void;
  isSubmenuOpen?: boolean;
  isActive?: boolean;
  isSubmenu?: boolean;
  hasSubMenus?: boolean;
  openSubMenus?: boolean;
  isSidebarOpen?: boolean;
  id: number;
}

const SideBarLink = ({
  icon,
  iconActive,
  name,
  route,
  clicked,
  isActive,
  hasSubMenus,
  openSubMenus,
  isSidebarOpen,
  id,
}: IProps) => {
  const [css] = useStyletron();
  const location = useLocation();
  function getActivePathname(location: any, route: string) {
    if (location.search) {
      return `${location.pathname}${location.search}`.includes(route);
    }
    return location.pathname.includes(route);
  }
  const isActiveURl = getActivePathname(location, route);
  const wrapperStyles = css({
    display: 'flex',
    flexDirection: !isSidebarOpen ? 'column' : 'row',
    justifyContent: !isSidebarOpen ? 'center' : '',
    width: '100%',
    maxWidth: '180px',
    padding: '4px 0px',
    alignItems: 'center',
    marginBottom: id === 4 ? '0' : '6px',
    cursor: 'pointer',
    position: 'relative',
  });
  const wrapperActiveStyles = css({
    display: 'flex',
    flexDirection: !isSidebarOpen ? 'column' : 'row',
    justifyContent: !isSidebarOpen ? 'center' : '',
    width: '100%',
    maxWidth: '180px',
    padding: '4px 0px',
    alignItems: 'center',
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '12px',
    backgroundColor: colors.primaryGreen,
    marginBottom: '6px',
    cursor: 'pointer',
    position: 'relative',
  });

  const iconStyles = css({
    margin: '0 15px',
    width: '20px',
    height: '20px',
    marginRight: !isSidebarOpen ? '20px' : '0.1rem',
    filter:
      'invert(31%) sepia(72%) saturate(259%) hue-rotate(181deg) brightness(90%) contrast(94%)',
  });
  const iconActiveStyles = css({
    margin: '0 15px',
    width: '20px',
    height: '20px',
    marginRight: !isSidebarOpen ? '20px' : '0.1rem',
  });
  const buttonStyles = css({
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontFamily: 'poppins-regular',
    fontSize: isSidebarOpen ? '16px' : '12px',
    color: colors.darkBlue,
    cursor: 'pointer',
  });
  const buttonActiveStyles = css({
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontFamily: 'poppins-medium',
    fontSize: isSidebarOpen ? '16px' : '12px',
    color: colors.white,
    cursor: 'pointer',
    // textAlign: 'left',
    display: 'inline-block',
    overflow: 'hidden',
  });
  const sidebarIcon = css({
    display: 'flex',
  });
  const sidebarLabel = css({
    opacity: isSidebarOpen ? '1' : '0',
    pointerEvents: 'none',
    transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-20px)',
    transition: 'all 400ms ease',
  });
  const iconContainerStyles = css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
  });
  const iconHoverStyles = css({
    ':hover .tooltip': {
      visibility: 'visible',
      opacity: 1,
    },
  });
  return (
    <>
      {urls?.includes(location.pathname) ? (
        <Link to={route}>
          <div className={sidebarIcon}>
            <img src={icon} alt="" />
          </div>
          <div
            className={sidebarLabel}
            style={{ color: location?.pathname === route ? 'var(--blue)' : '' }}
          >
            {name}
          </div>
        </Link>
      ) : (
        <div
          className={
            isActiveURl && isActive ? wrapperActiveStyles : wrapperStyles
          }
          onClick={clicked}
          style={{
            backgroundColor:
              openSubMenus && isActive ? colors.lighterGray : 'none',
          }}
        >
          <div
            className={`${iconContainerStyles} ${
              !isSidebarOpen ? iconHoverStyles : ''
            }`}
          >
            <img
              className={
                isActiveURl && isActive ? iconActiveStyles : iconStyles
              }
              src={isActiveURl && isActive ? iconActive : icon}
              alt={name}
            />
          </div>
          <button
            className={
              isActiveURl && isActive ? buttonActiveStyles : buttonStyles
            }
            type="button"
          >
            {name}
          </button>
        </div>
      )}
    </>
  );
};

export default SideBarLink;
