import { useStyletron } from 'styletron-react';
import sortArrowIconURL from 'assets/icons/arrow-sort.svg';
import colors from 'constants/colors';

const Icon = () => {
  const [css] = useStyletron();
  return (
    <img
      className={css({
        width: '24px',
        height: '24px',
        margin: '0px 10px',
        color: colors.darkBlue,
      })}
      src={sortArrowIconURL}
      alt="Sort"
    />
  );
};

export default Icon;
