import React, { useEffect, useRef, useState } from 'react';
import { useStyletron } from 'styletron-react';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import CustomIconButton from 'modules/shared/components/icon-button';
import ordersIconURL from 'assets/icons/orders.svg';
import { Axios } from 'core/services/http';
import Pagin from './components/pagination';
import { Select, SIZE } from 'baseui/select';
import {
  dateFilters,
  dates,
  pageSizeOption,
  sortByTypes,
} from 'modules/shared/constant';
import FilterList from './components/search';
import ReportList from 'modules/shared/components/report-list';
import moment from 'moment';
import ExportOrderReport from './components/export';
import html2pdf from 'html2pdf.js';
import * as xlsx from 'xlsx';
import colors from 'constants/colors';
import filter from '../../../../assets/icons/filter.png';
import './index.scss';
import { useLocation } from 'react-router-dom';
import config from 'config';
import { Spinner } from 'baseui/spinner';
import print from 'assets/icons/print.png';
import CustomSearch from 'modules/shared/components/search';
import { toaster } from 'baseui/toast';
import Loader from 'modules/shared/components/loader';
import ReportingInternalTableLayout from 'layout/ReportingInternalTableLayout';
import useExportPdf from 'core/hooks/exportPdf';
import { Sort } from 'modules/shared/components/sort';
import SelectOption from 'modules/shared/components/selectoption';
import { exportFileOptions } from 'constants/options';
import useLoader from 'core/hooks/loader';

const BREADCRUMB_ITEMS = [
  { name: 'Reports', route: '/reports' },
  { name: 'Order List', route: '/reports/orderListReport' },
];
const REPORT_LIST_HEADERS = [
  'Order No',
  'Store',
  'Order Date',
  'Supply Date',
  'PR No.',
  'Order Value',
  'Supplier',
  'Received Value',
];
interface IParams {
  param: any;
}

const OrderListReport = () => {
  const location = useLocation();
  const { loading, cancelLoading, triggerLoading } = useLoader();
  const [ordersList, setOrdersList] = React.useState<Array<any>>([]);
  const [totalValues, setTotalValues] = React.useState<any>(null);
  const [stores, setStores] = React.useState<Array<any>>([]);
  const [users, setUsers] = React.useState<Array<any>>([]);
  const [vendors, setVendors] = React.useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [orderNumberSearch, setOrderNumberSearch] = React.useState('');
  const [createdByValue, setCreatedByValue] = React.useState<Array<any>>([]);
  const [vendorsValue, setVendorsValue] = React.useState<Array<any>>([]);
  const [updatedByValue, setUpdatedByValue] = React.useState<Array<any>>([]);
  const [releasedByValue, setReleasedByValue] = React.useState<Array<any>>([]);
  const [orderMinValue, setOrderMinValue] = React.useState<any>();
  const [orderMaxValue, setOrderMaxValue] = React.useState<any>();
  const [products, setProducts] = React.useState<Array<any>>([]);
  const [requisitionNumberSearch, setRequisitionNumberSearch] =
    React.useState('');
  const [sort, setSort] = React.useState<any>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [numPages, setNumPages] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<any>([{ label: 10, id: '1' }]);
  const [processingErrorCountValue, setProcessingErrorCountValue] =
    React.useState<any>();
  const [activeAllFilters, setActiveAllFilters] = useState(false);
  const [startDate, setStartDate] = React.useState<any>([
    new Date(moment().utc().clone().startOf('month').format()),
  ]);
  const [secondStartDate, setSecondStartDate] = React.useState<any>([]);
  const [secondEndDate, setSecondEndDate] = React.useState<any>([]);
  const [endDate, setEndDate] = React.useState<any>([new Date()]);
  const [supplyStartDate, setSupplyStartDate] = React.useState<any>([]);
  const [supplyEndDate, setSupplyEndDate] = React.useState<any>([]);
  const [dateOption, setDateOption] = React.useState<any>([]);
  const [secondDateOption, setSecondDateOption] = React.useState<any>([]);
  const [dateFilter, setDateFilter] = React.useState<any>([]);
  const [secondDateFilter, setSecondDateFilter] = React.useState<any>([]);
  const [store, setStore] = React.useState<any>([]);
  const [orderStatusFilterValue, setOrderStatusFilterValue] = useState<any>([]);
  const [isChangedAfterReleaseValue, setIsChangedAfterReleaseValue] =
    useState<any>([]);
  const [validForAllStores, setValidForAllStores] = React.useState(true);
  const [isDateDisable, setIsDateDisable] = React.useState<any>(true);
  const [productsValue, setProductsValue] = useState<any>([]);
  const containerRef = useRef(null);
  const [isExportOpen, setIsExportOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isExportLoading, setIsExportLoading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [isPageLoading, setIsPageLoading] = React.useState(false);
  const [allOrdersList, setAllOrdersList] = React.useState<Array<any>>([]);
  const [css] = useStyletron();
  const axios = new Axios().getInstance();
  const [pdfBlobUrl, setPdfBlobUrl] = React.useState<string>('');
  const [AscDescOrder, setAscDescOrder] = React.useState<any>([]);
  const pdpExport = useExportPdf({ pdfBlobUrl, isLoading, setPdfBlobUrl });
  const [controller, setController] = useState<any>(new AbortController());
  const [exportValue, setExportValue] = useState<any>(null);
  const user = JSON.parse(localStorage.getItem('auth-user') || '{}') as any;
  const sortByOptions = [
    { id: 'orderNumber', label: 'Order Number' },
    { id: 'supplierId', label: 'Supplier' },
    { id: 'store', label: 'Store' },
    { id: 'date', label: 'Order Date' },
    { id: 'supplyDate', label: 'Supply Date' },
    { id: 'prNo', label: 'Pr No' },
    { id: 'value', label: 'Order Value (for this page)' },
    { id: 'receivedValue', label: 'Received Value (for this page)' },
  ];
  const titleWrapper = css({
    display: 'flex',
    padding: '16px 16px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
  });
  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '10px 0px 0',
    alignItems: 'center',
    marginTop: '-40px',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const actionsWrapper = css({
    padding: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '-10px',
    gap: '10px',
  });
  const searchWrapper = css({ width: '400px' });
  const inputWrapper = css({
    display: 'flex',
    marginRight: '5px',
    marginLeft: '5px',
  });
  const exportBtnWrapper = css({
    marginRight: '5px',
  });
  const listWrapper = css({
    padding: '12px',
    '@media (max-width: 540px)': {
      overflow: 'scroll',
    },
  });
  const filterListWrapper = css({
    padding: '12px',
    '@media (max-width: 540px)': {
      overflow: 'scroll',
    },
    marginTop: '-40px',
  });
  const sortOption = css({
    marginRight: '5px',
    width: '250px',
    fontFamily: 'poppins-regular',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: '5px',
  });
  const toggleFilter = css({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '12px 8px',
    borderRadius: '10px',
    background: activeAllFilters ? colors.darkGreen : colors.white,
    cursor: 'pointer',
    margin: '10px 0',
    boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  });
  const filterImg = css({
    width: '22px',
    filter: activeAllFilters
      ? 'brightness(0) invert(1)'
      : 'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const customButtonWrapper = css({
    marginRight: '8px',
    marginBottom: '8px',
  });
  const wrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(13, 38, 107, 0.1)',
    borderRadius: '10px',
    backgroundColor: colors.white,
    padding: '6px 12px',
    cursor: 'pointer',
    height: 'fit-content',
    '@media (max-width: 600px)': {
      padding: '4px 10px',
    },
  });
  const searchCountWrapper = css({
    display: 'flex',
    minWidth: '530px',
    alignItems: 'center',
  });
  const orderCountWrapper = css({
    marginLeft: '2%',
    marginTop: '2px',
  });
  const countTextStyle = css({
    fontFamily: 'poppins-regular',
    color: 'rgb(68, 85, 120)',
    lineHeight: '12px',
  });

  const getOrderLists = async (
    pageNumber?: number,
    datePeriods?: any,
    customStartDate?: any,
    customEndDate?: any,
    selectedStore?: any
  ) => {
    try {
      setIsPageLoading(true);
      const response = await axios.get('/report', {
        params: {
          period: {
            label:
              datePeriods?.length > 0
                ? datePeriods[0]?.id
                : dateOption.length > 0
                ? dateOption[0]?.id
                : 'this_month',
            startDate:
              customStartDate?.length > 0 ? customStartDate[0] : startDate[0],
            endDate: customEndDate?.length > 0 ? customEndDate[0] : endDate[0],
          },
          secondPeriod:
            secondDateOption.length > 0
              ? {
                  label:
                    secondDateOption.length > 0
                      ? secondDateOption[0]?.id
                      : 'today',
                  startDate: secondStartDate[0],
                  endDate: secondEndDate[0],
                }
              : '',
          filterBy: dateFilter.length > 0 ? dateFilter[0]?.id : 'ReleasedAt',
          secondFilterBy:
            secondDateFilter.length > 0
              ? secondDateFilter[0]?.id
              : 'SupplyDate',
          store:
            selectedStore?.length > 0
              ? selectedStore.map((item: any) => item.id)
              : store.length > 0
              ? store.map((item: any) => item.id)
              : [],
          pageSize: pageSize[0]?.label,
          pageNumber: pageNumber ? pageNumber : currentPage,
          orderNumber: orderNumberSearch ? orderNumberSearch : '',
          prNumber: requisitionNumberSearch,
          supplyDateFrom: supplyStartDate.length > 0 ? supplyStartDate[0] : '',
          supplyDateTo: supplyEndDate.length > 0 ? supplyEndDate[0] : '',
          orderStatus:
            orderStatusFilterValue.length > 0
              ? orderStatusFilterValue.map((item: any) => item.id)
              : [],
          isChangedAfterRelease:
            isChangedAfterReleaseValue.length > 0
              ? isChangedAfterReleaseValue.map((item: any) => item.id)
              : [],
          processingErrorCount: processingErrorCountValue
            ? processingErrorCountValue
            : '',
          createdBy: createdByValue.length > 0 ? createdByValue[0].id : '',
          updatedBy: updatedByValue.length > 0 ? updatedByValue[0].id : '',
          releasedBy: releasedByValue.length > 0 ? releasedByValue[0].id : '',
          supplierIds:
            vendorsValue.length > 0
              ? vendorsValue.map((item: any) => item.id)
              : [],
          sortType: sort.length > 0 ? sort[0].id : '',
          sortOrder:
            AscDescOrder.length > 0 && AscDescOrder[0].id === 'desc' ? -1 : 1,
          orderNumberMin: orderMinValue ? orderMinValue : '',
          orderNumberMax: orderMaxValue ? orderMaxValue : '',
          vendorProductIds:
            productsValue.length > 0
              ? productsValue.map((item: any) => item.id)
              : [],
          search: searchValue,
          getAll: true,
        },
        signal: controller.signal,
      });
      var lastPage =
        Number(response?.data?.data?.meta?.total) /
        Number(response?.data?.data?.meta?.limit);
      if (response?.data?.success) {
        setOrdersList(response?.data?.data?.items);
        setNumPages(response?.data?.data?.meta?.totalPages);
        setTotalValues({
          totalValues: response?.data?.data?.meta?.totalValues,
          isLastPage: Number(response?.data?.data?.meta?.page) >= lastPage,
        });
        setTotalCount(response?.data?.data?.meta?.total);
      }
      setIsPageLoading(false);
      cancelLoading();
    } catch (error: any) {
      cancelLoading();
      setIsPageLoading(false);
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };
  const handleClear = () => {
    setCurrentPage(1);
    setDateOption([]);
    setSecondDateOption([]);
    setDateFilter([]);
    setSecondDateFilter([]);
    setStore([]);
    setPageSize([{ label: 10, id: '1' }]);
    setOrderNumberSearch('');
    setRequisitionNumberSearch('');
    setSupplyStartDate([]);
    setSupplyEndDate([]);
    setOrderStatusFilterValue([]);
    setIsChangedAfterReleaseValue([]);
    setProcessingErrorCountValue(null);
    setCreatedByValue([]);
    setUpdatedByValue([]);
    setReleasedByValue([]);
    setVendorsValue([]);
    setSort([]);
    setAscDescOrder([]);
    setOrderMinValue(null);
    setOrderMaxValue(null);
    setProductsValue([]);
  };
  const params = {
    period: {
      label: dateOption.length > 0 ? dateOption[0]?.id : 'this_month',
      startDate: startDate[0],
      endDate: endDate[0],
    },
    secondPeriod:
      secondDateOption.length > 0
        ? {
            label:
              secondDateOption.length > 0 ? secondDateOption[0]?.id : 'today',
            startDate: secondStartDate[0],
            endDate: secondEndDate[0],
          }
        : '',
    filterBy: dateFilter.length > 0 ? dateFilter[0]?.id : 'ReleasedAt',
    secondFilterBy:
      secondDateFilter.length > 0 ? secondDateFilter[0]?.id : 'SupplyDate',
    store: store.length > 0 ? store[0]?.id : '',
    orderNumber: orderNumberSearch ? orderNumberSearch : '',
    prNumber: requisitionNumberSearch,
    supplyDateFrom: supplyStartDate.length > 0 ? supplyStartDate[0] : '',
    supplyDateTo: supplyEndDate.length > 0 ? supplyEndDate[0] : '',
    orderStatus:
      orderStatusFilterValue.length > 0
        ? orderStatusFilterValue.map((item: any) => item.id)
        : [],
    isChangedAfterRelease:
      isChangedAfterReleaseValue.length > 0
        ? isChangedAfterReleaseValue.map((item: any) => item.id)
        : [],
    processingErrorCount: processingErrorCountValue
      ? processingErrorCountValue
      : '',
    createdBy: createdByValue.length > 0 ? createdByValue[0].id : '',
    updatedBy: updatedByValue.length > 0 ? updatedByValue[0].id : '',
    releasedBy: releasedByValue.length > 0 ? releasedByValue[0].id : '',
    supplierIds:
      vendorsValue.length > 0 ? vendorsValue.map((item: any) => item.id) : [],
    sortType: sort.length > 0 ? sort[0].id : '',
    sortOrder:
      AscDescOrder.length > 0 && AscDescOrder[0].id === 'desc' ? -1 : 1,
    orderNumberMin: orderMinValue ? orderMinValue : '',
    orderNumberMax: orderMaxValue ? orderMaxValue : '',
    vendorProductIds:
      productsValue.length > 0 ? productsValue.map((item: any) => item.id) : [],

    getAll: true,
    search: searchValue,
  };

  const getAllOrderLists = async () => {
    try {
      const response = await getOrderData();
      if (response?.data?.success) {
        setIsExportOpen(false);
        exportData(response?.data?.data?.items);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getOrderData = async (isDownloadingFile?: boolean) => {
    try {
      return await axios.get('/report', {
        params: { ...params, downloadFile: isDownloadingFile },
        signal: controller.signal,
      });
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const onSubmit = () => {
    getOrderLists(1);
    setCurrentPage(1);
  };
  const exportData = (exportProducts: Array<any>) => {
    const dataToExport: Array<any> = [];
    exportProducts.forEach(
      ({
        receivedAt,
        cancelledAt,
        orderCode,
        requisitionUsed,
        SupplyDate,
        orderStatus,
        isChangedAfterRelease,
        processingErrorCount,
        errorMessageAt,
        errorMessage,
        sendPOToVendor,
        releasedAt,
      }: any) => {
        dataToExport.push({
          'Received At': receivedAt
            ? moment(receivedAt).format('YYYY:MM:DD hh:mm a')
            : '',
          'Cancelled At': cancelledAt
            ? moment(cancelledAt).format('YYYY:MM:DD hh:mm a')
            : '',
          'Order Code': orderCode,
          'Requisition Used': requisitionUsed,
          'Supply Date': SupplyDate
            ? moment(SupplyDate).format('YYYY:MM:DD hh:mm a')
            : '',
          'Order Status': orderStatus,
          'Is Changed After Release': isChangedAfterRelease,
          'Processing Error Count': processingErrorCount,
          'Error Message At': errorMessageAt
            ? moment(errorMessageAt).format('YYYY:MM:DD hh:mm a')
            : '',
          'Error Message': errorMessage,
          'Send PO To Vendor': sendPOToVendor,
          'Released At': releasedAt
            ? moment(releasedAt).format('YYYY:MM:DD hh:mm a')
            : '',
        });
      }
    );
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Order Report');
    if (dataToExport.length > 0) {
      xlsx.writeFile(workbook, 'order_report.xlsx');
    }
  };

  const getStores = async () => {
    try {
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        const stores = response.data.data.map((store: any) => ({
          id: store.id,
          label: store.name,
        }));
        setStores(stores);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };
  const getUsersList = async () => {
    try {
      const response = await axios.get('/users');
      if (response?.data?.success) {
        let users = response?.data?.data?.users;
        const tempUserList = users.map((user: any) => ({
          id: user.id,
          label: `${user.firstName} ${user.lastName}-${user.email}`,
        }));
        setUsers(tempUserList);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          console.error(message);
        });
      }
    }
  };
  const getVendors = async () => {
    try {
      const response = await axios.get('/vendors?all=true');
      if (response?.data?.success) {
        const fetchedVendors = response.data.data.products;
        const vendorOptions = fetchedVendors.map((vendor: any) => ({
          label: vendor.name,
          id: vendor.id,
        }));
        setVendors(vendorOptions);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getProducts = async () => {
    try {
      const response = await axios.get('/supplierProducts', {
        params: {
          all: true,
        },
      });
      if (response?.data?.success) {
        let data: any[] = response.data?.data?.products;
        data = data.map(
          ({
            id,
            supplierProductName,
            supplierProductCode,
            packagingUnit: { name },
          }) => ({
            label: `${supplierProductCode} - ${supplierProductName} - ${name}`,
            id,
          })
        );
        setProducts(data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };
  useEffect(() => {
    getStores();
    getUsersList();
    getVendors();
    getProducts();
    if (location.state) {
      let { param } = location.state as IParams;
      setDateOption([{ label: 'Custom', id: 'custom' }]);
      setIsDateDisable(false);
      setStartDate(param.startDate);
      setEndDate(param.endDate);
      setValidForAllStores(param.validForAllStores);
      if (!param.validForAllStores) {
        setStore(param.selectedStore);
      }
      getOrderLists(
        1,
        [{ label: 'Custom', id: 'custom' }],
        param.startDate,
        param.endDate,
        param.selectedStore
      );
    } else {
      getOrderLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, sort, location.state, AscDescOrder]);

  const listToRender = () => {
    return ordersList.map((item) => {
      return [
        item.orderNumber,
        `${item.storeName}`,
        item?.releasedAt ? moment(item.releasedAt).format('MM/DD/YY') : 'N/A',
        item?.supplyDate ? moment(item.supplyDate).format('MM/DD/YY') : 'N/A',
        `${item.prNumber}`,
        item.value,
        `${item.supplierName}`,
        item.receivedValue,
      ];
    });
  };
  const handleOrderValueDropDownChange = (selectedOptions: any) => {
    setOrderStatusFilterValue(selectedOptions.value);
  };
  const handleIsChangedAfterReleaseDropDownChange = (selectedOptions: any) => {
    setIsChangedAfterReleaseValue(selectedOptions.value);
  };
  const handleProductChange = (selectedOptions: any) => {
    setProductsValue(selectedOptions.value);
  };
  const handleVendorChange = (selectedOptions: any) => {
    setVendorsValue(selectedOptions.value);
  };

  const getAllListData = async () => {
    try {
      const params: any = {
        period: JSON.stringify({
          label: dateOption.length > 0 ? dateOption[0]?.id : 'this_month',
          startDate: startDate[0],
          endDate: endDate[0],
        }),
        secondPeriod:
          secondDateOption.length > 0
            ? {
                label:
                  secondDateOption.length > 0
                    ? secondDateOption[0]?.id
                    : 'today',
                startDate: secondStartDate[0],
                endDate: secondEndDate[0],
              }
            : '',
        filterBy: dateFilter.length > 0 ? dateFilter[0]?.id : 'ReleasedAt',
        secondFilterBy:
          secondDateFilter.length > 0 ? secondDateFilter[0]?.id : 'SupplyDate',
        store: store.length > 0 ? store[0]?.id : '',
        orderNumber: orderNumberSearch ? orderNumberSearch : '',
        prNumber: requisitionNumberSearch,
        supplyDateFrom: supplyStartDate.length > 0 ? supplyStartDate[0] : '',
        supplyDateTo: supplyEndDate.length > 0 ? supplyEndDate[0] : '',
        orderStatus:
          orderStatusFilterValue.length > 0
            ? orderStatusFilterValue.map((item: any) => item.id)
            : [],
        isChangedAfterRelease:
          isChangedAfterReleaseValue.length > 0
            ? isChangedAfterReleaseValue.map((item: any) => item.id)
            : [],
        processingErrorCount: processingErrorCountValue
          ? processingErrorCountValue
          : '',
        createdBy: createdByValue.length > 0 ? createdByValue[0].id : '',
        updatedBy: updatedByValue.length > 0 ? updatedByValue[0].id : '',
        releasedBy: releasedByValue.length > 0 ? releasedByValue[0].id : '',
        supplierIds:
          vendorsValue.length > 0
            ? vendorsValue.map((item: any) => item.id)
            : [],
        sortType: sort.length > 0 ? sort[0].id : '',
        orderNumberMin: orderMinValue ? orderMinValue : '',
        orderNumberMax: orderMaxValue ? orderMaxValue : '',
        vendorProductIds:
          productsValue.length > 0
            ? productsValue.map((item: any) => item.id)
            : [],

        getAll: true,
        search: searchValue,
      };
      setIsLoading(true);
      const response: any = await fetch(
        `${config.API_URL}/report-pdf?` + new URLSearchParams(params),
        {
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('auth-token')}`,
          },
          signal: controller.signal,
        }
      );
      setIsPageLoading(false);
      setIsExportLoading(false);
      if (response?.status === 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        if (exportValue) {
          setExportValue(null);
          return url;
        }
        setPdfBlobUrl(url);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setIsExportLoading(false);
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getExportData = async (): Promise<any[]> => {
    setIsExportLoading(true);
    const response: any = await getOrderData(true);
    setIsExportLoading(false);
    if (response?.data?.success) {
      setAllOrdersList(response?.data?.data?.items);
      return response?.data?.data?.items;
    }
    return [];
  };

  async function exportToCSV() {
    let dataToExport: Array<any> = [{}, {}, {}];
    setIsExportLoading(true);

    try {
      const orders = await getExportData();
      orders?.forEach(
        (
          {
            orderNumber,
            supplierName,
            storeName,
            date,
            supplyDate,
            prNumber,
            value,
            receivedValue,
          },
          index
        ) => {
          const obj: any = {
            'S.No': index + 1,
            'Order No': orderNumber,
            Supplier: supplierName,
            Store: storeName,
            'Order Date': date,
            'Supply Date': supplyDate,
            'PR No.': prNumber,
            'Order Value': Number(value),
            'Received Value': Number(receivedValue),
          };
          dataToExport.push(obj);
        }
      );
      dataToExport.push({
        'S.No': '',
        'Order No': '',
        Supplier: '',
        Store: '',
        'Order Date': '',
        'Supply Date': '',
        'PR No.': '',
        'Order Value': orders.reduce(
          (acc, curr) => acc + Number(curr.value),
          0
        ),
        'Received Value': orders.reduce(
          (acc, curr) => acc + Number(curr.receivedValue),
          0
        ),
      });
      const worksheet = xlsx.utils.json_to_sheet(dataToExport);
      if (!worksheet['!merges']) worksheet['!merges'] = [];
      worksheet['!merges'].push(xlsx.utils.decode_range('A1:I1'));
      xlsx.utils.sheet_add_aoa(worksheet, [['Order List Report']], {
        origin: 'A1',
      });
      xlsx.utils.sheet_add_aoa(worksheet, [['Start Data']], {
        origin: 'A2',
      });
      xlsx.utils.sheet_add_aoa(worksheet, [[startDate[0]]], {
        origin: 'B2',
      });
      xlsx.utils.sheet_add_aoa(worksheet, [['End Data']], {
        origin: 'C2',
      });
      xlsx.utils.sheet_add_aoa(worksheet, [[startDate[0]]], {
        origin: 'D2',
      });
      xlsx.utils.sheet_add_aoa(worksheet, [['Filter By']], {
        origin: 'E2',
      });
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [[dateFilter.length > 0 ? dateFilter[0]?.id : 'ReleasedAt']],
        {
          origin: 'F2',
        }
      );
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [
          [
            'S.no',
            'Order No',
            'Supplier',
            'Store',
            'Order Date',
            'Supply Date',
            'PR No.',
            'Order Value',
            'Received Value',
          ],
        ],
        {
          origin: 'A4',
        }
      );
      const columnWidths = [
        { wch: 15 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
        { wch: 40 },
        { wch: 20 },
      ];
      worksheet['!cols'] = columnWidths;
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Order Lists');
      if (orders.length > 0) {
        setExportValue(null);
        xlsx.writeFile(workbook, 'order_list.xlsx');
      }
    } catch (err: any) {
      setExportValue(null);
      toaster.negative(err.message, {
        autoHideDuration: 4000,
      });
    }
  }

  const onSearch = (e: any) => {
    e.preventDefault();
    setCurrentPage(1);
    getOrderLists(currentPage);
  };
  const cancelProcessing = (event?: any, isEmailProcessStarted?: boolean) => {
    if (!isEmailProcessStarted) {
      setExportValue(null);
    }
    controller.abort();
    setIsLoading(false);
    setIsExportLoading(false);
    const newController = new AbortController();
    setController(newController);
  };
  const downloadPdf = async () => {
    var file = await getAllListData();
    if (file) {
      var anchor: any = document.createElement('a');
      anchor.id = 'order-list-report-pdf-anchor';
      anchor.href = file;
      anchor.style.display = 'none';
      anchor.download = 'order-list-report.pdf';
      document.body.appendChild(anchor);
      setIsLoading(false);
      setIsExportLoading(false);
      anchor.click();
    }
  };
  const downloadFile = () => {
    if (exportValue[0]?.id === 1) {
      setIsExportLoading(true);
      return downloadPdf();
    }
    if (exportValue[0]?.id === 2) return exportToCSV();
  };
  useEffect(() => {
    if (exportValue) {
      downloadFile();
    }
  }, [exportValue]);
  useEffect(() => {
    if (!searchValue) {
      getOrderLists();
    }
  }, [searchValue]);

  const selectOverrides = {
    ControlContainer: {
      style: () => ({
        border: '1px solid rgba(13, 38, 107, 0.1)',
        borderRadius: '10px',
        backgroundColor: colors.white,
        color: colors.darkBlue,
      }),
    },
  };
  const selectOverridesForSort = {
    ControlContainer: {
      style: () => ({
        border: '1px solid rgba(13, 38, 107, 0.1)',
        borderRadius: '10px',
        backgroundColor: colors.white,
        color: colors.darkBlue,
        width: '250px',
      }),
    },
  };
  const emailMeFile = async () => {
    cancelProcessing({}, true);
    try {
      if (exportValue[0]?.id === 1) {
        const params: any = {
          period: JSON.stringify({
            label: dateOption.length > 0 ? dateOption[0]?.id : 'this_month',
            startDate: startDate[0],
            endDate: endDate[0],
          }),
          secondPeriod:
            secondDateOption.length > 0
              ? {
                  label:
                    secondDateOption.length > 0
                      ? secondDateOption[0]?.id
                      : 'today',
                  startDate: secondStartDate[0],
                  endDate: secondEndDate[0],
                }
              : '',
          filterBy: dateFilter.length > 0 ? dateFilter[0]?.id : 'ReleasedAt',
          secondFilterBy:
            secondDateFilter.length > 0
              ? secondDateFilter[0]?.id
              : 'SupplyDate',
          store: store.length > 0 ? store[0]?.id : '',
          orderNumber: orderNumberSearch ? orderNumberSearch : '',
          prNumber: requisitionNumberSearch,
          supplyDateFrom: supplyStartDate.length > 0 ? supplyStartDate[0] : '',
          supplyDateTo: supplyEndDate.length > 0 ? supplyEndDate[0] : '',
          orderStatus:
            orderStatusFilterValue.length > 0
              ? orderStatusFilterValue.map((item: any) => item.id)
              : [],
          isChangedAfterRelease:
            isChangedAfterReleaseValue.length > 0
              ? isChangedAfterReleaseValue.map((item: any) => item.id)
              : [],
          processingErrorCount: processingErrorCountValue
            ? processingErrorCountValue
            : '',
          createdBy: createdByValue.length > 0 ? createdByValue[0].id : '',
          updatedBy: updatedByValue.length > 0 ? updatedByValue[0].id : '',
          releasedBy: releasedByValue.length > 0 ? releasedByValue[0].id : '',
          supplierIds:
            vendorsValue.length > 0
              ? vendorsValue.map((item: any) => item.id)
              : [],
          sortType: !AscDescOrder
            ? sort.length > 0
              ? sort[0].id
              : ''
            : AscDescOrder[0]?.id,
          orderNumberMin: orderMinValue ? orderMinValue : '',
          orderNumberMax: orderMaxValue ? orderMaxValue : '',
          vendorProductIds:
            productsValue.length > 0
              ? productsValue.map((item: any) => item.id)
              : [],

          getAll: true,
          search: searchValue,
          emailFile: 'pdf',
          userEmail: user && user.email ? user.email : '',
        };
        setIsLoading(false);
        setExportValue(null);
        const response: any = await fetch(
          `${config.API_URL}/report-pdf?` + new URLSearchParams(params),
          {
            method: 'GET',
            headers: {
              authorization: `Bearer ${localStorage.getItem('auth-token')}`,
            },
          }
        );
        toaster.positive('Email sent', {
          autoHideDuration: 4000,
        });
      }
      if (exportValue[0].id === 2) {
        try {
          const downloadParams = {
            period: {
              label: dateOption.length > 0 ? dateOption[0]?.id : 'this_month',
              startDate: startDate[0],
              endDate: endDate[0],
            },
            secondPeriod:
              secondDateOption.length > 0
                ? {
                    label:
                      secondDateOption.length > 0
                        ? secondDateOption[0]?.id
                        : 'today',
                    startDate: secondStartDate[0],
                    endDate: secondEndDate[0],
                  }
                : '',
            filterBy: dateFilter.length > 0 ? dateFilter[0]?.id : 'ReleasedAt',
            secondFilterBy:
              secondDateFilter.length > 0
                ? secondDateFilter[0]?.id
                : 'SupplyDate',
            store: store.length > 0 ? store[0]?.id : '',
            orderNumber: orderNumberSearch ? orderNumberSearch : '',
            prNumber: requisitionNumberSearch,
            supplyDateFrom:
              supplyStartDate.length > 0 ? supplyStartDate[0] : '',
            supplyDateTo: supplyEndDate.length > 0 ? supplyEndDate[0] : '',
            orderStatus:
              orderStatusFilterValue.length > 0
                ? orderStatusFilterValue.map((item: any) => item.id)
                : [],
            isChangedAfterRelease:
              isChangedAfterReleaseValue.length > 0
                ? isChangedAfterReleaseValue.map((item: any) => item.id)
                : [],
            processingErrorCount: processingErrorCountValue
              ? processingErrorCountValue
              : '',
            createdBy: createdByValue.length > 0 ? createdByValue[0].id : '',
            updatedBy: updatedByValue.length > 0 ? updatedByValue[0].id : '',
            releasedBy: releasedByValue.length > 0 ? releasedByValue[0].id : '',
            supplierIds:
              vendorsValue.length > 0
                ? vendorsValue.map((item: any) => item.id)
                : [],
            sortType: !AscDescOrder
              ? sort.length > 0
                ? sort[0].id
                : ''
              : AscDescOrder[0]?.id,
            orderNumberMin: orderMinValue ? orderMinValue : '',
            orderNumberMax: orderMaxValue ? orderMaxValue : '',
            vendorProductIds:
              productsValue.length > 0
                ? productsValue.map((item: any) => item.id)
                : [],

            getAll: true,
            search: searchValue,
            emailFile: 'excel',
            userEmail: user && user.email ? user.email : '',
          };
          setExportValue(null);
          await axios.get('/report', {
            params: downloadParams,
          });
        } catch (error: any) {
          const errors = error?.response?.data?.errors;
          if (errors?.length) {
            errors.forEach((message: string) => console.error(message));
          }
        }
      }
    } catch (error: any) {
      toaster.negative(error.message, {
        autoHideDuration: 4000,
      });
    }
  };
  const handleSearchClicked = () => {
    if (loading) {
      controller.abort();
      const newController = new AbortController();
      setController(newController);
      cancelLoading();
      return;
    }
    if (searchValue) {
      triggerLoading();
      setCurrentPage(1);
      getOrderLists(currentPage);
    }
  };
  return (
    <ReportingInternalTableLayout
      isLoading={isLoading}
      isExportLoading={isExportLoading}
      cancelProcessing={cancelProcessing}
      emailMeFile={emailMeFile}
      type={exportValue}
    >
      <div className={titleWrapper}>
        <div className={breadcrumbWrapper}>
          <img className={iconStyles} src={ordersIconURL} alt="Order" />
          <Breadcrumb items={BREADCRUMB_ITEMS} />
        </div>
        <div
          className={toggleFilter}
          onClick={() => setActiveAllFilters(!activeAllFilters)}
        >
          <img src={filter} alt="Filter" title="Filter" className={filterImg} />
        </div>
      </div>
      <div className={`create-order-list`}>
        <div className={filterListWrapper}>
          <FilterList
            dates={dates}
            dateFilters={dateFilters}
            stores={stores}
            store={store}
            startDate={startDate}
            secondStartDate={secondStartDate}
            endDate={endDate}
            secondEndDate={secondEndDate}
            dateOption={dateOption}
            secondDateOption={secondDateOption}
            dateFilter={dateFilter}
            secondDateFilter={secondDateFilter}
            setStore={setStore}
            setStartDate={setStartDate}
            setSecondStartDate={setSecondStartDate}
            setEndDate={setEndDate}
            setSecondEndDate={setSecondEndDate}
            setDateOption={setDateOption}
            setSecondDateOption={setSecondDateOption}
            setDateFilter={setDateFilter}
            setSecondDateFilter={setSecondDateFilter}
            onSubmit={onSubmit}
            orderNumberSearch={orderNumberSearch}
            setOrderNumberSearch={setOrderNumberSearch}
            requisitionNumberSearch={requisitionNumberSearch}
            setRequisitionNumberSearch={setRequisitionNumberSearch}
            orderStatusFilterValue={orderStatusFilterValue}
            handleOrderValueDropDownChange={handleOrderValueDropDownChange}
            isChangedAfterReleaseValue={isChangedAfterReleaseValue}
            handleIsChangedAfterReleaseDropDownChange={
              handleIsChangedAfterReleaseDropDownChange
            }
            processingErrorCountValue={processingErrorCountValue}
            setProcessingErrorCountValue={setProcessingErrorCountValue}
            users={users}
            createdByValue={createdByValue}
            setCreatedByValue={setCreatedByValue}
            updatedByValue={updatedByValue}
            setUpdatedByValue={setUpdatedByValue}
            releasedByValue={releasedByValue}
            setReleasedByValue={setReleasedByValue}
            vendors={vendors}
            handleVendorChange={handleVendorChange}
            vendorsValue={vendorsValue}
            orderMinValue={orderMinValue}
            setOrderMinValue={setOrderMinValue}
            orderMaxValue={orderMaxValue}
            setOrderMaxValue={setOrderMaxValue}
            products={products}
            productsValue={productsValue}
            handleProductChange={handleProductChange}
            handleClear={handleClear}
            isDateDisable={isDateDisable}
            setIsDateDisable={setIsDateDisable}
            validForAllStores={validForAllStores}
            setValidForAllStores={setValidForAllStores}
            activeAllFilters={activeAllFilters}
          />
        </div>
      </div>
      <div className={actionsWrapper}>
        <div className={searchCountWrapper}>
          <div className={searchWrapper}>
            <form onSubmit={onSearch}>
              <CustomSearch
                placeholder="Table search..."
                buttonName={loading ? 'Cancel' : 'Search'}
                searchClicked={handleSearchClicked}
                setSearchValue={setSearchValue}
                inputChange={(e) => setSearchValue(e.target.value)}
                inputValue={searchValue}
                loading={loading}
              />
            </form>
          </div>
          {!loading && (
            <div className={orderCountWrapper}>
              <div>
                <p style={{ display: 'inline' }} className={countTextStyle}>
                  {totalCount} Order(s)
                </p>
              </div>
            </div>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <div className={inputWrapper}>
            <Sort sortOption={sortOption} />
            <Select
              size={SIZE.default}
              options={sortByOptions}
              value={sort}
              placeholder="Order No"
              disabled={false}
              onChange={(params: any) => {
                setSort(params.value);
              }}
              overrides={selectOverridesForSort}
            />
            <div style={{ margin: '0 5px' }}>
              <Select
                size={SIZE.default}
                options={sortByTypes}
                placeholder="ASC"
                disabled={false}
                value={AscDescOrder}
                onChange={(params: any) => setAscDescOrder(params.value)}
                overrides={selectOverrides}
                clearable={false}
              />
            </div>
          </div>
          <div className={customButtonWrapper}>
            {!isLoading || isExportLoading ? (
              <CustomIconButton
                icon={print}
                name="Print"
                onClick={() => {
                  setExportValue(null);
                  getAllListData();
                }}
                isDisable={false}
              />
            ) : (
              <div className={wrapperStyles}>
                <span> Printing...</span>
                <Spinner $size={20} />
              </div>
            )}
          </div>

          {!isExportLoading ? (
            <div className={customButtonWrapper}>
              <SelectOption
                name="Export"
                exportFileOptions={exportFileOptions}
                value={exportValue}
                selectOption={(params) => {
                  setExportValue(params.value);
                }}
                selectOverrides={selectOverrides}
              />
            </div>
          ) : (
            <div className={wrapperStyles}>
              <span> Exporting...</span>
              <Spinner $size={20} />
            </div>
          )}
        </div>
      </div>
      <div ref={containerRef} className={listWrapper}>
        {isPageLoading ? (
          <div className="loader-container">
            <Loader size="small" />
          </div>
        ) : (
          <ReportList
            reportHeader={REPORT_LIST_HEADERS}
            reportData={listToRender()}
            type="order"
            type2="OR"
            totalValues={totalValues}
          />
        )}
      </div>
      <div className={listWrapper}>
        <Pagin
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numPages={numPages}
          getOrderLists={getOrderLists}
        />
        <div className={css({ width: '100px', marginTop: '10px' })}>
          <Select
            size={SIZE.mini}
            options={pageSizeOption}
            value={pageSize}
            placeholder="10"
            onChange={(params) => setPageSize(params.value)}
          />
        </div>
      </div>
      <ExportOrderReport
        isOpen={isExportOpen}
        products={ordersList}
        setIsOpen={setIsExportOpen}
        getAllOrderLists={getAllOrderLists}
      />
    </ReportingInternalTableLayout>
  );
};

export default OrderListReport;
