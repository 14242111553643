export enum OrderStatusEnum {
  Released = 'Released',
  Partial = 'Partial',
  Draft = 'Draft',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Archived = 'Archived',
  Conflict = 'Conflict',
  Submitted = 'Submitted',
  Declined = 'Declined',
}
