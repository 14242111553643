import { SIZE, Select } from 'baseui/select';

type Props = {
  value: any;
  name: string;
  selectOption: (a: any) => void;
  selectOverrides: any;
  exportFileOptions: any;
};
const SelectOption = ({
  value,
  name,
  selectOption,
  selectOverrides,
  exportFileOptions,
}: Props) => {
  return (
    <Select
      size={SIZE.default}
      options={exportFileOptions}
      value={value}
      placeholder={name}
      onChange={selectOption}
      overrides={selectOverrides}
    />
  );
};

export default SelectOption;
