import classNames from 'classnames';
import './index.scss';
import colors from 'constants/colors';

interface IProps {
  reportHeader: Array<any>;
  reportData: Array<Array<any>>;
  type?: string;
  type2?: string;
  totalValues?: any;
}

const ReportList = ({
  reportHeader,
  reportData,
  type,
  type2,
  totalValues,
}: IProps) => {
  return (
    <div className="report-list">
      <div className="report-list-header">
        {reportHeader.map((item, index) => {
          return (
            <div
              key={`header_${index}`}
              className={classNames({
                'report-list-header-columnNumber': index === 10,
                'report-list-header-column': !(index === 10),
              })}
              data-attr-sno-header={
                type === 'invoice' && type2 !== 'DRR' && index === 0
              }
              data-attr-no-items-header={
                type === 'invoice' && type2 !== 'DRR' && index === 9
              }
              data-attr-no-datetime-header={
                type === 'invoice' && type2 !== 'DRR' && index === 2
              }
              data-attr-no-noofitems-header={
                type === 'invoice' && type2 !== 'DRR' && index === 9
              }
              data-attr-no-type-header={
                type === 'invoice' && type2 !== 'DRR' && index === 3
              }
              data-attr-no-currentstatus-header={
                type === 'invoice' && type2 !== 'DRR' && index === 6
              }
            >
              <div
                className="report-list-header-column__label"
                style={
                  index === 2 && type === 'delivery'
                    ? { marginLeft: '70px' }
                    : {}
                }
              >
                {item}
              </div>
            </div>
          );
        })}
      </div>
      <div className="report-list-rows">
        {reportData.map((item, i) => (
          <div key={`data_${i}`} className="report-list-row">
            {item.map((_item, j) => (
              <div
                key={`data_${i}_${j}`}
                className={classNames({
                  'report-list-row-columnNumber': j === 10,
                  'report-list-row-column': !(j === 10),
                })}
                style={j === 1 ? { paddingLeft: '-30px' } : {}}
                data-attr-sno={type === 'invoice' && type2 !== 'DRR' && j === 0}
                data-attr-no-items={
                  type === 'invoice' && type2 !== 'DRR' && j === 9
                }
                data-attr-no-datetime={
                  type === 'invoice' && type2 !== 'DRR' && j === 2
                }
                data-attr-no-noofitems={
                  type === 'invoice' && type2 !== 'DRR' && j === 9
                }
                data-attr-no-type={
                  type === 'invoice' && type2 !== 'DRR' && j === 3
                }
                data-attr-no-currentstatus={
                  type === 'invoice' && type2 !== 'DRR' && j === 6
                }
              >
                <div className="report-list-row-column__label">{_item}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {type2 === 'DDR' && reportData.length > 0 && totalValues?.totalValues ? (
        <div
          className="report-list-row"
          style={{ backgroundColor: colors.lightGray }}
        >
          <div className="report-list-row-column">
            <div className="report-list-row-column__label">Totals</div>
          </div>
          <div className="report-list-row-column1">
            <div className="report-list-row-column__label"></div>
          </div>
          <div className="report-list-row-column">
            <div className="report-list-row-column__label"></div>
          </div>
          <div className="report-list-row-column">
            <div className="report-list-row-column__label"></div>
          </div>
          <div className="report-list-row-column">
            <div className="report-list-row-column__label"></div>
          </div>
          <div className="report-list-row-columnNumber report-list-row-column">
            <div className="report-list-row-column__label"></div>
          </div>
          <div className="report-list-row-column">
            <div className="report-list-row-column__label"></div>
          </div>
          <div className="report-list-row-columnNumber">
            <div className="report-list-row-column__label">
              {new Intl.NumberFormat().format(totalValues?.totalValues)}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReportList;
