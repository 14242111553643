import { AuthService } from 'core/services/auth';
import config from '../config';
import { SocketClient } from './client';

const authService = new AuthService();

export const getSocketClient = () => {
  const token = authService.getAuthToken();
  if (!token) return null;
  const socketClient = new SocketClient(config.API_URL, token);
  return socketClient.getInstance();
};
