import React, { useEffect } from 'react';
import { v4 as uuidv4, v4 } from 'uuid';
import priceListIconURL from 'assets/icons/price-list.svg';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import { useStyletron } from 'styletron-react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';
import { DatePicker } from 'baseui/datepicker';
import { Select } from 'baseui/select';
import { Input } from 'baseui/input';
import { useNavigate, useParams } from 'react-router-dom';
import { Axios } from 'core/services/http';
import { toaster } from 'baseui/toast';
import { Spinner } from 'baseui/icon';
import CustomIconButton from 'modules/shared/components/icon-button';
import editIconUrl from 'assets/icons/edit.svg';
import submitIconURL from 'assets/icons/pr-submitted.svg';
import numberWithCommas from 'modules/shared/number-with-commas';
import ExclusionModal from '../edit/ExclusionModal';
import moment from 'moment';

const BREADCRUMB_ITEMS = [
  { name: 'Price Contracts', route: '/price-contracts' },
  { name: 'View Price Contract', route: '' },
];

interface ContractItem {
  _id: string;
  vendorProductId: {
    id: string;
    label: string;
  };
  priceWoVAT: number;
  isActive: boolean;
  defaultVendor: boolean;
  excludedBrandList: Array<any>;
  excludedStoreList: Array<any>;
  specialEmailAnyStore: boolean;
  specialEmailSpecificStoreList: Array<any>;
  hideInRequisition: boolean;
}

const ViewPriceContract = () => {
  const { id } = useParams();

  const [css] = useStyletron();
  const navigate = useNavigate();

  const axios = new Axios().getInstance();

  const [priceContract, setPriceContract] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);

  //backend data
  const [vendors, setVendors] = React.useState<Array<any>>([]);
  const [brands, setBrands] = React.useState<Array<any>>([]);
  const [stores, setStores] = React.useState<Array<any>>([]);

  //frontend data for create price contract
  const [supplier, setSupplier] = React.useState<any>([]);
  const [isOpenContract, setIsOpenContract] = React.useState(true);
  const [startDate, setStartDate] = React.useState<any>([]);
  const [endDate, setEndDate] = React.useState<any>([]);
  const [validForAllBrands, setValidForAllBrands] = React.useState(true);
  const [includedBrandList, setIncludedBrandList] = React.useState([]);
  const [excludedBrandList, setExcludedBrandList] = React.useState([]);
  const [validForAllStores, setValidForAllStores] = React.useState(true);
  const [includedStoreList, setIncludedStoreList] = React.useState([]);
  const [excludedStoreList, setExcludedStoreList] = React.useState([]);

  const [exclusionsModalOpen, setExclusionsModalOpen] = React.useState(false);
  const [selectedContractItem, setSelectedContractItem] = React.useState<any>();

  const [contractItems, setContractItems] = React.useState<Array<ContractItem>>(
    [
      {
        _id: v4(),
        vendorProductId: {
          id: uuidv4(),
          label: '',
        },
        priceWoVAT: 0,
        isActive: true,
        defaultVendor: false,
        excludedBrandList: [],
        excludedStoreList: [],
        specialEmailAnyStore: false,
        specialEmailSpecificStoreList: [],
        hideInRequisition: false,
      },
    ]
  );

  const getVendors = async () => {
    try {
      const response = await axios.get('/vendors?all=true');
      if (response?.data?.success && response?.data?.data?.products) {
        setVendors(
          response?.data?.data?.products?.filter(
            (data: any) => data.deleted === false
          )
        );
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getStores = async () => {
    try {
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        setStores(response.data.data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const BrandStatusEnum = {
    Active: 'active',
    Archived: 'archived',
  };

  const getBrands = async () => {
    try {
      const response = await axios.get('/brands?status=active', {
        params: {
          status: BrandStatusEnum.Active,
        },
      });
      if (response?.data?.success) {
        setBrands(response.data.data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const buttonWrapper = css({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  });
  const flexGridItemClass = css({
    width: 'fit-content !important',
    '@media (max-width: 540px)': {
      width: '100%',
      marginRight: 'auto',
    },
  });
  const flexGridSmallItemClass = css({
    '@media (min-width: 541px)': {
      width: 'calc(((100% - 120px) / 12) - 0.5px) !important',
      marginRight: '12px !important',
    },
  });

  const newRequistionWrapper = css({
    display: 'grid',
    gridTemplateColumns: '10% 20% 15% 8% 5% 15% 10% 10% 3%',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 8px',
    minWidth: '900px',
  });
  const newRequisitionHeader = css({
    display: 'grid',
    gridTemplateColumns: '10% 20% 15% 8% 5% 15% 10% 10% 3%',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 6px',

    backgroundColor: '#063360',
    color: 'white',
    fontWeight: 700,
    fontSize: '16px',
  });
  const topLabelWrapper = css({
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  });
  const topLabel = css({
    fontSize: '18px',
    fontWeight: '500',
  });
  const topLabelValue = css({
    fontSize: '17px',
    fontFamily: 'poppins-regular',
  });

  const getVendorOptions = () => {
    return vendors.map((vendor: any) => ({
      id: vendor.id,
      label: vendor.name,
    }));
  };

  const getStoreOptions = () => {
    const storeOptions = stores.map((store: any) => ({
      id: store.id,
      label: store.name,
      brand: store.brand,
    }));

    const filteredStoreOptions = storeOptions.filter((storeOption) => {
      //if valid for all brands is true
      if (validForAllBrands) {
        //we check if the exluded brand list is empty then we return all stores
        if (excludedBrandList.length === 0) {
          return true;
        }
        //we return the stores that are not included in the excluded brand list
        const isExcluded = excludedBrandList.find(
          ({ id }: any) =>
            id === storeOption.brand?._id || id === storeOption.brand
        );
        return !isExcluded;
      } else {
        //we return the stores that are included in the included brand list
        const isIncluded = includedBrandList.find(
          ({ id }: any) =>
            id === storeOption.brand?._id || id === storeOption.brand
        );
        return isIncluded;
      }
    });

    return filteredStoreOptions;
  };

  const getQualifyingStoreOptions = () => {
    //if valid for all stores is true then we need to return all stores except excluded stores, if valid for all stores is false then we need to return included stores
    const options = getStoreOptions();
    if (validForAllStores) {
      return options.filter(
        (store: any) =>
          !excludedStoreList.find(({ id }: any) => id === store.id)
      );
    }
    return includedStoreList;
  };

  const getBrandOptions = () => {
    return brands.map((brand: any) => ({
      id: brand.id,
      label: brand.name,
    }));
  };

  const isAllInputsValid = () => {
    if (!supplier.length) {
      toaster.negative('Supplier is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!startDate.length) {
      toaster.negative('Start Date is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!isOpenContract && !endDate.length) {
      toaster.negative('End Date is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (endDate.length && startDate[0] > endDate[0]) {
      toaster.negative('End Date should be greater than Start Date', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!validForAllBrands && !includedBrandList.length) {
      toaster.negative('Included Brands is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    if (!validForAllStores && !includedStoreList.length) {
      toaster.negative('Included Stores is required', {
        autoHideDuration: 4000,
      });
      return false;
    }

    return true;
  };

  const getPriceContract = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/priceContracts/${id}`);
      const contractData = response.data.data;
      setPriceContract(contractData);
      setSupplier([
        {
          id: contractData?.vendorId?._id,
          label: contractData?.vendorId?.name,
        },
      ]);
      setIsOpenContract(contractData?.isOpenContract);
      setStartDate([new Date(contractData?.contractStartDate)]);
      setEndDate(
        contractData?.contractEndDate
          ? [new Date(contractData.contractEndDate)]
          : []
      );
      setValidForAllBrands(contractData.validForAllBrands);
      setIncludedBrandList(
        contractData?.includedBrandList.map((brand: any) => ({
          id: brand?._id,
          label: brand?.name,
        }))
      );
      setExcludedBrandList(
        contractData?.excludedBrandList.map((brand: any) => ({
          id: brand?._id,
          label: brand?.name,
        }))
      );
      setValidForAllStores(contractData?.validForAllStores);
      setIncludedStoreList(
        contractData?.includedStoreList.map((store: any) => ({
          id: store?._id,
          label: store?.name,
          brand: store?.brand?._id,
        }))
      );
      setExcludedStoreList(
        contractData?.excludedStoreList.map((store: any) => ({
          id: store?._id,
          label: store?.name,
          brand: store?.brand?._id,
        }))
      );
      const updatedContractItems = contractData?.contractItems.map(
        (item: any) => {
          return {
            _id: item?._id,
            vendorProductId: {
              id: item?.vendorProductId?._id,
              label: `${item?.vendorProductId?.supplierProductCode}-${item?.vendorProductId?.supplierProductName}-${item?.vendorProductId?.productPackagingUnit?.name}`,
              supplierProductCode: item?.vendorProductId?.supplierProductCode,
              supplierProductName: item?.vendorProductId?.supplierProductName,
              productPackagingUnit:
                item?.vendorProductId?.productPackagingUnit?.name,
            },
            priceWoVAT: item?.priceWoVAT,
            isActive: item?.isActive,
            excludedStoreList: item?.excludedStoreList.map((store: any) => ({
              id: store?._id,
              label: store?.name,
              brand: store?.brand?._id,
            })),
            specialEmailAnyStore: item?.specialEmailAnyStore,
            //if special email any store is true then we need to set special email specific store list to all stores
            specialEmailSpecificStoreList: item?.specialEmailAnyStore
              ? getStoreOptions()
              : item?.specialEmailSpecificStoreList.map((store: any) => ({
                  id: store?._id,
                  label: store?.name,
                  brand: store?.brand?._id,
                })),
            hideInRequisition: item?.hideInRequisition,
          };
        }
      );
      setContractItems(updatedContractItems);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVendors();
    getStores();
    getBrands();
    if (id) {
      getPriceContract();
    }

    // eslint-disable-next-line
  }, [id]);

  const validateContractItem = (item: any) => {
    if (!item.vendorProductId?.label || !item.priceWoVAT) {
      toaster.negative('Product and Price w/o VAT is required', {
        autoHideDuration: 4000,
      });
      return false;
    }
    return true;
  };

  const submitPriceContractHandler = async () => {
    if (!isAllInputsValid()) {
      return;
    }
    //we need to validate each contract item
    if (contractItems.length) {
      const isValid = contractItems.every((item) => validateContractItem(item));
      if (!isValid) {
        return;
      }
    }
    try {
      const response = await axios.patch(`/priceContracts/submit/${id}`);
      if (response.data.success) {
        toaster.positive('Price Contract Submitted Successfully', {
          autoHideDuration: 4000,
        });
        navigate('/price-contracts');
      }
    } catch (error) {
      console.error(error);
      toaster.negative('Failed to submit price contract', {
        autoHideDuration: 4000,
      });
    }
  };

  const activePriceContractHandler = async () => {
    if (!isAllInputsValid()) {
      return;
    }
    //we need to validate each contract item
    if (contractItems.length) {
      const isValid = contractItems.every((item) => validateContractItem(item));
      if (!isValid) {
        return;
      }
    }
    try {
      const response = await axios.patch(`/priceContracts/active/${id}`);
      if (response.data.success) {
        toaster.positive('Price Contract Activated Successfully', {
          autoHideDuration: 4000,
        });
        navigate('/price-contracts');
      }
    } catch (error) {
      console.error(error);
      toaster.negative('Failed to activate price contract', {
        autoHideDuration: 4000,
      });
    }
  };

  return (
    <>
      {!loading && (
        <div className={css({ marginTop: '70px', padding: '10px' })}>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            })}
          >
            <div className={breadcrumbWrapper}>
              <img
                className={iconStyles}
                src={priceListIconURL}
                alt="Price Contract"
              />
              <Breadcrumb items={BREADCRUMB_ITEMS} />
            </div>
            <div
              className={topLabelWrapper}
              style={{
                marginRight: '20px',
              }}
            >
              <p className={topLabel}>Status:</p>
              <span className={topLabelValue}>{priceContract?.status}</span>
            </div>
          </div>
          <div>
            <div>
              <FlexGrid
                flexGridColumnCount={[1, 1, 6]}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
              >
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Contract Code</div>
                    <Input
                      value={priceContract?.contractCode}
                      disabled
                      placeholder="Contract Code"
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Supplier Name</div>
                    <Select
                      options={getVendorOptions()}
                      value={supplier}
                      placeholder="Select Supplier"
                      onChange={(params: any) => setSupplier(params.value)}
                      disabled
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Contract Start Date</div>
                    <DatePicker
                      value={startDate}
                      onChange={({ date }) =>
                        setStartDate(Array.isArray(date) ? date : [date])
                      }
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 999 },
                              },
                            },
                          },
                        },
                        MonthYearSelectPopover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 9999 },
                              },
                            },
                          },
                        },
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="input-wrapper">
                    <div className="input-label">Contract End Date</div>
                    <DatePicker
                      value={endDate}
                      onChange={({ date }) =>
                        setEndDate(Array.isArray(date) ? date : [date])
                      }
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 999 },
                              },
                            },
                          },
                        },
                        MonthYearSelectPopover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 9999 },
                              },
                            },
                          },
                        },
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridSmallItemClass}>
                  <div className="input-wrapper">
                    <div
                      className="input-label"
                      style={{ marginBottom: '12px' }}
                    >
                      Open Contract
                    </div>
                    <Checkbox
                      checked={isOpenContract}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Toggle: {
                          style: ({ $theme, $checked }) => ({
                            backgroundColor: $checked ? '#09B08C' : '#000',
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setIsOpenContract(e.target.checked);
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
              </FlexGrid>
              <br />
              <FlexGrid
                flexGridColumnCount={[1, 2, 6]}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
              >
                <FlexGridItem className={flexGridSmallItemClass}>
                  <div className="input-wrapper">
                    <div
                      className="input-label"
                      style={{ marginBottom: '12px' }}
                    >
                      All Brands
                    </div>
                    <Checkbox
                      checked={validForAllBrands}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Toggle: {
                          style: ({ $theme, $checked }) => ({
                            backgroundColor: $checked ? '#09B08C' : '#000',
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setIncludedBrandList([]);
                        setExcludedBrandList([]);
                        setValidForAllBrands(e.target.checked);
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Included Brands</div>
                    <SelectWithCheckboxes
                      //@ts-ignore
                      options={getBrandOptions()}
                      disabled={validForAllBrands}
                      store={includedBrandList}
                      name="Include Brands"
                      handleChange={() => {}}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Excluded Brands</div>
                    <SelectWithCheckboxes
                      options={getBrandOptions()}
                      disabled={!validForAllBrands}
                      store={excludedBrandList}
                      name="Exclude Brands"
                      handleChange={() => {}}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridSmallItemClass}>
                  <div className="input-wrapper">
                    <div
                      className="input-label"
                      style={{ marginBottom: '12px' }}
                    >
                      All Stores
                    </div>
                    <Checkbox
                      checked={validForAllStores}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Toggle: {
                          style: ({ $theme, $checked }) => ({
                            backgroundColor: $checked ? '#09B08C' : '#000',
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setIncludedStoreList([]);
                        setExcludedStoreList([]);
                        setValidForAllStores(e.target.checked);
                      }}
                      disabled
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Included Stores</div>
                    <SelectWithCheckboxes
                      options={getStoreOptions()}
                      disabled={validForAllStores}
                      store={includedStoreList}
                      handleChange={() => {}}
                    />
                  </div>
                </FlexGridItem>
                <FlexGridItem className={flexGridItemClass}>
                  <div className="input-wrapper">
                    <div className="input-label">Excluded Stores</div>
                    <SelectWithCheckboxes
                      options={getStoreOptions()}
                      disabled={!validForAllStores}
                      store={excludedStoreList}
                      handleChange={() => {}}
                    />
                  </div>
                </FlexGridItem>
              </FlexGrid>
            </div>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '20px 0',
                borderTop: '1px solid #e5e5e5',
                paddingTop: '20px',
              })}
            >
              {priceContract?.status === 'Draft' && (
                <div className={buttonWrapper}>
                  <CustomIconButton
                    icon={submitIconURL}
                    name="Submit Price Contract"
                    onClick={submitPriceContractHandler}
                  />
                </div>
              )}
              {priceContract?.status === 'Submitted' && (
                <div className={buttonWrapper}>
                  <CustomIconButton
                    icon={editIconUrl}
                    name="Activate"
                    onClick={activePriceContractHandler}
                  />
                </div>
              )}
            </div>
            <div>
              {contractItems?.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                  <div className={`${newRequisitionHeader}`}>
                    <span>Product Code</span>
                    <span>Product Name</span>
                    <span>Product Packaging Unit</span>
                    <span
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      Price w/o VAT
                    </span>
                    <span>Active</span>
                    <span>Special Email Store</span>
                    <span>Hide In Requisition</span>
                    <span>Exclusions</span>
                    <span></span>
                  </div>
                  {contractItems.map((item: any) => (
                    <div
                      className={newRequistionWrapper}
                      key={item.vendorProductId.id}
                    >
                      <span>
                        {item.vendorProductId?.supplierProductCode || '-'}
                      </span>
                      <span>
                        {item.vendorProductId?.supplierProductName || '-'}
                      </span>
                      <span>
                        {item.vendorProductId?.productPackagingUnit || '-'}
                      </span>
                      <span
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {numberWithCommas(item.priceWoVAT)}
                      </span>
                      <span>
                        <Checkbox
                          checked={item.isActive}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          overrides={{
                            Toggle: {
                              style: ({ $theme, $checked }) => ({
                                backgroundColor: $checked ? '#09B08C' : '#000',
                              }),
                            },
                          }}
                          disabled
                        />
                      </span>
                      <span>
                        <SelectWithCheckboxes
                          options={getQualifyingStoreOptions()}
                          store={item?.specialEmailSpecificStoreList || []}
                          disabled={false}
                          name="Special Email Stores"
                          handleChange={() => {}}
                        />
                      </span>
                      <span>
                        <Checkbox
                          checked={item.hideInRequisition}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          overrides={{
                            Toggle: {
                              style: ({ $theme, $checked }) => ({
                                backgroundColor: $checked ? '#09B08C' : '#000',
                              }),
                            },
                          }}
                          disabled
                        />
                      </span>
                      <span
                        className={css({
                          color: '#09B08C',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        })}
                        onClick={() => {
                          setSelectedContractItem(item);
                          setExclusionsModalOpen(true);
                        }}
                      >
                        {item?.excludedStoreList?.length > 0
                          ? item?.excludedStoreList?.length + ' Exclusions'
                          : 'None'}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
            })}
          >
            <div className={topLabelWrapper}>
              <p className={topLabel}>Created:</p>
              <span className={topLabelValue}>
                {moment(priceContract?.createdAt).format('DD/MM/YYYY')} by{' '}
                {priceContract?.createdBy?.firstName}{' '}
                {priceContract?.createdBy?.lastName}
              </span>
            </div>
            <div className={topLabelWrapper}>
              <p className={topLabel}>Updated:</p>
              <span className={topLabelValue}>
                {moment(priceContract?.updatedAt).format('DD/MM/YYYY')} by{' '}
                {priceContract?.updatedBy?.firstName}{' '}
                {priceContract?.updatedBy?.lastName}
              </span>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div
          className={css({
            marginTop: '70px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          })}
        >
          <Spinner $size={50} />
        </div>
      )}
      {exclusionsModalOpen && (
        <ExclusionModal
          isOpen={exclusionsModalOpen}
          onClose={() => setExclusionsModalOpen(false)}
          storeOptions={getQualifyingStoreOptions()}
          contractItem={selectedContractItem}
          onExclusionChange={() => {}}
        />
      )}
    </>
  );
};

export default ViewPriceContract;
